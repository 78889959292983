import React, { useState, useContext, useEffect } from 'react';

// import OrganziationEdit from 'Components/Organization/Edit';
import { CurrentUserContext } from 'gunner-react'
import TagList from 'Components/Tag/List';
import { Paper, Button } from '@material-ui/core';
import Modal from 'Components/Modal';
import TagNew from 'Components/Tag/New';
import NavigationBreadcrumbsContainer from 'Components/Navigation/Breadcrumbs';


import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HelpIcon from '@material-ui/icons/Help';
import { Typography } from '@material-ui/core';
import { AppBarContext } from 'gunner-react/web';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { IconButton } from '@material-ui/core';
import { useHasVisitedScreen } from 'gunner-react/web';

export default () => {
  const currentUser = useContext(CurrentUserContext);
  const [showModal, setShowModal] = useState(false);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(!useHasVisitedScreen());

  const appBar = useContext(AppBarContext);

  useEffect(() => {
    appBar.setTitle("Tags")

    appBar.setOnClickHelp(() => () => setShowHelpModal(true))
    appBar.setRightButtons([
      <IconButton
        aria-label="add"
        aria-haspopup="true"
        onClick={() => setShowModal(true)}
        color="inherit"
      >
        <AddCircleOutlineIcon />
      </IconButton>
    ])

    return appBar.reset
  }, [])

  return (
    <>
      <NavigationBreadcrumbsContainer />
      
      {/* <Button variant="contained" color="secondary" onClick={() => setShowModal(true)}>Add Tag</Button> */}
      <Paper square>
        <TagList organizationId={currentUser.userOrganizationId} doRefetch={!showModal} />
      </Paper>

      <Modal
        title="Create Tag"
        body={
          <TagNew organizationId={currentUser.userOrganizationId} onSuccess={() => setShowModal(false)} />
        }
        onClose={() => setShowModal(false)}
        submitting={false}
        opened={!!showModal}
        saveButton={false}
      />

      <Modal
        title="Help"
        body={
          <>
            <Typography paragraph>Tags allow you to organize surveys and contacts.</Typography>
          </>
        }
        onClose={() => setShowHelpModal(false)}
        submitting={false}
        opened={!!showHelpModal}
        saveButton={false}
      />

      {/* <SpeedDial
        ariaLabel="SpeedDial"
        icon={<SpeedDialIcon />}
        onClose={() => setSpeedDialOpen(false)}
        onOpen={() => setSpeedDialOpen(true)}
        open={speedDialOpen}
        direction={'up'}
      >
        <SpeedDialAction
          icon={<HelpIcon />}
          tooltipTitle={"Help"}
          onClick={() => setShowHelpModal(true)}
          tooltipOpen
        />
        <SpeedDialAction
          icon={<AddCircleIcon />}
          tooltipTitle={"Add Tag"}
          onClick={() => setShowModal(true)}
          tooltipOpen
        />
      </SpeedDial> */}
    </>
  )
}
