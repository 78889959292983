import React, { useState } from 'react'
import { Button, MenuItem } from '@material-ui/core';
import PromptEdit from '..';
import Modal from '../../../Modal';

const PromptEditButtonContainer = ({id, render = "button", onSuccess = () => null}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Modal
        title="Edit Prompt"
        body={
          <PromptEdit id={id} onSuccess={prompt => [setShowModal(false), onSuccess(prompt)]} />
        }
        onClose={() => setShowModal(false)}
        submitting={false}
        opened={!!showModal}
        saveButton={false}
      />
      {
        render === 'button' ? (
          <Button variant="contained" size="small" color="secondary" onClick={() => setShowModal(true)}>Edit</Button>
        ) : (
          <MenuItem onClick={() => setShowModal(true)}>Edit</MenuItem>
        )
      }
      
    </>
  )
}

export default PromptEditButtonContainer