import React from 'react'
import { TextField, MenuItem } from '@material-ui/core'

export default ({surveyTemplates, textFieldProps = {}, selectedSurveyTemplateId, handleChange}) =>
  <TextField
    label="Select a Survey"
    fullWidth
    {...textFieldProps}
    select
    value={selectedSurveyTemplateId || ""}
    onChange={handleChange}
  >
    {
      surveyTemplates.map(surveyTemplate => 
        <MenuItem key={surveyTemplate.id} value={surveyTemplate.id}>
          {surveyTemplate.name}
        </MenuItem>
      )
    }
  </TextField>