import React from 'react';

import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';

import Form from "../Form";

import Contact from 'react-shared/api/Contact';



const ContactEditContainer = ({id, onSuccess = () => null, onSubmitting = () => null, onError = () => null}) => {

  const { error, loading, data: {getContact: contact} = {} } = useQuery(Contact.queries.get, {variables: {id}});

  !!error && console.log(error)


  return (
    !!loading ? (
      <CircularProgress color="secondary" />
    ) : (
      <Form contact={contact} onSubmitting={onSubmitting} onSuccess={onSuccess} onError={onError} />
    )
  )
}

export default ContactEditContainer;