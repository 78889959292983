import React, { useState } from 'react'
import Edit from '../Edit';
import { Button, MenuItem } from '@material-ui/core';
import Modal from "Components/Modal"


export default ({id, render = "button", onSuccess = () => null, onSubmitting = () => null, onError = () => null}) => {
  const [showModal, setShowModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);


  return (
    <>
      <Modal
        title="Review"
        body={
          <Edit type={'review'} onSubmitting={() => setSubmitting(true)} id={id} onSuccess={response => [setShowModal(false), onSuccess(response), setSubmitting(false)]} />
        }
        onClose={() => setShowModal(false)}
        submitting={submitting}
        opened={!!showModal}
        saveButton={false}
      />
      {
        render === 'button' ? (
          <Button variant="contained" size="small" color="secondary" onClick={() => setShowModal(true)}>Review</Button>
        ) : (
          <MenuItem onClick={() => setShowModal(true)}>Review</MenuItem>
        )
      }
    </>
  )
}