import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import CampaignTemplate from 'react-shared/api/CampaignTemplate'
import { Link } from 'react-router-dom'
import CampaignTemplateEditButton from '../Edit/Button/CampaignTemplateEditContainer';
import CampaignNewButton from '../../Campaign/New/Button';
import MoreButtonContainer from '../../MoreButton';
import { DataTable } from 'gunner-react/web'

export default ({organizationId, onNewCampaign}) => {
  const fields = 
  {
    "Name": {
      value: item => <Link to={`/campaign-templates/${item.id}/survey-templates`}>{item.name}</Link>,
      sort: order =>  (a, b) => a.name > b.name ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Description": {
      value: item => item.description,
      sort: order =>  (a, b) => a.description > b.description ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Public": {
      value: item => item.public ? "Yes" : "No" ,
      sort: order =>  (a, b) => a.public > b.public ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Actions": {
      hideLabel: true,
      sort: null,
      value: item => 
        <MoreButtonContainer>
          {
            !item.public && <CampaignTemplateEditButton id={item.id} render="menu" />
          }
          <CampaignNewButton render="menu" label="Create Campaign from this template" onChange={onNewCampaign} campaignTemplateId={item.id} />
        </MoreButtonContainer>
    },
  }

  const { data: {listCampaignTemplates: {items: publicTemplates = []} = {}} = {}} = useQuery(CampaignTemplate.queries.list, {
    pollInterval: 3000,
    variables: {
      limit: 100,
      filter: {
        public: {
          eq: true
        }
      }
    }
  });

  return (
    <DataTable
      fields={fields}
      search={null}
      dataListParams={{
        prependedObjects: publicTemplates,
        useButton: true,
        query: CampaignTemplate.queries.listByOrganizationId,
        dataKey: "listCampaignTemplatesByOrganizationId",
        variables: {
          campaignTemplateOrganizationId: organizationId,
        }
      }}
    />
  )
}



// const CampaignTemplateListContainer = ({organizationId, doRefetch, onNewCampaign}) => {
  

//   const { error, loading, refetch, data: {listCampaignTemplatesByOrganizationId: {items: campaignTemplates = []} = {}} = {}} = useQuery(CampaignTemplate.queries.listByOrganizationId, {
//     pollInterval: 3000,
//     variables: {
//       campaignTemplateOrganizationId: organizationId,
//       limit: 100
//     }
//   });

//   const { data: {listCampaignTemplates: {items: publicTemplates = []} = {}} = {}} = useQuery(CampaignTemplate.queries.list, {
//     pollInterval: 3000,
//     variables: {
//       limit: 100,
//       filter: {
//         public: {
//           eq: true
//         }
//       }
//     }
//   });

//   const allTemplates = [
//     ...campaignTemplates,
//     ...publicTemplates.filter(pt => !campaignTemplates.find(ct => ct.id === pt.id))
//   ]

//   useEffect(() => {
//     !!doRefetch && refetch()
//   }, [doRefetch])

//   !!error && console.log(error)

//   return (
//     loading ? (
//       <CircularProgress color="secondary" />
//     ) : (
//       <CampaignTemplateListView onNewCampaign={onNewCampaign} campaignTemplates={allTemplates} />
//     )
//   )
// }

// export default CampaignTemplateListContainer;