import { convertArrayToCSV } from "convert-array-to-csv";
import { useState, useEffect } from 'react'
import { useQuery, useApolloClient } from '@apollo/client'
import Response from 'react-shared/api/Response'
import Survey from 'react-shared/api/Survey';
import Campaign from 'react-shared/api/Campaign';
import Prompt from 'react-shared/api/Prompt';

export default ({campaignId}) => {
  const header = ["Campaign", "Survey", "Tags", "Prompt", "Response", "Comments", 'Reviewer Name', 'Reviewer Email', 'Reviewed At (UTC -5)', 'Reviewer Comments', "Sender's Name", "Sender's Email", "Respondent Name", "Respondent Contact Info", "Contact Group", "Date (UTC -5)"];
  const [csvData, setCsvData] = useState(null);
  const [surveyToken, setSurveyToken] = useState(undefined);
  const [surveys, setSurveys] = useState([]);
  const [responses, setResponses] = useState([]);
  const client = useApolloClient();

  const { error: surveysError, loading: loadingSurveys, data: { listSurveysByCampaignId: {nextToken: nextSurveyToken, items = []} = {} } = {}} = useQuery(Survey.queries.listByCampaignId, {
    variables: {
      surveyCampaignId: campaignId,
      limit: 100,
      nextToken: surveyToken
    }
  })

  const { data: { getCampaign } = {}} = useQuery(Campaign.queries.get, {variables: {id: campaignId}});

  useEffect(() => {
    setSurveys([
      ...items,
      ...surveys
    ]);
    
  }, [JSON.stringify(items)])

  useEffect(() => {
    setSurveyToken(nextSurveyToken);
  }, [nextSurveyToken])

  useEffect(() => {
    surveyToken === null && //we loaded them all
    Promise.all(
      surveys.map(survey => 
        client.query({
          query: Response.queries.listBySurveyId,
          fetchPolicy: "network-only",
          variables: {
            responseSurveyId: survey.id,
            limit: 200
          }
        })
          .then(({data: {listResponsesBySurveyId: {items = []} = {}} = {}}) => !!items.length ? (
            items
           ) : (
             client.query({
              fetchPolicy: "network-only",
               query: Prompt.queries.listBySurveyTemplateId,
               variables: {
                 promptSurveyTemplateId: survey.surveySurveyTemplateId,
                 limit: 100
               }
             })
              .then(({data: {listPromptsBySurveyTemplateId: {items = []} = {}} = {}}) =>
                items.map(prompt => ({survey, prompt}))
              )
           ))
      )
    )
      .then(arrOfArrs => setResponses(arrOfArrs.flat(1)))
  }, [surveyToken])


  useEffect(() => {
    !!responses.length &&
    Promise.resolve(null)
      .then(() =>
        responses.map(response => [
          getCampaign.name,
          response.survey.surveyTemplate.name,
          (response.survey.tagSurveys?.items||[]).map(ts => ts.tag.name).join(";"),
          response.prompt?.body || "",
          response.option?.name || "",
          response.reason || "",
          response.reviewer?.name || "",
          response.reviewer?.email || "",
          response.reviewer?.reviewedAt || "",
          response.reviewer?.reviewComment || "",
          response.survey.user.name || "",
          response.survey.user.email,
          response.survey.contact.name,
          response.survey.contact.email || response.survey.contact.phone,
          response.survey.contactGroup.name,
          response.createdAt || ""
        ])
      )
      .then(data => 
        setCsvData(
          convertArrayToCSV(data, {
            header,
            separator: ','
          })
        )
      )
  }, [responses.length, getCampaign, header.length])

  !!surveysError && console.log(surveysError)


  return csvData;
}