import React, { useState, useEffect } from 'react'
import ContactGroupSelectView from './ContactGroupSelectView'
import { useQuery } from '@apollo/client'
import ContactGroup from 'react-shared/api/ContactGroup'

export default ({organizationId, onChange}) => {
  const [selectedContactGroupId, setSelectedContactGroupId] = useState(null);

  const {data: {listContactGroupsByOrganizationId: {items = []} = {}} = {}} = useQuery(ContactGroup.queries.listByOrganizationId, {
    variables: {
      contactGroupOrganizationId: organizationId,
      limit: 100
    }
  });

  const handleChange = ({target: {value}}) =>
    setSelectedContactGroupId(value)

  useEffect(() => {
    (items.length === 1 || (!selectedContactGroupId && !!items.length)) &&
    setSelectedContactGroupId(items[0].id)
  }, [items.length, selectedContactGroupId])

  useEffect(() => {
    onChange(selectedContactGroupId)
  }, [selectedContactGroupId])


  return (
    <ContactGroupSelectView 
      contactGroups={items.filter(i => i.name !== "Hidden")} 
      selectedContactGroupId={selectedContactGroupId} 
      handleChange={handleChange}
    />
  )
}