import React, { useState, useContext, useEffect } from 'react';

// import OrganziationEdit from 'Components/Organization/Edit';
import { CurrentUserContext } from 'gunner-react'
import ContactGroupList from 'Components/ContactGroup/List';
import { Paper, Button } from '@material-ui/core';
import Modal from 'Components/Modal';
import ContactGroupNew from 'Components/ContactGroup/New';
import NavigationBreadcrumbsContainer from 'Components/Navigation/Breadcrumbs';
import ContactUploader from 'Components/Contact/Uploader';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HelpIcon from '@material-ui/icons/Help';
import PublishIcon from '@material-ui/icons/Publish';
import { Typography } from '@material-ui/core';
import { AppBarContext } from 'gunner-react/web';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { IconButton } from '@material-ui/core';
import { useHasVisitedScreen } from 'gunner-react/web';

const AdminContactGroupListRoute = () => {
  const currentUser = useContext(CurrentUserContext);
  const [showModal, setShowModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [doUpload, setDoUpload] = useState(false);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(!useHasVisitedScreen());
  const appBar = useContext(AppBarContext);

  useEffect(() => {
    appBar.setTitle(`Contact Groups`)

    appBar.setOnClickHelp(() => () => setShowHelpModal(true))
    appBar.setRightButtons([
      <IconButton
        aria-label="add"
        aria-haspopup="true"
        onClick={() => setShowModal(true)}
        color="inherit"
      >
        <AddCircleOutlineIcon />
      </IconButton>
      ,
      <IconButton
        aria-label="upload"
        aria-haspopup="true"
        onClick={() => setShowUploadModal(true)}
        color="inherit"
      >
        <PublishIcon />
      </IconButton>
    ])

    return appBar.reset;
  }, []);

  return (
    <>
      <NavigationBreadcrumbsContainer />

      {/* <Button variant="contained" color="secondary" onClick={() => setShowModal(true)}>Add Group</Button>
      <Button variant="contained" color="secondary" onClick={() => setShowUploadModal(true)}>Upload CSV</Button> */}

      <Paper square>
        <ContactGroupList organizationId={currentUser.userOrganizationId} doRefetch={!showModal || !showUploadModal} />
      </Paper>


      <Modal
        title="Upload Contacts"
        body={
          <ContactUploader onError={err => [window.alert(err), setDoUpload(false)]} doSubmit={doUpload} onSubmitSuccess={() => [setDoUpload(false), setShowUploadModal(false)]} />
        }
        onClose={() => setShowUploadModal(false)}
        submitting={doUpload}
        opened={!!showUploadModal}
        saveButton={{
          text: !!doUpload ? "Uploading" : "Upload",
          onClick: () => setDoUpload(true),
          ButtonProps: {
            disabled: !!doUpload
          }
        }}
      />

      <Modal
        title="Create Contact Group"
        body={
          <ContactGroupNew organizationId={currentUser.userOrganizationId} onSuccess={() => setShowModal(false)} />
        }
        onClose={() => setShowModal(false)}
        submitting={false}
        opened={!!showModal}
        saveButton={false}
      />

      <Modal
        title="Help"
        body={
          <>
            <Typography paragraph>Contacts are who your organization is allowed to send surveys to.</Typography>
            <Typography paragraph>Adding groups allows you to organize these contacts as you see fit.</Typography>
            <Typography paragraph>You may create a group manually or upload a CSV file the group name and contacts' information.</Typography>
          </>
        }
        onClose={() => setShowHelpModal(false)}
        submitting={false}
        opened={!!showHelpModal}
        saveButton={false}
      />

      {/* <SpeedDial
        ariaLabel="SpeedDial"
        icon={<SpeedDialIcon />}
        onClose={() => setSpeedDialOpen(false)}
        onOpen={() => setSpeedDialOpen(true)}
        open={speedDialOpen}
        direction={'up'}
      >
        <SpeedDialAction
          icon={<HelpIcon />}
          tooltipTitle={"Help"}
          onClick={() => setShowHelpModal(true)}
          tooltipOpen
        />
        <SpeedDialAction
          icon={<AddCircleIcon />}
          tooltipTitle={"Add Group"}
          onClick={() => setShowModal(true)}
          tooltipOpen
        />
        <SpeedDialAction
          icon={<PublishIcon />}
          tooltipTitle={"Upload CSV"}
          onClick={() => setShowUploadModal(true)}
          tooltipOpen
        />
      </SpeedDial> */}
    </>
  )
}


export default AdminContactGroupListRoute; 
