import gql from "graphql-tag"

const Fragments = {}

Fragments.Tag = {
  public: gql`
    fragment TagPublic on Tag {
      id
      name
      tagUserId
      createdAt
      updatedAt
      adminGroup
      memberGroup
    }
  `,
}

Fragments.TagSurvey = {
  public: gql`
    fragment TagSurveyPublic on TagSurvey {
      id
      tagId
      surveyId
      tagSurveyUserId
      adminGroup
      memberGroup
      createdAt
      updatedAt
      tag {
        ...TagPublic
      }
      survey {
        id
        surveyCampaignId
        surveySurveyTemplateId
        surveyContactId
        surveyContactGroupId
        createdAt
        updatedAt
        lastSeenAt
        includeNotice
        surveyUserId
        adminGroup
        memberGroup
        submitted
        contact {
          id
          name
        }
        contactGroup {
          id
          name
        }
        tagSurveys {
          nextToken
          items {
            id
            tagId
            tag {
              id
              name
            }
          }
        }
        surveyTemplate {
          id
          name
        }
      }
    }
    ${Fragments.Tag.public}
  `,
}


Fragments.TagContact = {
  public: gql`
    fragment TagContactPublic on TagContact {
      id
      tagId
      contactId
      tagContactUserId
      adminGroup
      memberGroup
      createdAt
      updatedAt
      tag {
        ...TagPublic
      }
      contact {
        id
        name
        email
        phone
        createdAt
        updatedAt
        adminGroup
        memberGroup
        tagContacts {
          items {
            id
            tagId
            tag {
              id
              name
            }
          }
          nextToken
        }
      }
    }
    ${Fragments.Tag.public}
  `,
}

Fragments.Organization = {
  public: gql`
    fragment OrganizationPublic on Organization {
      id
      organizationUserId
      name
      logoUrl
      createdAt
      updatedAt
      thankYouMessage
      planType
      adminGroup
      memberGroup
    }
  `,
}

Fragments.User = {
  public: gql`
    fragment UserPublic on User {
      id
      createdAt
      updatedAt
      name
      email
      phone
      adminGroup
      memberGroup
      title
      userOrganizationId
      inactive
      organizationAdmin
      organization {
        ...OrganizationPublic
      }
    }
    ${Fragments.Organization.public}
  `,
}

Fragments.Invitation = {
  public: gql`
    fragment InvitationPublic on Invitation {
      id
      createdAt
      updatedAt
      contactInfo
      invitationUserId
      invitationAcceptorId
      code
      accepted
      adminGroup
      memberGroup
      acceptor {
        id
        name
      }
      user {
        id
        name
        userOrganizationId
        organization {
          ...OrganizationPublic
        }
      }
    }
    ${Fragments.Organization.public}
  `
}

Fragments.ContactGroup = {
  public: gql`
    fragment ContactGroupPublic on ContactGroup {
      id
      name
      contactGroupUserId
      contactGroupOrganizationId
      createdAt
      updatedAt
      adminGroup
      memberGroup
    }
  `,
}

Fragments.SurveyTemplate = {
  public: gql`
    fragment SurveyTemplatePublic on SurveyTemplate {
      id
      name
	    position
      description
      notice
	    surveyTemplateCampaignTemplateId
      surveyTemplateUserId
      createdAt
      updatedAt
    }
  `,
}

Fragments.Prompt = {
  public: gql`
    fragment PromptPublic on Prompt {
      id
      body
      position
      promptSurveyTemplateId

      adminGroup
      memberGroup

      createdAt
      updatedAt
      promptUserId

      options {
        nextToken
        items {
          id
          value
          name
          iconUrl
          optionPromptId
          position

          createdAt
          updatedAt
          optionUserId

          adminGroup
          memberGroup
        }
      }
    }
  `,
}

Fragments.Option = {
  public: gql`
    fragment OptionPublic on Option {
      id
      value
      name
      iconUrl
      optionPromptId
      position

      createdAt
      updatedAt
      optionUserId

      adminGroup
      memberGroup
      prompt {
        ...PromptPublic
      }
    }
    ${Fragments.Prompt.public}
  `,
}

Fragments.CampaignTemplate = {
  public: gql`
    fragment CampaignTemplatePublic on CampaignTemplate {
      id
      campaignTemplateUserId
      public
      name
      description
      campaignTemplateOrganizationId
      createdAt
      updatedAt
    }
  `,
}

Fragments.Campaign = {
  public: gql`
    fragment CampaignPublic on Campaign {
      id
      name
      campaignCampaignTemplateId
      campaignOrganizationId
      campaignUserId
      inactive
      
      createdAt
      updatedAt
      adminGroup
      memberGroup

      campaignTemplate {
        ...CampaignTemplatePublic
      }
    }
    ${Fragments.CampaignTemplate.public}
  `,
}

Fragments.Contact = {
  public: gql`
    fragment ContactPublic on Contact {
      id
      name
      email
      phone
      createdAt
      updatedAt
      adminGroup
      memberGroup
      tagContacts {
        items {
          ...TagContactPublic
        }
        nextToken
      }
      contactContactGroups {
        items {
          id
          contactId
          contactGroupId
        }
        nextToken
      }
    }
    ${Fragments.TagContact.public}
  `,
}


Fragments.ContactContactGroup = {
  public: gql`
    fragment ContactContactGroupPublic on ContactContactGroup {
      id
      contactId
      contactGroupId
      
      createdAt
      updatedAt

      adminGroup
      memberGroup

      contact {
        ...ContactPublic
      }
      contactGroup {
        ...ContactGroupPublic
      }
    }
    ${Fragments.Contact.public}
    ${Fragments.ContactGroup.public}
  `,
}

Fragments.Survey = {
  public: gql`
    fragment SurveyPublic on Survey {
      id
      surveyCampaignId
      surveySurveyTemplateId
      surveyContactId
      surveyContactGroupId
      sendAt
      createdAt
      updatedAt
      lastSeenAt
      includeNotice
      surveyUserId
      adminGroup
      memberGroup
      submitted
      contact {
        ...ContactPublic
      }
      contactGroup {
        ...ContactGroupPublic
      }
      tagSurveys {
        nextToken
        items {
          ...TagSurveyPublic
        }
      }
      responses {
        nextToken
        items {
          id
          responseOptionId
          responseSurveyId
          responsePromptId
          responseReviewerId
          reason
          
          reviewedAt
          reviewComment
          reasonSeenAt

          createdAt
          updatedAt

          responseUserId

          adminGroup
          memberGroup
        }
      }
      surveyTemplate {
        ...SurveyTemplatePublic
      }
      user {
        ...UserPublic
      }
    }
    ${Fragments.User.public}
    ${Fragments.SurveyTemplate.public}
    ${Fragments.Contact.public}
    ${Fragments.ContactGroup.public}
    ${Fragments.TagSurvey.public}
  `,
}

Fragments.Response = {
  public: gql`
    fragment ResponsePublic on Response {
      id
      responseOptionId
      responseSurveyId
      responseSurveyTemplateId
      responsePromptId
      responseReviewerId
      reason
      
      reviewedAt
      reviewComment
      reasonSeenAt

      createdAt
      updatedAt

      responseUserId

      adminGroup
      memberGroup
      survey {
        ...SurveyPublic
      }
      option {
        ...OptionPublic
      }
      prompt {
        ...PromptPublic
      }
      reviewer {
        ...UserPublic
      }
    }
    ${Fragments.Survey.public}
    ${Fragments.Option.public}
    ${Fragments.Prompt.public}
    ${Fragments.User.public}
  `,
}


export default Fragments;