import React from 'react'
import Survey from 'react-shared/api/Survey'

import { Link } from 'react-router-dom'
import moment from 'moment';
import { DataTable } from 'gunner-react/web'
import SurveyResendButton from '../ResendButton/SurveyResendButtonContainer';
import MoreButton from '../../MoreButton';
import SurveyDeleteButton from '../DeleteButton';

const fields = 
  {
    "Survey Name": {
      value: item => <Link to={`/surveys/${item.id}/responses`}>{item.surveyTemplate.name}</Link>,
      sort: order =>  (a, b) => a.surveyTemplate.name > b.surveyTemplate.name ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Tags": {
      value: item => <>
        {(item.tagSurveys?.items||[]).map(ts => <React.Fragment key={ts.id}><Link  to={`/tags/${ts.tagId}/surveys`}>{ts.tag.name}</Link>&nbsp;&nbsp;</React.Fragment>) }
      </>,
      sort: null,
    },
    "Contact": {
      value: item => <Link to={`/contacts/${item.contact.id}/surveys`}>{item.contact.name}</Link>,
      sort: order =>  (a, b) => a.contact.name > b.contact.name ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Send At": {
      value: item => moment(item.sendAt || item.updatedAt).format('MM/DD/YYYY H:mm'),
      sort: order =>  (a, b) => (a.sendAt || a.updatedAt) > (b.sendAt || b.updatedAt) ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Seen At": {
      value: item => !!item.lastSeenAt ? moment(item.lastSeenAt).format('MM/DD/YYYY H:mm') : "",
      sort: order =>  (a, b) => (a.lastSeenAt || "") > (b.lastSeenAt || "") ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Completed?": {
      value: item => !!item.submitted ? "Yes" : "No",
      sort: order =>  (a, b) => !!a.submitted > !!b.submitted ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Actions": {
      hideLabel: true,
      sort: null,
      value: item => 
        !item.submitted ? (
          <MoreButton>
            <SurveyResendButton render="menu" id={item.id} />
            <SurveyDeleteButton render="menu" id={item.id} />
          </MoreButton>
        ) : (
          null
        )
    },
  }

export default ({campaignId, contactId, contactGroupId}) => {
  const [queryField, api, variables] = !!campaignId ? (
    ['listSurveysByCampaignId', 'listByCampaignId', {surveyCampaignId: campaignId}]
  ) : !!contactGroupId ? (
    ['listSurveysByContactGroupId', 'listByContactGroupId', {surveyContactGroupId: contactGroupId}]
  ) : (
    ['listSurveysByContactId', 'listByContactId', {surveyContactId: contactId}]
  );

  return (
    <>
      <DataTable
        fields={fields}
        search={null}
        dataListParams={{
          useButton: true,
          query: Survey.queries[api],
          variables: {
            sortDirection: "DESC",
            ...variables,
          },
          dataKey: queryField,
        }}
      />
    </>
  )
}
