import React, { useState, useEffect } from 'react'
import CampaignSelectView from './CampaignSelectView'
import { useQuery } from '@apollo/client'
import Campaign from 'react-shared/api/Campaign'

export default ({selectedCampaignId: initialCampaignId, organizationId, onChange}) => {
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);

  const {data: {listCampaignsByOrganizationId: {items = []} = {}} = {}} = useQuery(Campaign.queries.listByOrganizationId, {
    pollInterval: 3000,
    variables: {
      campaignOrganizationId: organizationId,
      limit: 100,
      filter: {
        inactive: {
          ne: true
        }
      }
    }
  });

  const handleChange = ({target: {value}}) =>
    setSelectedCampaignId(value)

  useEffect(() => {
    !!initialCampaignId && setSelectedCampaignId(initialCampaignId)
  }, [initialCampaignId])

  useEffect(() => {
    items.length === 1 &&
    setSelectedCampaignId(items[0].id)
  }, [items.length])

  useEffect(() => {
    onChange(selectedCampaignId)
  }, [selectedCampaignId])


  return (
    <CampaignSelectView 
      campaigns={items} 
      selectedCampaignId={selectedCampaignId} 
      handleChange={handleChange}
    />
  )
}