import moment from "moment";
import { uniqBy } from 'ramda';
import React, { useContext, useEffect, useState } from 'react';

// import OrganziationEdit from 'Components/Organization/Edit';
import { useMutation, useQuery } from '@apollo/client';
import { AppBar, Box, Checkbox, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Tab, TableFooter, Tabs, TextField, Typography, Divider } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ModalView from 'Components/Modal';
import SurveyTemplateSelect from 'Components/SurveyTemplate/Select';
import { CurrentUserContext } from 'gunner-react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import Campaign from 'react-shared/api/Campaign';
import Option from 'react-shared/api/Option';
import Prompt from 'react-shared/api/Prompt';
import Response from 'react-shared/api/Response';
import Tag from 'react-shared/api/Tag';
import User from 'react-shared/api/User';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const WeeklyTooltip = ({ active, payload, label }) => 
  !active ? null :
    <div className="custom-tooltip">
      <p className="label">{`Week ${label+1}`}</p>
      <p className="score">{`Score: ${payload[0].value}`}</p>
      <p className="responses">{`Responses: ${payload[0].payload.count}`}</p>
    </div>

const WeeklyChart = ({scores}) => console.log("SCORES", scores) ||
  <ResponsiveContainer>
    <LineChart data={scores.map(score => ({name: score.weekNumber, score: score.points, count: score.count}))} margin={{top: 0, right: 5, left: 0, bottom: 5}}>
      <Tooltip content={<WeeklyTooltip />} />
      
      <Line name="Score" type="monotone" dataKey="score" stroke="#b78df9" dot={false} />

    </LineChart>
  </ResponsiveContainer>

const UserWeekCell = ({userIds, promptId, weekNumber, onScore, onCount}) => {
  const currentUser = useContext(CurrentUserContext);
  const { responses } = useAllResponses({
    adminGroup: `${currentUser?.userOrganizationId}-Admins`, 
    responsePromptId: promptId, 
    endsAt: moment().subtract({weeks: (11 - weekNumber)}).endOf('day').toDate(),
    startsAt: moment().subtract({weeks: (1 + (11 - weekNumber))}).endOf('day').toDate(),
  });
  const filteredResponses = responses.filter(response => userIds.includes(response.survey.surveyUserId));

  useEffect(() => {
    onCount(filteredResponses.length);
    onScore(((filteredResponses.reduce((sum, response) => sum + response.option.value, 0))));
  }, [filteredResponses.length])

  
  

  return (
    <TableCell>{(parseInt(filteredResponses.reduce((sum, response) => sum + response.option.value, 0) / filteredResponses.length) || "N/A").toString()}</TableCell>
  )
}

const UserWeekRow = ({userIds, label, promptId}) => {
  const [totalScore, setTotalScore] = useState({});
  const [totalCount, setTotalCount] = useState({});
  const weekNumbers = [...Array(12).keys()];

  return (
    <TableRow>
      <TableCell>{label}</TableCell>
      {
        weekNumbers.map(num => 
          <UserWeekCell 
            onCount={count => setTotalCount(totalCount => ({
              ...totalCount,
              [num]: (totalCount[num] || 0) + count
            }))}
            onScore={score => setTotalScore(totalScore => ({
              ...totalScore,
              [num]: (totalScore[num] || 0) + score
            }))}
            key={num} 
            promptId={promptId} 
            userIds={userIds} 
            weekNumber={num} 
          />
        )
      }
      <TableCell>{Object.values(totalCount).reduce((sum, currentValue) => sum + currentValue, 0)}</TableCell>
      <TableCell>{(parseInt(Object.values(totalScore).reduce((sum, currentValue) => sum + currentValue, 0) / Object.values(totalCount).reduce((sum, currentValue) => sum + currentValue, 0)) || "N/A")}</TableCell>
      <TableCell>
        <div style={{width: "150px", height: "50px"}}>
          <WeeklyChart scores={Object.entries(totalScore).map(([key, value]) => ({weekNumber: key, points: parseInt((value / totalCount[key]) || 0), count: totalCount[key]}) )} />
        </div>
      </TableCell>
    </TableRow>
  )
}


const WeeklyReport = ({promptId}) => {
  const currentUser = useContext(CurrentUserContext);
  const weekNumbers = [...Array(12).keys()];
  
  const {data: {listUsersByOrganizationId: {items: activeUsers = []} = {}} = {}} = useQuery(User.queries.listByOrganizationId, {
    variables: {
      limit: 200,
      userOrganizationId: currentUser?.userOrganizationId,
      filter: {
        inactive: {
          ne: true
        }
      }
    }
  });

  const {data: {listUsersByOrganizationId: {items: inactiveUsers = []} = {}} = {}} = useQuery(User.queries.listByOrganizationId, {
    variables: {
      limit: 200,
      userOrganizationId: currentUser?.userOrganizationId,
      filter: {
        inactive: {
          eq: true
        }
      }
    }
  });


  return (
    <Box style={{overflow: 'auto'}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Member</TableCell>
            {
              weekNumbers.map(num => <TableCell key={num}>W{num+1}</TableCell>)
            }
            <TableCell>#</TableCell>
            <TableCell>Avg</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            activeUsers.slice().sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(user => 
              <UserWeekRow 
                key={user.id} 
                userIds={[user.id]}
                label={user.name} 
                promptId={promptId}
              />
            )
          }
          <UserWeekRow 
            userIds={inactiveUsers.map(ia => ia.id)}
            label="Inactive Users" 
            promptId={promptId}
          />
          <UserWeekRow 
            userIds={[...inactiveUsers.map(ia => ia.id), ...activeUsers.map(ia => ia.id)]}
            label="Total" 
            promptId={promptId}
          />
        </TableBody>
      </Table>
    </Box>
  )
}

const ResponseItem = ({response: passedResponse}) => {
  const [doSubmit, setDoSubmit] = useState(false);
  const [reviewComment, setReviewComment] = useState(null);
  const currentUser = useContext(CurrentUserContext);
  const {data: {getResponse} = {}} = useQuery(Response.queries.get, {variables: {id: passedResponse.id}});
  const response = getResponse ?? passedResponse;
  const {data: {getUser} = {}} = useQuery(User.queries.get, {variables: {id: response.responseReviewerId}});
  const [updateResponse] = useMutation(Response.mutations.update, {
    variables: {
      input: {
        id: response.id,
        responseReviewerId: currentUser?.id,
        reviewedAt: (new Date()).toISOString(),
        reviewComment: reviewComment || undefined,
        createdAt: response.createdAt,
        responsePromptId: response.responsePromptId,
        responseSurveyTemplateId: response.responseSurveyTemplateId
      }
    },
    optimisticResponse: {
      __typename: "Mutation",
      updateResponse: {
        ...response,
        responseReviewerId: currentUser?.id,
        reviewedAt: (new Date()).toISOString(),
        reviewComment
      }
    },
    refetchQueries: [
      {
        query: Response.queries.get,
        variables: {id: response.id}
      }
    ]
  });


  useEffect(() => {
    !!doSubmit &&
    setReviewComment(window.prompt("Add a comment (optional)") ?? "")
  }, [doSubmit])

  useEffect(() => {
    reviewComment !== null &&
    updateResponse()
      .then(() => [
        setDoSubmit(false),
        setReviewComment(null)
      ])
  }, [reviewComment])

  return (
    <ListItem button={!getUser} onClick={!getUser ? () => setDoSubmit(true) : null}>
      <ListItemText 
        primary={`${response.survey.contact.name} ${response.survey.contact.email ?? ""} ${response.survey.contact.phone ?? ""}`}
        secondary={
          <>
            <Box component="blockquote">{response.reason}</Box>
            <Typography paragraph>
              {
                !!getUser &&
                <>
                  Reviewed {!response.reviewedAt ? "" : moment(response.reviewedAt).format("M-D-YYYY")} by {getUser.name}
                </>
              }
              {
                !!response.reviewComment && 
                <Box component="blockquote">{response.reviewComment}</Box>
              }
            </Typography>
          </>
        } 
      />
      <ListItemSecondaryAction>
        <Checkbox
          checked={!!getUser}
          disabled={!!getUser}
          tabIndex={-1}
          disableRipple
          onChange={() => setDoSubmit(true)}
        />
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const GraphBar = ({option, period1Responses}) => {
  const [showModal, setShowModal] = useState(false);
  const colors = [
    "#39b549",
    "#91c95f",
    "#faaf40",
    "#f05a29",
    "#df1f24"
  ]

  return (
    <Box flex={1} mr={1} ml={1} key={option.id} maxWidth={100}>
      <ModalView 
        title={"Review Comments"}
        subTitle={"Review the responses below and tap them to note it has been handled."}
        onClose={() => setShowModal(false)}
        opened={!!showModal}
        saveButton={null}
        body={<List>
          {
            period1Responses.filter(response => !!response.reason).map(response =>
              <ResponseItem response={response} key={response.id} />
            )
          }
        </List>}
      />
      <Box 
        width="100%" 
        alignItems="flex-end" 
        justifyContent="center" 
        display="flex" 
        height={ period1Responses.filter(response => response.responseOptionId === option.id).length * 40} 
        style={{backgroundColor: colors[option.position-1]}}
      >
        {
          period1Responses.filter(response => response.responseOptionId === option.id).length > 0 && 
          <Typography variant={"h4"} style={{textAlign: "center"}}>{period1Responses.filter(response => response.responseOptionId === option.id).length}</Typography>
        }
      </Box>
      <img 
        style={{cursor: "pointer", maxWidth: "100%", height: "auto"}} 
        src={option.iconUrl || require(`../../../assets/images/survey/${option.position}.png`)} 
        onClick={() => setShowModal(true)}
      />
    </Box>
  )
}

const BarChart = ({numberOfDaysBack, promptId}) => {
  const currentUser = useContext(CurrentUserContext)
  const {responses: period1Responses} = useAllResponses({
    adminGroup: `${currentUser?.userOrganizationId}-Admins`, 
    responsePromptId: promptId, 
    endsAt: moment().subtract({days: (numberOfDaysBack || 0) * 0}).endOf('day').toDate(),
    startsAt: moment().subtract({days: (numberOfDaysBack || 0) * 1}).endOf('day').toDate(),
  });

  const { error, loading, refetch, data: {listOptionsByPromptId: {items: options = []} = {}} = {}} = useQuery(Option.queries.listByPromptId, {
    variables: {
      optionPromptId: promptId,
      limit: 100,
      sortDirection: "DESC"
    }
  });

  return (
    <Box display="flex" flexDirection="row" alignItems="flex-end">
      {
        options.map(option => 
          <GraphBar period1Responses={period1Responses} option={option} key={option.id} />
        )
      }
    </Box>
  )
}

const Scores = ({numberOfDaysBack, promptId}) => {
  const currentUser = useContext(CurrentUserContext)
  const {responses: period1Responses} = useAllResponses({
    adminGroup: `${currentUser?.userOrganizationId}-Admins`, 
    responsePromptId: promptId, 
    endsAt: moment().subtract({days: (numberOfDaysBack || 0) * 0}).endOf('day').toDate(),
    startsAt: moment().subtract({days: (numberOfDaysBack || 0) * 1}).endOf('day').toDate(),
  });

  const {responses: period2Responses} = useAllResponses({
    adminGroup: `${currentUser?.userOrganizationId}-Admins`, 
    responsePromptId: promptId, 
    endsAt: moment().subtract({days: (numberOfDaysBack || 0) * 1}).endOf('day').toDate(),
    startsAt: moment().subtract({days: (numberOfDaysBack || 0) * 2}).endOf('day').toDate(),
  });

  const period1Score = parseInt(period1Responses.reduce((sum, response) => sum + response.option.value, 0) / period1Responses.length);
  const period2Score = parseInt(period2Responses.reduce((sum, response) => sum + response.option.value, 0) / period2Responses.length);
  const delta = period1Score - period2Score;

  return (
    <>
      <Typography>Score: {period1Score || "N/A"} / Change: {delta > 0 ? `+${delta}` : delta || "N/A"}</Typography>
      {/* <Typography variant={"h6"}></Typography> */}
    </>
  )
}

const CustomTooltip = ({ active, payload, label }) => 
  !active ? null :
    <div className="custom-tooltip" style={{border: "1px solid gray", padding: '5px'}}>
      <p style={{padding: '0', margin: '2px 0'}} className="label">{`${label}`}</p>
      <p style={{padding: '0', margin: '2px 0'}} className="score">{`Score: ${payload[0].payload.score || 'N/A'}`}</p>
      <p style={{padding: '0', margin: '2px 0'}} className="responses">{`Responses: ${payload[0].payload.count}`}</p>
    </div>

const TrendChart = ({numberOfDaysBack, promptId}) => {
  const currentUser = useContext(CurrentUserContext);
  
  const {responses: period1Responses} = useAllResponses({
    adminGroup: `${currentUser?.userOrganizationId}-Admins`, 
    responsePromptId: promptId, 
    endsAt: moment().subtract({days: (numberOfDaysBack || 0) * 0}).endOf('day').toDate(),
    startsAt: moment().subtract({days: (numberOfDaysBack || 0) * 1}).endOf('day').toDate(),
  });

  const {responses: period2Responses} = useAllResponses({
    adminGroup: `${currentUser?.userOrganizationId}-Admins`, 
    responsePromptId: promptId, 
    endsAt: moment().subtract({days: (numberOfDaysBack || 0) * 1}).endOf('day').toDate(),
    startsAt: moment().subtract({days: (numberOfDaysBack || 0) * 2}).endOf('day').toDate(),
  });

  const {responses: period3Responses} = useAllResponses({
    adminGroup: `${currentUser?.userOrganizationId}-Admins`, 
    responsePromptId: promptId, 
    endsAt: moment().subtract({days: (numberOfDaysBack || 0) * 2}).endOf('day').toDate(),
    startsAt: moment().subtract({days: (numberOfDaysBack || 0) * 3}).endOf('day').toDate(),
  });

  const {responses: period4Responses} = useAllResponses({
    adminGroup: `${currentUser?.userOrganizationId}-Admins`, 
    responsePromptId: promptId, 
    endsAt: moment().subtract({days: (numberOfDaysBack || 0) * 3}).endOf('day').toDate(),
    startsAt: moment().subtract({days: (numberOfDaysBack || 0) * 4}).endOf('day').toDate(),
  });

  const {responses: period5Responses} = useAllResponses({
    adminGroup: `${currentUser?.userOrganizationId}-Admins`, 
    responsePromptId: promptId, 
    endsAt: moment().subtract({days: (numberOfDaysBack || 0) * 4}).endOf('day').toDate(),
    startsAt: moment().subtract({days: (numberOfDaysBack || 0) * 5}).endOf('day').toDate(),
  });

  const {responses: period6Responses} = useAllResponses({
    adminGroup: `${currentUser?.userOrganizationId}-Admins`, 
    responsePromptId: promptId, 
    endsAt: moment().subtract({days: (numberOfDaysBack || 0) * 5}).endOf('day').toDate(),
    startsAt: moment().subtract({days: (numberOfDaysBack || 0) * 6}).endOf('day').toDate(),
  });

  const {responses: period7Responses} = useAllResponses({
    adminGroup: `${currentUser?.userOrganizationId}-Admins`, 
    responsePromptId: promptId, 
    endsAt: moment().subtract({days: (numberOfDaysBack || 0) * 6}).endOf('day').toDate(),
    startsAt: moment().subtract({days: (numberOfDaysBack || 0) * 7}).endOf('day').toDate(),
  });

  return (
    <ResponsiveContainer>
      <LineChart data={[
        {
          name: moment().subtract({days: (numberOfDaysBack || 0) * 6}).endOf('day').format("M-D-YYYY"),
          score: parseInt(period7Responses.reduce((sum, response) => sum + response.option.value, 0) / period7Responses.length),
          count: period7Responses.length
        },
        {
          name: moment().subtract({days: (numberOfDaysBack || 0) * 5}).endOf('day').format("M-D-YYYY"),
          score: parseInt(period6Responses.reduce((sum, response) => sum + response.option.value, 0) / period6Responses.length),
          count: period6Responses.length
        },
        {
          name: moment().subtract({days: (numberOfDaysBack || 0) * 4}).endOf('day').format("M-D-YYYY"),
          score: parseInt(period5Responses.reduce((sum, response) => sum + response.option.value, 0) / period5Responses.length),
          count: period5Responses.length
        },
        {
          name: moment().subtract({days: (numberOfDaysBack || 0) * 3}).endOf('day').format("M-D-YYYY"),
          score: parseInt(period4Responses.reduce((sum, response) => sum + response.option.value, 0) / period4Responses.length),
          count: period4Responses.length
        },
        {
          name: moment().subtract({days: (numberOfDaysBack || 0) * 2}).endOf('day').format("M-D-YYYY"),
          score: parseInt(period3Responses.reduce((sum, response) => sum + response.option.value, 0) / period3Responses.length),
          count: period3Responses.length
        },
        {
          name: moment().subtract({days: (numberOfDaysBack || 0) * 1}).endOf('day').format("M-D-YYYY"),
          score: parseInt(period2Responses.reduce((sum, response) => sum + response.option.value, 0) / period2Responses.length),
          count: period2Responses.length
        },
        {
          name: moment().subtract({days: (numberOfDaysBack || 0) * 0}).endOf('day').format("M-D-YYYY"),
          score: parseInt(period1Responses.reduce((sum, response) => sum + response.option.value, 0) / period1Responses.length),
          count: period1Responses.length
        },
      ]}
      >
        <Tooltip content={<CustomTooltip />} />
        <YAxis type="number" domain={['auto', 'auto']} />
        <XAxis dataKey="name" />
        <Line name="Score" type="monotone" dataKey="score" stroke="#b78df9" activeDot={{r: 8}} />

      </LineChart>
    </ResponsiveContainer>
  )
}

const UserResponseRow = ({label, responses, prompts}) =>
  <TableRow>
    <TableCell>{label}</TableCell>
    <TableCell>{responses.length}</TableCell>
    {
      prompts.map((prompt, i) => 
        <TableCell key={prompt.id}>
          {
            (parseInt(responses.reduce((sum, response) => sum + response.option.value, 0) / responses.filter(response => response.responsePromptId === prompt.id).length) || "N/A").toString()
          }
        </TableCell>
      )
    }
  </TableRow>

const UserResponseTable = ({surveyTemplateId, responses}) => {
  const currentUser = useContext(CurrentUserContext);
  const {data: {listUsersByOrganizationId: {items: activeUsers = []} = {}} = {}} = useQuery(User.queries.listByOrganizationId, {
    variables: {
      limit: 200,
      userOrganizationId: currentUser?.userOrganizationId,
      filter: {
        inactive: {
          ne: true
        }
      }
    }
  });

  const {data: {listUsersByOrganizationId: {items: inactiveUsers = []} = {}} = {}} = useQuery(User.queries.listByOrganizationId, {
    variables: {
      limit: 200,
      userOrganizationId: currentUser?.userOrganizationId,
      filter: {
        inactive: {
          eq: true
        }
      }
    }
  });

  const {data: {listPromptsBySurveyTemplateId: {items: prompts = []} = {}} = {}} = useQuery(Prompt.queries.listBySurveyTemplateId, {
    variables: {
      limit: 200,
      promptSurveyTemplateId: surveyTemplateId
    }
  });

  return (
    <Box style={{overflow: 'auto'}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Member</TableCell>
            <TableCell>Responses</TableCell>
            {
              prompts.map((prompt, i) => 
                <TableCell key={prompt.id}>
                  Q{i+1}
                </TableCell>
              )
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            activeUsers.slice().sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(user => 
              <UserResponseRow 
                key={user.id} 
                label={user.name} 
                responses={responses.filter(response => response.survey.surveyUserId === user.id)}
                prompts={prompts}
              />
            )
          }
          <UserResponseRow 
            label={`Inactive Users (${inactiveUsers.length})`} 
            responses={responses.filter(response => inactiveUsers.map(ia => ia.id).includes(response.survey.surveyUserId))}
            prompts={prompts}
          />
        </TableBody>
        <TableFooter>
          {
            prompts.map((prompt, i) => 
              <TableRow key={prompt.id}>
                <TableCell colSpan={prompts.length + 2}>
                  Q{i+1}: {prompt.body}
                </TableCell>
              </TableRow>
            )
          }
        </TableFooter>
      </Table>
    </Box>
  )
}

const TagResponseTable = ({surveyTemplateId, responses}) => {
  const currentUser = useContext(CurrentUserContext);
  const {data: {listTagsByAdminGroup: {items: tags = []} = {}} = {}} = useQuery(Tag.queries.listByAdminGroup, {
    variables: {
      limit: 200,
      adminGroup: `${currentUser?.userOrganizationId}-Admins`,
    }
  });

  const {data: {listPromptsBySurveyTemplateId: {items: prompts = []} = {}} = {}} = useQuery(Prompt.queries.listBySurveyTemplateId, {
    variables: {
      limit: 200,
      promptSurveyTemplateId: surveyTemplateId
    }
  });

  return (
    <Box style={{overflow: 'auto'}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Tag</TableCell>
            <TableCell>Responses</TableCell>
            {
              prompts.map((prompt, i) => 
                <TableCell key={prompt.id}>
                  Q{i+1}
                </TableCell>
              )
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            tags.slice().sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map(tag => 
              <UserResponseRow 
                key={tag.id} 
                label={tag.name} 
                responses={responses.filter(response => !!response.survey.tagSurveys.items.find(ts => ts.tagId === tag.id))}
                prompts={prompts}
              />
            )
          }
        </TableBody>
        <TableFooter>
          {
            prompts.map((prompt, i) => 
              <TableRow key={prompt.id}>
                <TableCell colSpan={prompts.length + 2}>
                  Q{i+1}: {prompt.body}
                </TableCell>
              </TableRow>
            )
          }
        </TableFooter>
      </Table>
    </Box>
  )
}


const useAllResponses = ({adminGroup, surveyTemplateId, responsePromptId, startsAt, endsAt}) => {
  const [paginationToken, setPaginationToken] = useState(null);
  const [responses, setResponses] = useState([]);
  
  const { error, called, loading, data: { [!!surveyTemplateId ? "listResponsesByAdminGroupAndSurveyTemplateIdCreatedAt" : "listResponsesByAdminGroupAndPromptIdCreatedAt"]: {nextToken, items = []} = {}} = {}} = useQuery(Response.queries[!!surveyTemplateId ? "listByAdminGroupAndSurveyTemplateIdCreatedAt" : "listByAdminGroupAndPromptIdCreatedAt"], {
    skip: !surveyTemplateId && !responsePromptId,
    variables: {
      limit: 100,
      nextToken: paginationToken,
      adminGroup,
      [!!surveyTemplateId ? "responseSurveyTemplateIdCreatedAt" : "responsePromptIdCreatedAt"]: {
        between: [
          {
            [!!surveyTemplateId ? "responseSurveyTemplateId" : "responsePromptId"]: !!surveyTemplateId ? surveyTemplateId : responsePromptId,
            createdAt: startsAt
          },
          {
            [!!surveyTemplateId ? "responseSurveyTemplateId" : "responsePromptId"]: !!surveyTemplateId ? surveyTemplateId : responsePromptId,
            createdAt: endsAt
          },
        ]
      }
    }
  })

  !!error && console.log(error)

  useEffect(() => {
    setResponses(uniqBy(item => item.id, [
      ...responses,
      ...items
    ]))
  }, [JSON.stringify(items)])

  useEffect(() => {
    !!nextToken &&
    setPaginationToken(nextToken)
  }, [nextToken])

  return {responses, completed: !nextToken && !!called && !loading}
}

const DayLengthSelect = ({textFieldProps = {}, selectedNumberOfDaysBack, onChange}) => 
  <TextField
    label="Responses from the Last:"
    fullWidth
    {...textFieldProps}
    select
    value={selectedNumberOfDaysBack}
    onChange={onChange}
  >
    <MenuItem value={"1"}>
      1 Day
    </MenuItem>

    <MenuItem value={"7"}>
      7 Days
    </MenuItem>

    <MenuItem value={"30"}>
      30 Days
    </MenuItem>

    <MenuItem value={"90"}>
      90 Days
    </MenuItem>

    <MenuItem value={"180"}>
      6 Months
    </MenuItem>

    <MenuItem value={"360"}>
      12 Months
    </MenuItem>
  </TextField>

const ReportSelect = ({textFieldProps = {}, selectedReportType, onChange}) => 
  <TextField
    label="Select a Report"
    fullWidth
    {...textFieldProps}
    select
    value={selectedReportType}
    onChange={onChange}
  >
    <MenuItem value={"OVERVIEW"}>
      Overview Report
    </MenuItem>

    <MenuItem value={"STAFF"}>
      Staff Report
    </MenuItem>

    <MenuItem value={"IDENTIFIER"}>
      Tag Report
    </MenuItem>

    <MenuItem value={"TREND"}>
      Weekly Trend Report
    </MenuItem>
  </TextField>

const useQueryParms = () =>
  new URLSearchParams(useLocation().search)

const CampaignNavBar = ({selected, onChange, campaigns}) =>
  <AppBar position="static">
    <Tabs
      value={selected}
      onChange={onChange}
      variant="scrollable"
      scrollButtons="auto"
    >
      {
        campaigns.map(campaign =>
          <Tab key={campaign.id} value={campaign.id} label={campaign.name} />
        )
      }
    </Tabs>
  </AppBar>


export default () => {
  const history = useHistory();
  const currentUser = useContext(CurrentUserContext);
  const queryParams = useQueryParms();
  const queryCampaignId = queryParams.get('campaignId');
  const querySurveyTemplateId = queryParams.get('surveyTemplateId');
  const queryReportType = queryParams.get('reportType');
  const queryNumberOfDaysBack = queryParams.get('numberOfDaysBack')

  const [campaignId, setCampaignId] = useState(queryCampaignId);
  const [surveyTemplateId, setSurveyTemplateId] = useState(null);
  const [reportType, setReportType] = useState(null);
  const [numberOfDaysBack, setNumberOfDaysBack] = useState(null);

  const {responses, completed} = useAllResponses({
    adminGroup: `${currentUser?.userOrganizationId}-Admins`, 
    surveyTemplateId, 
    endsAt: moment().subtract({days: (numberOfDaysBack || 0) * 0}).endOf('day').toDate(),
    startsAt: moment().subtract({days: (numberOfDaysBack || 0) * 1}).endOf('day').toDate(),
  });

  const {data: {listPromptsBySurveyTemplateId: {items: prompts = []} = {}} = {}} = useQuery(Prompt.queries.listBySurveyTemplateId, {
    skip: !surveyTemplateId,
    pollInterval: 3000,
    variables: {
      limit: 200,
      promptSurveyTemplateId: surveyTemplateId
    }
  });

  const {loading: campaignsLoading, data: {listCampaignsByOrganizationId: {items: campaigns = []} = {}} = {}} = useQuery(Campaign.queries.listByOrganizationId, {
    skip: !currentUser?.userOrganizationId,
    pollInterval: 3000,
    variables: {
      campaignOrganizationId: currentUser?.userOrganizationId,
      filter: {
        inactive: {
          ne: true
        }
      }
    }
  });
  

  const selectedCampaign = (campaigns||[]).find(campaign => campaign.id === campaignId);

  const setUrl = ({campaignId, surveyTemplateId, reportType, numberOfDaysBack}) =>
    history.push(`/dashboard?campaignId=${campaignId||""}&surveyTemplateId=${surveyTemplateId||""}&reportType=${reportType||""}&numberOfDaysBack=${numberOfDaysBack||""}`)

  const handleCampaignChange = (event, campaignId) => 
    setUrl({campaignId, surveyTemplateId: "", reportType: "", numberOfDaysBack: ""})

  // useEffect(() => {
  //   !campaignId &&
  //   setUrl({campaignId: campaigns[0]?.id, surveyTemplateId, reportType, numberOfDaysBack})
  // }, [campaignId, JSON.stringify(campaigns)])

  // useEffect(() => {
  //   !!surveyTemplateId &&
  //   !reportType &&
  //   setUrl({campaignId, surveyTemplateId, reportType: "STAFF", numberOfDaysBack})
  // }, [surveyTemplateId, reportType])


  useEffect(() => {
    !!queryCampaignId && 
    setCampaignId(queryCampaignId)

    !!queryCampaignId &&
    setSurveyTemplateId(null)
    

    !queryCampaignId &&
    !!campaigns.length &&
    setUrl({campaignId: campaigns[0]?.id, surveyTemplateId, reportType, numberOfDaysBack})
  }, [queryCampaignId, JSON.stringify(campaigns)])

  useEffect(() => {
    setSurveyTemplateId(querySurveyTemplateId)
  }, [querySurveyTemplateId])

  useEffect(() => {
    setReportType(queryReportType || "OVERVIEW")
  }, [queryReportType])

  useEffect(() => {
    setNumberOfDaysBack(queryNumberOfDaysBack || "1")
  }, [queryNumberOfDaysBack])

  return (
    <>
      {
        !campaignsLoading &&
        !campaigns.length &&
        <Redirect to="/welcome" push={false} />
      }
      {
        !!campaignId && 
        <CampaignNavBar campaigns={campaigns} selected={campaignId} onChange={handleCampaignChange} />
      }
      {
        !!campaignId &&
        !!selectedCampaign &&
        <SurveyTemplateSelect campaignTemplateId={selectedCampaign.campaignCampaignTemplateId} onChange={surveyTemplateId => setUrl({campaignId, surveyTemplateId, reportType, numberOfDaysBack})} />
      }
      {
        !!surveyTemplateId &&
        <ReportSelect selectedReportType={reportType} onChange={({target: {value: reportType}}) => setUrl({campaignId, surveyTemplateId, reportType, numberOfDaysBack})} />
      }
      {
        !!surveyTemplateId &&
        ["OVERVIEW", "STAFF", "IDENTIFIER"].includes(reportType) &&
        <DayLengthSelect selectedNumberOfDaysBack={numberOfDaysBack} onChange={({target: {value: numberOfDaysBack}}) => setUrl({campaignId, surveyTemplateId, reportType, numberOfDaysBack})} />
      }
      {
        reportType === 'TREND' &&
        <>
          {
            prompts.map(prompt =>
              <WeeklyReport key={prompt.id} promptId={prompt.id} />
            )
          }
        </>
      }
      {
        reportType === 'IDENTIFIER' &&
        !!numberOfDaysBack &&
        <TagResponseTable responses={responses} surveyTemplateId={surveyTemplateId} />
      }
      {
        reportType === 'OVERVIEW' &&
        !!numberOfDaysBack &&
        prompts.map(prompt =>
          <Box mt={4} key={prompt.id} >
            <Box my={2}>
              <Divider />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={3} style={{minHeight: 280}}>
                <Box display={"flex"} flex={1} flexDirection={"column"} justify="flex-end">
                  <Box mb={4}>
                    <Typography>{prompt.body}</Typography>
                  </Box>
                  <BarChart numberOfDaysBack={numberOfDaysBack} promptId={prompt.id} />
                  <Scores numberOfDaysBack={numberOfDaysBack} promptId={prompt.id} />
                </Box>
              </Grid>
              <Grid item xs={12} md={8} lg={9} style={{minHeight: 280}}>
                <TrendChart numberOfDaysBack={numberOfDaysBack} promptId={prompt.id} />
              </Grid>
            </Grid>
          </Box>
        )
      }
      {
        reportType === 'STAFF' &&
        !!numberOfDaysBack &&
        <UserResponseTable responses={responses} surveyTemplateId={surveyTemplateId} />
      }
    </>
  )
}

