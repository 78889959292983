import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Redirect, Link } from "react-router-dom";

import { CurrentUserContext } from 'gunner-react'
import Video from 'Components/Video';

const SplashRoute = () => {
  const currentUser = useContext(CurrentUserContext);
  return !!currentUser ? (
    <Redirect to="/home" />
  ) : (
    <>
      <Typography paragraph>SimpliSurvey is a tool for interpreting real-time customer satisfaction and analysis.</Typography>
      <Typography paragraph>It works by polling customers with a simple algorithm and presenting findings on an easy-to-use dashboard.</Typography>
      <Typography paragraph>Whether you were invited to join an organization or want to create your own organization, please <Link to={`/home`}>follow this link</Link> to create your account.</Typography>
      <Typography paragraph>Please send feedback to <a href="mailto:info@simplisurvey.com">info@simplisurvey.com</a>.</Typography>
      <Grid container spacing={4} justify={'space-around'}>
        <Grid item xs={12} md={3}>
          <img alt="send" src={require("../../assets/images/send.png")} style={{maxWidth: "100%", height: "auto"}} />
        </Grid>
        <Grid item xs={12} md={3}>
          <img alt="respond" src={require("../../assets/images/respond.png")} style={{maxWidth: "100%", height: "auto"}} />
        </Grid>
        <Grid item xs={12} md={3}>
          <img alt="analyze" src={require("../../assets/images/analyze.png")} style={{maxWidth: "100%", height: "auto"}} />
        </Grid>
      </Grid>

      <Video url="https://www.youtube.com/embed/Silq1vQnYL4?rel=0&showinfo=0" />
    </>
  );
}

export default SplashRoute;