import React, { useState, useEffect, useContext } from 'react';
import { useMutation } from '@apollo/client';

import { Form } from 'gunner-react/web';
import Tag from 'react-shared/api/Tag';
import { withoutKeys } from 'gunner-react';
import { withoutBlanks } from 'gunner-react';
import writeFragment from 'Util/writeFragment';
import { CurrentUserContext } from 'gunner-react';

const fields = {
  name: {
    label: "Name",
    regex: /\w+/,
    required: true,
    type: 'text',
    errorMessage: "You are required enter a name"
  },
}

const TagFormContainer = ({organizationId, tag, onSuccess = () => null, onSubmitting = () => null, onError = () => null}) => {
  const [formData, setFormData] = useState(tag || {});
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const currentUser = useContext(CurrentUserContext)

  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: field === 'isExpired' ? !!value : value
    })

  const [_createTag] = useMutation(Tag.mutations.create, {
    variables: {input: { 
      ...withoutBlanks(formData),
      name: (formData.name || "").toLowerCase(),
      adminGroup: `${currentUser.userOrganizationId}-Admins`,
      memberGroup: `${currentUser.userOrganizationId}-Members`
    }},
    optimisticResponse: {
      __typename: "Mutation",
      createTag: { 
        ...formData,
        name: (formData.name || "").toLowerCase(),
        __typename: "Tag",
        adminGroup: `${currentUser.userOrganizationId}-Admins`,
        memberGroup: `${currentUser.userOrganizationId}-Members`
      }
    },
    refetchQueries: [
      {
        query: Tag.queries.listByAdminGroup,
        variables: {
          adminGroup: `${currentUser.userOrganizationId}-Admins`,
        },
      }
    ]
  });

  

  const [_updateTag] = useMutation(Tag.mutations.update, {
    variables: {input: {
      ...withoutBlanks(withoutKeys(formData, ['__typename'])),
      name: (formData.name || "").toLowerCase(),
    }},
    optimisticResponse: {
      __typename: "Mutation",
      updateTag: { 
        ...formData,
        name: (formData.name || "").toLowerCase(),
        __typename: "Tag"
      }
    },
    update: (proxy, {data: {updateTag}}) => writeFragment({client: proxy, data: updateTag, model: 'Tag'})
  });

  useEffect(() => {
    !!doSubmit && onSubmitting()

    !!doSubmit &&
    (!!formData.id ? _updateTag : _createTag)() //if this is an existing tag, update it, otherwise, create it
      .then(({data: {[(!!formData.id ? "updateTag" : "createTag")]: tag}}) => 
        setSubmitSuccess(true)
      )
      .catch(e => [
        console.log(e),
        setDoSubmit(false),
        setSubmitSuccess(false),
        window.alert(e.message),
        onError(e)
      ])
  }, [doSubmit]);

  useEffect(() => {
    !!submitSuccess &&
    onSuccess()
  }, [submitSuccess])

  return (
    <Form 
      primaryButtonProps={{color: "primary", fullWidth: true}}
      submitting={false}
      fields={fields}
      onChange={handleChange}
      data={formData}
      onSubmit={() => setDoSubmit(true)}
      buttonLabel={"Submit"}
    />
  )
}

export default TagFormContainer;