import gql from 'graphql-tag'
import Fragments from './Fragments'

const Option = {
  mutations: {
    update: gql`
      mutation UpdateOption($input: UpdateOptionInput!) {
        updateOption(input: $input) {
          ...OptionPublic
        }
      }
      ${Fragments.Option.public}
    `,
    create: gql`
      mutation CreateOption($input: CreateOptionInput!) {
        createOption(input: $input) {
          ...OptionPublic
        }
      }
      ${Fragments.Option.public}
    `
  },
  subscriptions: {
    onUpdate: gql`
      subscription OnUpdateOption($id: String) {
        onUpdateOption(id: $id) {
          ...OptionPublic
        }
      }
      ${Fragments.Option.public}
    `
  },
  queries: {
    listByPromptId: gql`
      query ListOptionsByPromptId(
        $optionPromptId: ID
        $position: ModelIntKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelOptionFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listOptionsByPromptId(
          optionPromptId: $optionPromptId
          position: $position
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...OptionPublic
          }
          nextToken
        }
      }
      ${Fragments.Option.public}
    `,
    get: gql`
      query GetOption($id: ID!) {
        getOption(id: $id) {
          ...OptionPublic
        }
      }
      ${Fragments.Option.public}
    `,
    list: gql`
      query ListOptions(
        $filter: ModelOptionFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            ...OptionPublic
          }
          nextToken
        }
      }
      ${Fragments.Option.public}
    `
  }
}

export default Option