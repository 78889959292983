import React from 'react';
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";

import HomeRoute from "./Home";
import TutorialRoute from './Tutorial';
import WelcomeRoute from './Welcome';

import InvitationListRoute from "./Admin/Invitation/List"
import SurveyDetailRoute from "./Survey/Detail"
import OrganizationEditRoute from "./Admin/Organization/Edit"
import UserListRoute from "./Admin/User/List"
import ContactGroupListRoute from "./Admin/ContactGroup/List"
import ContactListRoute from "./Admin/Contact/List"
import CampaignRoute from "./Admin/Campaign"
import CampaignTemplateRoute from "./Admin/CampaignTemplate"
import SurveyTemplateListRoute from "./Admin/SurveyTemplate/List"
import SurveyListRoute from "./Admin/Survey/List"

import SplashRoute from "./Splash";
import PrivacyPolicyRoute from "./PrivacyPolicy";
import SignOutRoute from "./SignOut";
import SignInRoute from "./SignIn";

import Layout from 'Components/Layout';
import { useTracker, PrivateRoute, LayoutProvider } from 'gunner-react/web';


import { Cache } from 'aws-amplify';
import AdminRoute from './AdminRoute';
import AdminPromptListRoute from './Admin/Prompt/List';
import AdminOptionListRoute from './Admin/Option/List';
import MemberRoute from './MemberRoute';
import MemberSurveySendRoute from './Member/Survey/Send/MemberSurveySendRoute';
import AdminResponseListRoute from './Admin/Response/List';
import AdminCommentListRoute from './Admin/Comment/List/AdminCommentListRoute';
import AdminTagListRoute from './Admin/Tag/List';
import AdminTagContactListRoute from './Admin/TagContact/List';
import AdminTagSurveyListRoute from './Admin/TagSurvey/List';
import AdminDashboardRoute from './Admin/Dashboard';


const useQuery = () =>
  new URLSearchParams(useLocation().search)

const InvititationCodeCapture = () => {
  const query = useQuery();
  const code = query.get("code");

  !!code && Cache.setItem('invitationCode', code)
  return null
}

const Router = () => 
  <BrowserRouter>
    <InvititationCodeCapture />
    <LayoutProvider 
      LayoutComponent={Layout}
      showNav={true}
    >
      <Switch>
        <MemberRoute path='/surveys/send' exact component={useTracker(MemberSurveySendRoute)} />
        
        <Route path='/surveys/:id' exact component={useTracker(SurveyDetailRoute)} />
        <Route path='/' exact component={useTracker(SplashRoute)} />
        <Route path='/tutorial' exact component={useTracker(TutorialRoute)} />
        <Route path='/welcome' exact component={useTracker(WelcomeRoute)} />
        <Route path='/sign-out' exact component={useTracker(SignOutRoute)} />
        <Route path='/privacy-policy' exact component={useTracker(PrivacyPolicyRoute)} />

        <PrivateRoute path='/sign-in' exact component={useTracker(SignInRoute)} />
        <PrivateRoute path='/home' exact component={useTracker(HomeRoute)} />

        <AdminRoute path='/dashboard' exact component={useTracker(AdminDashboardRoute)} />
        <AdminRoute path='/invitations' exact component={useTracker(InvitationListRoute)} />
        <AdminRoute path='/settings' exact component={useTracker(OrganizationEditRoute)} />
        <AdminRoute path='/users' exact component={useTracker(UserListRoute)} />
        <AdminRoute path='/contact-groups' exact component={useTracker(ContactGroupListRoute)} />
        <AdminRoute path='/contact-groups/:contactGroupId/contacts' exact component={useTracker(ContactListRoute)} />
        <AdminRoute path='/campaigns' exact component={useTracker(CampaignRoute)} />
        <AdminRoute path='/campaign-templates' exact component={useTracker(CampaignTemplateRoute)} />
        <AdminRoute path='/campaign-templates/:campaignTemplateId/survey-templates' exact component={useTracker(SurveyTemplateListRoute)} />
        <AdminRoute path='/survey-templates/:surveyTemplateId/prompts' exact component={useTracker(AdminPromptListRoute)} />
        <AdminRoute path='/prompts/:promptId/options' exact component={useTracker(AdminOptionListRoute)} />
        <AdminRoute path='/campaigns/:campaignId/surveys' exact component={useTracker(SurveyListRoute)} />
        <AdminRoute path='/contacts/:contactId/surveys' exact component={useTracker(SurveyListRoute)} />
        <AdminRoute path='/contact-groups/:contactGroupId/surveys' exact component={useTracker(SurveyListRoute)} />
        <AdminRoute path='/surveys/:surveyId/responses' exact component={useTracker(AdminResponseListRoute)} />
        <AdminRoute path='/comments' exact component={useTracker(AdminCommentListRoute)} />
        <AdminRoute path='/tags' exact component={useTracker(AdminTagListRoute)} />
        <AdminRoute path='/tags/:tagId/contacts' exact component={useTracker(AdminTagContactListRoute)} />
        <AdminRoute path='/tags/:tagId/surveys' exact component={useTracker(AdminTagSurveyListRoute)} />

        
      </Switch>
    </LayoutProvider>
  </BrowserRouter>

export default Router;