import React, { useState, useEffect } from 'react'
import { Button, MenuItem } from '@material-ui/core'
import { useMutation, useQuery } from '@apollo/client';
import ContactContactGroup from 'react-shared/api/ContactContactGroup';
import Modal from '../../../Modal';
import Contact from 'react-shared/api/Contact';

export default ({contactId, contactGroupId, render = "button"}) => {
  const [showModal, setShowModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const {error, data: {listContactContactGroupsByContactGroupIdAndContactId: {items = []} = {}} = {}} = useQuery(ContactContactGroup.queries.listByContactGroupIdAndContactId, {
    variables: {
      contactGroupId,
      contactId: {
        eq: contactId
      }
    }
  });

  const [deleteContactContactGroup] = useMutation(ContactContactGroup.mutations.delete, {
    variables: {
      input: {
        id: items[0]?.id
      }
    },
    refetchQueries: [
      {
        query: ContactContactGroup.queries.listByContactGroupId,
        variables: {
          contactGroupId,
          limit: 200
        }
      }
    ]
  });

  useEffect(() => {
    !!submitting &&
    deleteContactContactGroup()
      .then(() => [
        setShowModal(false),
        setSubmitting(false),
      ])
      .catch(err => [
        console.log(err),
        window.alert(JSON.stringify(err)),
        setShowModal(false),
        setSubmitting(false),
      ])
  }, [submitting])

  !!error && console.log(error)

  return (
    <>
      <Modal
        title="Remove Contact"
        body="Are you sure you want to remove the contact from this group?"
        onClose={() => setShowModal(false)}
        submitting={submitting}
        opened={!!showModal}
        saveButton={{
          text: "Yes",
          onClick: () => setSubmitting(true),
          ButtonProps: {}
        }}
      />
      {
        !!items[0] &&
        (
          render === "button" ? (
            <Button variant="contained" size="small" color="secondary" onClick={() => setShowModal(true)}>Remove</Button>
          ) : (
            <MenuItem onClick={() => setShowModal(true)}>Remove</MenuItem>
          )
        )
      }
    </>
  )
}