import gql from 'graphql-tag'
import Fragments from './Fragments'

const Tag = {
  mutations: {
    update: gql`
      mutation UpdateTag($input: UpdateTagInput!) {
        updateTag(input: $input) {
          ...TagPublic
        }
      }
      ${Fragments.Tag.public}
    `,
    create: gql`
      mutation CreateTag($input: CreateTagInput!) {
        createTag(input: $input) {
          ...TagPublic
        }
      }
      ${Fragments.Tag.public}
    `
  },
  subscriptions: {
    onUpdate: gql`
      subscription OnUpdateTag($id: String) {
        onUpdateTag(id: $id) {
          ...TagPublic
        }
      }
      ${Fragments.Tag.public}
    `
  },
  queries: {
    listByName: gql`
      query ListTagsByName(
        $name: String
        $updatedAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelTagFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listTagsByName(
          name: $name
          updatedAt: $updatedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...TagPublic
          }
          nextToken
        }
      }
      ${Fragments.Tag.public}
    `,
    listByAdminGroup: gql`
      query ListTagsByAdminGroup(
        $adminGroup: String
        $name: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelTagFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listTagsByAdminGroup(
          adminGroup: $adminGroup
          name: $name
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...TagPublic
          }
          nextToken
        }
      }
      ${Fragments.Tag.public}
    `,
    get: gql`
      query GetTag($id: ID!) {
        getTag(id: $id) {
          ...TagPublic
        }
      }
      ${Fragments.Tag.public}
    `,
    list: gql`
      query ListTags(
        $filter: ModelTagFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            ...TagPublic
          }
          nextToken
        }
      }
      ${Fragments.Tag.public}
    `
  }
}

export default Tag