import React, { useContext, useEffect } from 'react';

// import OrganziationEdit from 'Components/Organization/Edit';
import { Paper, } from '@material-ui/core';
import NavigationBreadcrumbsContainer from 'Components/Navigation/Breadcrumbs';
import SurveyList from 'Components/Survey/List';
import { AppBarContext } from 'gunner-react/web';


export default ({match: {params}}) => {
  const appBar = useContext(AppBarContext);

  useEffect(() => {
    appBar.setTitle("Sent Surveys")
    
    return appBar.reset;
  }, [])

  return (
    <>
      <NavigationBreadcrumbsContainer />
      <Paper square>
        <SurveyList campaignId={params.campaignId} contactId={params.contactId} contactGroupId={params.contactGroupId} />
      </Paper>
    </>
  )
}

