import React, { useState, useEffect, useContext } from 'react'
import { Button, Box, MenuItem } from '@material-ui/core';
import { useQuery, useMutation} from '@apollo/client';
import User from 'react-shared/api/User';
import { PasswordField } from 'gunner-react/web';
import Modal from '../../Modal';
import { CurrentUserContext } from 'gunner-react';


export default ({id, render = "button", label = "Password", buttonProps={size: "small", color: "secondary", variant: "contained"}}) => {
  const [showModal, setShowModal] = useState(false);
  const [doSave, setDoSave] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [password, setPassword] = useState("");
  const currentUser = useContext(CurrentUserContext);
  const {data: {getUser} = {}} = useQuery(User.queries.get, {variables: {id}});

  const [changePassword] = useMutation(User.mutations.changePassword, {
    variables: {
      input: {
        userId: id,
        password
      }
    }
  })
  
  useEffect(() => {
    !!doSave &&
    changePassword()
      .then(() => [setDoSave(false), setShowModal(false)])
      .catch(error => [setDoSave(false), console.log(error), window.alert(JSON.stringify(error))])
  }, [doSave])

  return (
    <>
      <Modal
        title="Password Reset"
        body={
          <Box component="form">
            <PasswordField
              value={password}
              onValueChange={value => setPassword(value)} 
              onValidChange={console.log} 
              showValidators={true}
              onValidChange={isValid => setIsValid(isValid)}
            />
          </Box>
        }
        onClose={() => setShowModal(false)}
        submitting={false}
        opened={!!showModal}
        saveButton={{
          text: !!doSave ? "Saving" : "Save",
          onClick: () => setDoSave(true),
          ButtonProps: {
            disabled: !!doSave || !isValid
          }
        }}
      />
      {
        render === 'button' ? (
          <Button {...buttonProps} onClick={() => setShowModal(true)}>{label}</Button>
        ) : (
          <MenuItem onClick={() => setShowModal(true)}>{label}</MenuItem>
        )
      }
      
    </>
  )
}