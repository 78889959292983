import gql from 'graphql-tag'
import Fragments from './Fragments'

const Survey = {
  mutations: {
    update: gql`
      mutation UpdateSurvey($input: UpdateSurveyInput!) {
        updateSurvey(input: $input) {
          ...SurveyPublic
        }
      }
      ${Fragments.Survey.public}
    `,
    create: gql`
      mutation CreateSurvey($input: CreateSurveyInput!) {
        createSurvey(input: $input) {
          ...SurveyPublic
        }
      }
      ${Fragments.Survey.public}
    `,
    delete: gql`
      mutation DeleteSurvey($input: DeleteSurveyInput!) {
        deleteSurvey(input: $input) {
          ...SurveyPublic
        }
      }
      ${Fragments.Survey.public}
    `
  },
  subscriptions: {
    onUpdate: gql`
      subscription OnUpdateSurvey($id: String) {
        onUpdateSurvey(id: $id) {
          ...SurveyPublic
        }
      }
      ${Fragments.Survey.public}
    `
  },
  queries: {
    listByContactGroupId: gql`
      query ListSurveysByContactGroupId(
        $surveyContactGroupId: ID
        $updatedAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelSurveyFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listSurveysByContactGroupId(
          surveyContactGroupId: $surveyContactGroupId
          updatedAt: $updatedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...SurveyPublic
          }
          nextToken
        }
      }
      ${Fragments.Survey.public}
    `,
    listByCampaignId: gql`
      query ListSurveysByCampaignId(
        $surveyCampaignId: ID
        $updatedAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelSurveyFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listSurveysByCampaignId(
          surveyCampaignId: $surveyCampaignId
          updatedAt: $updatedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...SurveyPublic
          }
          nextToken
        }
      }
      ${Fragments.Survey.public}
    `,
    listByContactId: gql`
      query ListSurveysByContactId(
        $surveyContactId: ID
        $updatedAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelSurveyFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listSurveysByContactId(
          surveyContactId: $surveyContactId
          updatedAt: $updatedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...SurveyPublic
          }
          nextToken
        }
      }
      ${Fragments.Survey.public}
    `,
    listByAdminGroupAndSendAt: gql`
      query ListSurveysByAdminGroupAndSendAt(
        $adminGroup: String
        $sendAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelSurveyFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listSurveysByAdminGroupAndSendAt(
          adminGroup: $adminGroup
          sendAt: $sendAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...SurveyPublic
          }
          nextToken
        }
      }
      ${Fragments.Survey.public}
    `,
    get: gql`
      query GetSurvey($id: ID!) {
        getSurvey(id: $id) {
          ...SurveyPublic
        }
      }
      ${Fragments.Survey.public}
    `,
    list: gql`
      query ListSurveys(
        $filter: ModelSurveyFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listSurveys(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            ...SurveyPublic
          }
          nextToken
        }
      }
      ${Fragments.Survey.public}
    `
  }
}

export default Survey