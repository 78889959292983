import gql from 'graphql-tag'
import Fragments from './Fragments'

const SurveyTemplate = {
  mutations: {
    update: gql`
      mutation UpdateSurveyTemplate($input: UpdateSurveyTemplateInput!) {
        updateSurveyTemplate(input: $input) {
          ...SurveyTemplatePublic
        }
      }
      ${Fragments.SurveyTemplate.public}
    `,
    create: gql`
      mutation CreateSurveyTemplate($input: CreateSurveyTemplateInput!) {
        createSurveyTemplate(input: $input) {
          ...SurveyTemplatePublic
        }
      }
      ${Fragments.SurveyTemplate.public}
    `
  },
  subscriptions: {
    onUpdate: gql`
      subscription OnUpdateSurveyTemplate($id: String) {
        onUpdateSurveyTemplate(id: $id) {
          ...SurveyTemplatePublic
        }
      }
      ${Fragments.SurveyTemplate.public}
    `
  },
  queries: {
    listByCampaignTemplateId: gql`
      query ListSurveyTemplatesByCampaignTemplateId(
        $surveyTemplateCampaignTemplateId: ID
        $position: ModelIntKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelSurveyTemplateFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listSurveyTemplatesByCampaignTemplateId(
          surveyTemplateCampaignTemplateId: $surveyTemplateCampaignTemplateId
          position: $position
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...SurveyTemplatePublic
          }
          nextToken
        }
      }
      ${Fragments.SurveyTemplate.public}
    `,
    get: gql`
      query GetSurveyTemplate($id: ID!) {
        getSurveyTemplate(id: $id) {
          ...SurveyTemplatePublic
        }
      }
      ${Fragments.SurveyTemplate.public}
    `,
    list: gql`
      query ListSurveyTemplates(
        $filter: ModelSurveyTemplateFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listSurveyTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            ...SurveyTemplatePublic
          }
          nextToken
        }
      }
      ${Fragments.SurveyTemplate.public}
    `
  }
}

export default SurveyTemplate