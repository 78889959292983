import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useQuery, useMutation } from '@apollo/client';
import { Cache } from 'aws-amplify';
// import { Redirect } from "react-router-dom";

import { CurrentUserContext } from 'gunner-react'

import User from 'react-shared/api/User';
import Invitation from 'react-shared/api/Invitation';
// import Organization from 'react-shared/api/Organization';

import Modal from 'Components/Modal';
import OrganizationNew from 'Components/Organization/New';
import { TextField, Button, Grid, CircularProgress } from '@material-ui/core';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  typography: {
    
  }
}));


const HomeRoute = ({history}) => {
  const classes = useStyles();
  const currentUser = useContext(CurrentUserContext);
  const [invitationCode, setInvitationCode] = useState(Cache.getItem('invitationCode'));
  const [showOrganizationModal, setShowOrganizationModal] = useState(false);

  const {error: invitationsError, loading: loadingInvitations, data: {listInvitationsByCode: {items = []} = {}} = {}} = useQuery(Invitation.queries.listByCode, {variables: {
    code: invitationCode || "<>",
    filter: {
      accepted: {
        ne: true
      }
    }
  }});
  const invitation = items[0];

  // const { data: { onUpdateUser } = {} } = useSubscription(User.subscriptions.onUpdate, { variables: { 
  //   id: currentUser?.id || "<>"
  // }});

  const [acceptInvitation, {called: acceptCalled, loading: acceptingInvitation, error: acceptInvitationError, data: {acceptInvitation: acceptedUser} = {}}] = useMutation(User.mutations.acceptInvitation, {
    variables: {input: {
      userId: currentUser?.id || "<>",
      invitationId: invitation?.id || "<>"
    }}
  });

  useEffect(() => {
    !!acceptCalled &&
    Cache.removeItem("invitationCode")
  }, [acceptCalled])

  useEffect(() => {
    !!currentUser?.userOrganizationId &&
    history.push("/welcome")
  }, [history, currentUser?.userOrganizationId])

  !!invitationsError && console.log(invitationsError);

  return (
      <>
        <Modal
          title="Create a New Organization"
          body={
            <OrganizationNew />
          }
          onClose={() => setShowOrganizationModal(false)}
          submitting={false}
          opened={!!showOrganizationModal}
          saveButton={false}
        />
        {
          !!currentUser?.isOrganizationAdmin ? (
            <Redirect to="/dashboard" />
          ) : !!currentUser?.isOrganizationMember ? (
            <Redirect to="/surveys/send" />
          ) : !!acceptCalled ? (
            <CircularProgress color="secondary" />
          ) : (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {
                  !!invitation ? (
                    <>
                      <Typography paragraph>Found invitation from {invitation.user.name} to join {invitation.user.organization.name}</Typography>
                      <Button onClick={acceptInvitation} variant="contained" color="secondary">Accept</Button>
                    </>
                  ) : (
                    <>
                      <Typography paragraph>If you received a invitation code via email or text message from someone within an organization, enter that code below.</Typography>
                      <Typography paragraph>Please note: The invitation code is not the same as the confirmation code you received when signing up for your account.</Typography>
                      <TextField
                        value={invitationCode}
                        onChange={({target: {value}}) => setInvitationCode(value)}
                        error={!!invitationCode && !loadingInvitations}
                        helperText={!!loadingInvitations ? "Checking...." : !invitationCode ? "Enter the invitation code sent to you via email or text message" : "Invalid Code"}
                        variant="outlined"
                      />
                    </>
                  )
                }
              </Grid>
              <Grid item xs={12}>
                <Typography paragraph>If you'd like to create a brand new account for your organization, click the button below.</Typography>
                <Button variant="contained" color="secondary" onClick={() => setShowOrganizationModal(true)}>Create Organization</Button>
              </Grid>
            </Grid>
          )
        }
      </>  
  )
}


export default HomeRoute; 
