import React, { useState, useContext, useEffect } from 'react';

// import OrganziationEdit from 'Components/Organization/Edit';
import { CurrentUserContext } from 'gunner-react'
import UserList from 'Components/User/List';
import { Tabs, Paper, Tab, Button } from '@material-ui/core';
import UserNew from 'Components/User/New';
import Modal from 'Components/Modal';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HelpIcon from '@material-ui/icons/Help';
import { Typography } from '@material-ui/core';
import { AppBarContext } from 'gunner-react/web';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { IconButton } from '@material-ui/core';
import { useHasVisitedScreen } from 'gunner-react/web';

const AdminUserListRoute = ({match: {params}}) => {
  const currentUser = useContext(CurrentUserContext);
  const [value, setValue] = useState("Active");
  const [showModal, setShowModal] = useState(false);
  const [doRefetch, setDoRefetch] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(!useHasVisitedScreen());

  const appBar = useContext(AppBarContext);

  const newUserData = {
    inactive: false, 
    userOrganizationId: currentUser.userOrganizationId,
    memberGroup: `${currentUser.userOrganizationId}-Members`,
    adminGroup: `${currentUser.userOrganizationId}-Admins`,
    admin: false
  }
  const filter = {
    inactive: {
      [value === 'Active' ? 'ne' : 'eq']: true
    }
  }

  useEffect(() => {
    appBar.setTitle("Users");
    appBar.setOnClickHelp(() => () => setShowHelpModal(true))
    appBar.setRightButtons([
      <IconButton
        aria-label="add"
        aria-haspopup="true"
        onClick={() => [setShowModal(true), setDoRefetch(false)]}
        color="inherit"
      >
        <AddCircleOutlineIcon />
      </IconButton>
    ])
    return appBar.reset;
  }, [])

  return (
    <>
      {/* <Button variant="contained" color="secondary" onClick={() => [setShowModal(true), setDoRefetch(false)]}>Add User</Button> */}
      <Paper square>
        <Tabs
          variant="fullWidth"
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={(event, newValue) => setValue(newValue)}
          aria-label="disabled tabs example"
        >
          <Tab label="Active" value="Active" />
          <Tab label="Inactive" value="Inactive" />
        </Tabs>
        <UserList doRefetch={doRefetch} filter={filter} organizationId={currentUser.userOrganizationId} />
      </Paper>

      <Modal
        title="Create Member"
        body={
          <UserNew 
            user={newUserData} 
            onSubmitting={() => setSubmitting(true)} 
            onSuccess={response => [setDoRefetch(true), setShowModal(false), setSubmitting(false)]} 
            onError={err => [console.log(err), window.alert(JSON.stringify(err)), setSubmitting(false)]} 
          />
        }
        onClose={() => setShowModal(false)}
        submitting={submitting}
        opened={!!showModal}
        saveButton={false}
      />

      <Modal
        title="Help"
        body={
          <>
            <Typography paragraph>The preferred method for adding users to your organization is to invite them to join so they can set up their own account.</Typography>
            <Typography paragraph>However, if someone is having problems joining or can't figure out how to reset their password, you may do so here.</Typography>
          </>
        }
        onClose={() => setShowHelpModal(false)}
        submitting={false}
        opened={!!showHelpModal}
        saveButton={false}
      />

      {/* <SpeedDial
        ariaLabel="SpeedDial"
        icon={<SpeedDialIcon />}
        onClose={() => setSpeedDialOpen(false)}
        onOpen={() => setSpeedDialOpen(true)}
        open={speedDialOpen}
        direction={'up'}
      >
        <SpeedDialAction
          icon={<HelpIcon />}
          tooltipTitle={"Help"}
          onClick={() => setShowHelpModal(true)}
          tooltipOpen
        />
        <SpeedDialAction
          icon={<AddCircleIcon />}
          tooltipTitle={"Add User"}
          onClick={() => [setShowModal(true), setDoRefetch(false)]}
          tooltipOpen
        />
      </SpeedDial> */}
    </>
  )
}


export default AdminUserListRoute; 
