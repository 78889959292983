import React, { useState, useContext, useEffect } from 'react';

// import OrganziationEdit from 'Components/Organization/Edit';
import ContactList from 'Components/Contact/List';
import { Paper} from '@material-ui/core';
import Modal from 'Components/Modal';
import ContactNew from 'Components/Contact/New';
import { useQuery } from '@apollo/client';
import ContactGroup from "react-shared/api/ContactGroup"
import NavigationBreadcrumbsContainer from 'Components/Navigation/Breadcrumbs';
import ContactUploader from 'Components/Contact/Uploader';
import { Typography } from '@material-ui/core';
import { AppBarContext } from 'gunner-react/web';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { IconButton } from '@material-ui/core';
import { useHasVisitedScreen } from 'gunner-react/web';
import PublishIcon from '@material-ui/icons/Publish';

const AdminContactListRoute = ({match: {params}}) => {
  const [showModal, setShowModal] = useState(false);
  const { data: {getContactGroup = {}} = {}} = useQuery(ContactGroup.queries.get, {variables: { id: params.contactGroupId }})
  // const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [doUpload, setDoUpload] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(!useHasVisitedScreen());
  const [showUploadModal, setShowUploadModal] = useState(false);
  const appBar = useContext(AppBarContext);

  useEffect(() => {
    appBar.setTitle(`Contacts`)

    appBar.setOnClickHelp(() => () => setShowHelpModal(true))
    appBar.setRightButtons([
      <IconButton
        aria-label="add"
        aria-haspopup="true"
        onClick={() => setShowModal(true)}
        color="inherit"
      >
        <AddCircleOutlineIcon />
      </IconButton>
      ,
      <IconButton
        aria-label="upload"
        aria-haspopup="true"
        onClick={() => setShowUploadModal(true)}
        color="inherit"
      >
        <PublishIcon />
      </IconButton>
    ])

    return appBar.reset;
  }, []);

  return (
    <>
      <NavigationBreadcrumbsContainer />
      
      {/* <Button variant="contained" color="secondary" onClick={() => setShowModal(true)}>Add Contact</Button> */}
      <Paper square>
        <ContactList contactGroupId={params.contactGroupId} doRefetch={!showModal} />
      </Paper>

      <Modal
        title="Upload Contacts"
        body={
          <ContactUploader contactGroupId={params.contactGroupId} onError={err => [window.alert(err), setDoUpload(false)]} doSubmit={doUpload} onSubmitSuccess={() => [setDoUpload(false), setShowUploadModal(false)]} />
        }
        onClose={() => setShowUploadModal(false)}
        submitting={doUpload}
        opened={!!showUploadModal}
        saveButton={{
          text: !!doUpload ? "Uploading" : "Upload",
          onClick: () => setDoUpload(true),
          ButtonProps: {
            disabled: !!doUpload
          }
        }}
      />

      <Modal
        title="Create Contact"
        body={
          <ContactNew organizationId={getContactGroup.contactGroupOrganizationId} contactGroupId={params.contactGroupId} onSuccess={() => setShowModal(false)} />
        }
        onClose={() => setShowModal(false)}
        submitting={false}
        opened={!!showModal}
        saveButton={false}
      />


      <Modal
        title="Help"
        body={
          <>
            <Typography paragraph>The preferred method for adding users to your organization is to invite them to join so they can set up their own account.</Typography>
            <Typography paragraph>However, if someone is having problems joining or can't figure out how to reset their password, you may do so here.</Typography>
          </>
        }
        onClose={() => setShowHelpModal(false)}
        submitting={false}
        opened={!!showHelpModal}
        saveButton={false}
      />

      {/* <SpeedDial
        ariaLabel="SpeedDial"
        icon={<SpeedDialIcon />}
        onClose={() => setSpeedDialOpen(false)}
        onOpen={() => setSpeedDialOpen(true)}
        open={speedDialOpen}
        direction={'up'}
      >
        <SpeedDialAction
          icon={<HelpIcon />}
          tooltipTitle={"Help"}
          onClick={() => setShowHelpModal(true)}
          tooltipOpen
        />
        <SpeedDialAction
          icon={<AddCircleIcon />}
          tooltipTitle={"Add Contact"}
          onClick={() => setShowModal(true)}
          tooltipOpen
        />
      </SpeedDial> */}
    </>
  )
}


export default AdminContactListRoute; 
