import React, { useState, useEffect } from 'react'
import SurveyDetailView from './SurveyDetailView'
import { useQuery, useMutation } from '@apollo/client'
import Survey from 'react-shared/api/Survey'
import Prompt from 'react-shared/api/Prompt'
import { CircularProgress, Typography } from '@material-ui/core'
import Response from 'react-shared/api/Response'

export default ({id}) => {
  const [isValid, setIsValid] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const { refetch: refetchSurvey, loading, error, data: {getSurvey} = {}} = useQuery(Survey.queries.get, {variables: {id}});
  const { refetch: refetchPrompts, data: {listPromptsBySurveyTemplateId: {items: prompts = []} = {}} = {}} = useQuery(Prompt.queries.listBySurveyTemplateId, {
    skip: !getSurvey?.surveySurveyTemplateId,
    variables: {
      promptSurveyTemplateId: getSurvey?.surveySurveyTemplateId,
      limit: 100
    }
  });
  const [updateSurvey] = useMutation(Survey.mutations.update, {
    variables: {
      input: {
        id: getSurvey?.id,
        submitted: true
      }
    },
    optimisticResponse: {
      __typename: "Mutation",
      updateSurvey: { 
        ...(getSurvey || {}),
        __typename: "Survey",
      }
    }
  })

  const selectedResponse = (getSurvey?.responses?.items || []).find(response => response.responsePromptId === selectedOption?.optionPromptId)

  const mutationVariables = {
    ...{
      responseOptionId: selectedOption?.id,
      responseSurveyId: getSurvey?.id,
      responsePromptId: selectedOption?.optionPromptId,
      responseSurveyTemplateId: getSurvey?.surveySurveyTemplateId,
      adminGroup: getSurvey?.adminGroup,
      memberGroup: getSurvey?.memberGroup,
      responseReviewerId: "<!>",
      createdAt: (new Date()).toISOString()
    },
    ...(
      !!selectedResponse?.id ? {id: selectedResponse?.id} : {}
    )
  }
  
  const [createResponse] = useMutation(Response.mutations.create, {
    variables: {
      input: mutationVariables
    },
    optimisticResponse: {
      __typename: "Mutation",
      createResponse: { 
        ...mutationVariables,
        __typename: "Response",
      }
    }
  })

  const [updateResponse] = useMutation(Response.mutations.update, {
    variables: {
      input: mutationVariables
    },
    optimisticResponse: {
      __typename: "Mutation",
      updateResponse: { 
        ...mutationVariables,
        __typename: "Response",
      }
    }
  })

  const mutation = !!selectedResponse?.id ? updateResponse : createResponse;

  useEffect(() => {
    !!submitting &&
    updateSurvey()
      .then(() => [
        setSubmitting(false),
        refetchSurvey(),
        refetchPrompts()
      ])
      .catch(e => [
        console.log(e),
        setSubmitting(false),
        window.alert(e.message),
      ])
  }, [submitting])

  useEffect(() => {
    !!selectedOption &&
    mutation()
      .then(() => [
        setSelectedOption(null),
        refetchPrompts(),
        refetchSurvey(),
      ])
      .catch(e => [
        console.log(e),
        setSelectedOption(null),
        window.alert(e.message),
      ])
  }, [!!selectedOption])


  useEffect(() => {
    setIsValid(
      prompts.length &&
      getSurvey?.responses?.items?.length &&
      prompts.length === getSurvey?.responses?.items?.length
    )
  }, [prompts.length, getSurvey?.responses?.items?.length])

  !!error && console.log(error)

  return (
    !!loading ? (
      <CircularProgress />
    ) : !!error ? (
      <Typography color="error" paragraph>Whoops! Something went wrong. Please try again later.</Typography>
    ) : !!getSurvey ? (
      <SurveyDetailView submitting={submitting} onSubmit={() => setSubmitting(true)} onOptionSelect={option => setSelectedOption(option)} prompts={prompts} isValid={isValid} survey={getSurvey} showThankYouMessage={!!getSurvey.submitted} />
    ) : (
      null
    )
  )
}