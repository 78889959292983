import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  video: {
    overflow: "hidden",
    "padding-bottom": "56.25%",
    "padding-top": "25px",
    position: "relative",
    height: 0,
    '& iframe': {
      left: 0,
      top: 0,
      height: "100%",
      width: "100%",
      position: "absolute"
    }
  }
}));