import React, { useState, useEffect } from 'react'
import SurveyTemplateSelectView from './SurveyTemplateSelectView'
import { useQuery } from '@apollo/client'
import SurveyTemplate from 'react-shared/api/SurveyTemplate'

export default ({campaignTemplateId, onChange}) => {
  const [selectedSurveyTemplateId, setSelectedSurveyTemplateId] = useState(null);

  const {data: {listSurveyTemplatesByCampaignTemplateId: {items = []} = {}} = {}} = useQuery(SurveyTemplate.queries.listByCampaignTemplateId, {
    pollInterval: 5000,
    variables: {
      surveyTemplateCampaignTemplateId: campaignTemplateId,
      limit: 100
    }
  });

  const handleChange = ({target: {value}}) =>
    setSelectedSurveyTemplateId(value)

  useEffect(() => {
    items.length === 1 &&
    setSelectedSurveyTemplateId(items[0].id)
  }, [JSON.stringify(items)])

  useEffect(() => {
    onChange(selectedSurveyTemplateId)
  }, [selectedSurveyTemplateId])


  return !items.length ? null : (
    <SurveyTemplateSelectView 
      surveyTemplates={items} 
      selectedSurveyTemplateId={selectedSurveyTemplateId} 
      handleChange={handleChange}
    />
  )
}