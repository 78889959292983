import React, { useState, useEffect, useContext } from 'react';
import { useMutation } from '@apollo/client';


import { Form } from 'gunner-react/web';
import Response from 'react-shared/api/Response';
import { withoutKeys } from 'gunner-react';
import { withoutBlanks } from 'gunner-react';
import { CurrentUserContext } from 'gunner-react';
import writeFragment from 'Util/writeFragment';


const ResponseFormContainer = ({type, organizationId, response, onSuccess = () => null, onSubmitting = () => null, onError = () => null}) => {
  const [formData, setFormData] = useState(response || {});
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const currentUser = useContext(CurrentUserContext);

  const fields = type !== 'review' ? {
    reason: {
      label: "Comment",
      regex: /\w+/,
      required: true,
      type: 'text',
      errorMessage: "You are required enter text",
      helperText: "Please explain why you chose the option you did",
      rows: 4
    },
  } : {
    reviewComment: {
      label: "Note",
      regex: /\w+/,
      required: true,
      type: 'text',
      errorMessage: "You are required enter text",
      helperText: "Enter notes related to the selected comment",
      rows: 4
    },
    reviewedAt: {
      label: "Reviewed At",
      regex: /\w+/,
      required: false,
      type: 'hidden',
      errorMessage: "You are required enter text",
      helperText: "Enter notes related to the selected comment",
      value: value => value ?? (new Date()).toISOString()
    },
  };

  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: field === 'isExpired' ? !!value : value
    })

  const [_createResponse] = useMutation(Response.mutations.create, {
    variables: {input: { 
      ...withoutBlanks(formData),
      adminGroup: `${organizationId}-Admins`,
      memberGroup: `${organizationId}-Members`,
      responseReviewerId: type !== 'review' ? "<!>" : currentUser?.id ?? "<!>",
      reviewedAt: type !== 'review' ? undefined : (new Date()).toISOString()
    }},
    optimisticResponse: {
      __typename: "Mutation",
      createResponse: { 
        ...formData,
        __typename: "Response",
        adminGroup: `${organizationId}-Admins`,
        memberGroup: `${organizationId}-Members`,
        responseReviewerId: type !== 'review' ? "<!>" : currentUser?.id ?? "<!>",
        reviewedAt: type !== 'review' ? undefined : (new Date()).toISOString()
      }
    },
    refetchQueries: [
      {
        query: Response.queries.listBySurveyId,
        variables: {
          responseSurveyId: formData?.responseSurveyId
        },
      }
    ]
  });

  

  const [_updateResponse] = useMutation(Response.mutations.update, {
    variables: {input: {
      ...withoutBlanks(withoutKeys(formData, ['__typename', 'survey', 'option', 'prompt', 'reviewer'])),
      reviewedAt: type !== 'review' ? (formData.reviewedAt ?? undefined) : (new Date()).toISOString()
    }},
    optimisticResponse: {
      __typename: "Mutation",
      updateResponse: { 
        ...formData,
        reviewedAt: type !== 'review' ? (formData.reviewedAt ?? undefined) : (new Date()).toISOString(),
        __typename: "Response"
      }
    },
    update: (proxy, {data: {updateResponse}}) => writeFragment({client: proxy, data: updateResponse, model: 'Response'})
  });

  useEffect(() => {
    !!doSubmit && onSubmitting()
    
    !!doSubmit &&
    (!!formData.id ? _updateResponse : _createResponse)() //if this is an existing response, update it, otherwise, create it
      .then(({data: {[(!!formData.id ? "updateResponse" : "createResponse")]: response}}) => 
        setSubmitSuccess(true)
      )
      .catch(e => [
        console.log(e),
        setDoSubmit(false),
        setSubmitSuccess(false),
        window.alert(e.message),
        onError(e)
      ])
  }, [doSubmit]);

  useEffect(() => {
    !!submitSuccess &&
    onSuccess()
  }, [submitSuccess]);


  return (
    <Form 
      primaryButtonProps={{color: "primary", fullWidth: true}}
      submitting={false}
      fields={fields}
      onChange={handleChange}
      data={formData}
      onSubmit={() => setDoSubmit(true)}
      buttonLabel={"Submit"}
    />
  )
}

export default ResponseFormContainer;