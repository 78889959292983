import gql from 'graphql-tag'
import Fragments from './Fragments'

const Contact = {
  mutations: {
    update: gql`
      mutation UpdateContact($input: UpdateContactInput!) {
        updateContact(input: $input) {
          ...ContactPublic
        }
      }
      ${Fragments.Contact.public}
    `,
    create: gql`
      mutation CreateContact($input: CreateContactInput!) {
        createContact(input: $input) {
          ...ContactPublic
        }
      }
      ${Fragments.Contact.public}
    `
  },
  subscriptions: {
    onUpdate: gql`
      subscription OnUpdateContact($id: String) {
        onUpdateContact(id: $id) {
          ...ContactPublic
        }
      }
      ${Fragments.Contact.public}
    `
  },
  queries: {
    listByAdminGroup: gql`
      query ListContactsByAdminGroup(
        $adminGroup: String
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelContactFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listContactsByAdminGroup(
          adminGroup: $adminGroup
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...ContactPublic
          }
          nextToken
        }
      }
      ${Fragments.Contact.public}
    `,
    get: gql`
      query GetContact($id: ID!) {
        getContact(id: $id) {
          ...ContactPublic
        }
      }
      ${Fragments.Contact.public}
    `,
    list: gql`
      query ListContacts(
        $filter: ModelContactFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            ...ContactPublic
          }
          nextToken
        }
      }
      ${Fragments.Contact.public}
    `
  }
}

export default Contact