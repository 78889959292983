import React from "react";
import { Link } from "react-router-dom";

import { Button, Divider, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DescriptionIcon from '@material-ui/icons/Description';
import EmailIcon from '@material-ui/icons/Email';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import LabelIcon from '@material-ui/icons/Label';
import MenuIcon from '@material-ui/icons/Menu';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import SendIcon from '@material-ui/icons/Send';

import HelpIcon from '@material-ui/icons/Help';
import AccountGroupIcon from 'mdi-material-ui/AccountGroup';
import AccountKeyIcon from 'mdi-material-ui/AccountKey';
import CommentMultipleIcon from 'mdi-material-ui/CommentMultiple';
import SettingsIcon from 'mdi-material-ui/light/Settings';


import OrganziationName from "../Organization/Name";

const navStyle = 'vertical'; //vertical or horizontal;

const DrawerContents = ({classes, handleDrawerToggle, currentUser, unseenCommentsCount}) =>
  <div>
    <div className={classes.toolbar} />
    { 
      !!currentUser?.organization &&
      <>
        <List subheader={
          <ListSubheader>
            <OrganziationName id={currentUser.userOrganizationId} />
          </ListSubheader>
        }>
          {
            !!currentUser?.isOrganizationAdmin &&
            <>
              {
                currentUser.organization?.planType === "FULL" && 
                <ListItem 
                  component={Link} 
                  to={`/dashboard`} 
                  button  
                  onClick={handleDrawerToggle}
                >
                  <ListItemIcon>
                    <AssessmentIcon />
                  </ListItemIcon>
                  <ListItemText><Typography  className={classes.link}>Dashboard</Typography></ListItemText>
                </ListItem>
              }
              <ListItem 
                component={Link} 
                to={`/comments`} 
                button  
                onClick={handleDrawerToggle}
              >
                <ListItemIcon>
                  <CommentMultipleIcon />
                </ListItemIcon>
                <ListItemText><Typography className={classes.link}>Comments ({unseenCommentsCount})</Typography></ListItemText>
              </ListItem>
              <ListItem 
                component={Link} 
                to={`/campaigns`} 
                button  
                onClick={handleDrawerToggle}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText><Typography  className={classes.link}>View Responses</Typography></ListItemText>
              </ListItem>
              <ListItem 
                component={Link} 
                to={`/campaign-templates`} 
                button  
                onClick={handleDrawerToggle}
              >
                <ListItemIcon>
                  <NoteAddIcon />
                </ListItemIcon>
                <ListItemText><Typography  className={classes.link}>Manage Campaigns</Typography></ListItemText>
              </ListItem>

              {
                currentUser.organization?.planType === "FULL" && 
                <ListItem 
                  component={Link} 
                  to={`/users`} 
                  button  
                  onClick={handleDrawerToggle}
                >
                  <ListItemIcon>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ListItemText><Typography  className={classes.link}>Members</Typography></ListItemText>
                </ListItem>
              }
              {
                currentUser.organization?.planType === "FULL" &&
                <ListItem 
                  component={Link} 
                  to={`/contact-groups`} 
                  button  
                  onClick={handleDrawerToggle}
                >
                  <ListItemIcon>
                    <AccountGroupIcon />
                  </ListItemIcon>
                  <ListItemText><Typography  className={classes.link}>Contacts</Typography></ListItemText>
                </ListItem>
              }
              <ListItem 
                component={Link} 
                to={`/surveys/send`} 
                button  
                onClick={handleDrawerToggle}
              >
                <ListItemIcon>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText><Typography  className={classes.link}>Send Survey</Typography></ListItemText>
              </ListItem>

              {
                currentUser.organization?.planType === "FULL" &&
                <ListItem 
                  component={Link} 
                  to={`/invitations`} 
                  button  
                  onClick={handleDrawerToggle}
                >
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  <ListItemText><Typography  className={classes.link}>Invitations</Typography></ListItemText>
                </ListItem>
              }

              {
                currentUser.organization?.planType === "FULL" &&
                <ListItem 
                  component={Link} 
                  to={`/tags`} 
                  button  
                  onClick={handleDrawerToggle}
                >
                  <ListItemIcon>
                    <LabelIcon />
                  </ListItemIcon>
                  <ListItemText><Typography  className={classes.link}>Tags</Typography></ListItemText>
                </ListItem>
              }
              
              <ListItem 
                component={Link} 
                to={`/settings`} 
                button  
                onClick={handleDrawerToggle}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText><Typography className={classes.link}>Settings</Typography></ListItemText>
              </ListItem>
            </>
          }
          {
            !!currentUser?.isOrganizationMember &&
            !currentUser?.isOrganizationAdmin &&
            <>
              <ListItem 
                component={Link} 
                to={`/surveys/send`} 
                button  
                onClick={handleDrawerToggle}
              >
                <ListItemIcon>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText><Typography  className={classes.link}>Send Survey</Typography></ListItemText>
              </ListItem>
            </>
          }
        </List>
        <Divider />
      </>
    }
    <List>
      {
        !currentUser &&
        <ListItem 
          component={Link} 
          to={`/`} 
          button  
          onClick={handleDrawerToggle}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText><Typography  className={classes.link}>Home</Typography></ListItemText>
        </ListItem>
      }
      {/* <ListItem 
        component={Link} 
        to={`/tutorial`} 
        button  
        onClick={handleDrawerToggle}
      >
        <ListItemIcon>
          <SchoolIcon />
        </ListItemIcon>
        <ListItemText><Typography  className={classes.link}>Tutorial</Typography></ListItemText>
      </ListItem> */}
      <ListItem 
        component={Link} 
        to={`/welcome`} 
        button  
        onClick={handleDrawerToggle}
      >
        <ListItemIcon>
          <SchoolIcon />
        </ListItemIcon>
        <ListItemText><Typography  className={classes.link}>Welcome</Typography></ListItemText>
      </ListItem>
      {
        !!currentUser ? (
          <ListItem 
            component={Link} 
            to={`/sign-out/`} 
            button  
            onClick={handleDrawerToggle}
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText><Typography  className={classes.link}>Sign Out</Typography></ListItemText>
          </ListItem>
        ) : (
          <ListItem 
            component={Link} 
            to={`/sign-in/`} 
            button  
            onClick={handleDrawerToggle}
          >
            <ListItemIcon>
              <AccountKeyIcon />
            </ListItemIcon>
            <ListItemText><Typography  className={classes.link}>Sign In</Typography></ListItemText>
          </ListItem>
        )
      }
    </List>
    </div>



const MainNavigationView = ({classes, appBar, handleDrawerToggle, mobileOpen, children, currentUser, unseenCommentsCount}) =>
  <div className={classes.root}>
    <AppBar className={classes.appBar}>
      <Toolbar>
        <Hidden mdUp>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={classes.navIconHide}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        {
          !appBar.title &&
          <Link className={classes.logoLink} to="/" >
            <img 
              src={require('../../assets/images/logo-nav.png')}
              className={classes.logo}
              alt="Home"
            />
          </Link>
        }
        {
          !!appBar.title &&
          <>
            <Hidden smDown>
              <Link className={classes.logoLink} to="/" >
                <img 
                  src={require('../../assets/images/logo-nav.png')}
                  className={classes.logo}
                  alt="Home"
                />
              </Link>
            </Hidden>
            <Typography variant="h6">
              {appBar.title}
            </Typography>
          </>
        }
        <div style={{flexGrow: 1}} />
        {
          (!!appBar.onClickHelp || !!appBar.rightButtons.length) &&
          <div style={{display: 'flex'}}>
            {
              !!appBar.onClickHelp &&
              <IconButton
                aria-label="help"
                aria-haspopup="true"
                onClick={appBar.onClickHelp}
                color="inherit"
              >
                <HelpIcon />
              </IconButton>
            }
            {
              appBar.rightButtons.map((button, i) => <React.Fragment key={i}>{button}</React.Fragment>)
            }
          </div>
        }
        {
          navStyle === 'horizontal' &&
          <>
            {
              !currentUser && 
              <Button component={Link} to="/home" color="inherit">Sign In</Button>
            }
            {
              !!currentUser && 
              <Button component={Link} to="/sign-out" color="inherit">Sign Out</Button>
            }
          </>
        }
      </Toolbar>
    </AppBar>
    <Hidden mdUp>
      <Drawer
        variant="temporary"
        // anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        anchor="left"
        open={!!mobileOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <DrawerContents handleDrawerToggle={handleDrawerToggle} currentUser={currentUser} classes={classes} unseenCommentsCount={unseenCommentsCount} />
      </Drawer>
    </Hidden>
    {
      navStyle === 'vertical' &&
      <Hidden smDown>
        <Drawer
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <DrawerContents handleDrawerToggle={handleDrawerToggle} currentUser={currentUser} classes={classes} unseenCommentsCount={unseenCommentsCount} />
        </Drawer>
      </Hidden>
    }
    <div className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.flex}>
        {children}
      </div>
      <footer className={classes.footer}>
        {/*
        <ul className={classes.social}>
          <li className={classes.socialItem}>
            <a target="_blank" rel="noopener noreferrer nofollow" href="https://facebook.com/gunnertechnology"><Facebook /></a>
          </li>
          <li className={classes.socialItem}>
            <a target="_blank" rel="noopener noreferrer nofollow" href="https://www.periscope.tv/gunnertech"><Twitter /></a>
          </li>
          <li className={classes.socialItem}>
            <a target="_blank" rel="noopener noreferrer nofollow" href="https://instagram.com/gunnertechnology"><Instagram /></a>
          </li>
          <li className={classes.socialItem}>
            <a target="_blank" rel="noopener noreferrer nofollow" href="https://youtube.com/channel/UCjURDi2kurZOJFK1OY-QRHg"><Youtube /></a>
          </li>
          <li className={classes.socialItem}>
            <a target="_blank" rel="noopener noreferrer nofollow" href="https://github.com/gunnertech"><GithubCircle /></a>
          </li>
          <li className={classes.socialItem}>
            <a target="_blank" rel="noopener noreferrer nofollow" href="https://itunes.apple.com/us/podcast/gunner-technology/id1308255296?mt=2"><Itunes /></a>
          </li>
          <li className={classes.socialItem}>
            <a target="_blank" rel="noopener noreferrer nofollow" href="https://www.linkedin.com/company/gunner-technology/"><Linkedin /></a>
          </li>
        </ul>
        */}

        <Typography variant="caption" paragraph className={classes.footerText}>
          © {(new Date()).getFullYear()} <Link style={{color: "white"}} to={process.env.REACT_APP_base_url}>SimpliSurvey</Link>
          &nbsp;|&nbsp;
          <Link style={{color: "white"}} to={`/privacy-policy`}>Privacy Policy</Link>
          &nbsp;&nbsp;|&nbsp;
          Version {process.env.REACT_APP_VERSION}
        </Typography>
      </footer>
    </div>
  </div>
  

export default MainNavigationView;