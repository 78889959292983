import React, { useState, useEffect } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';
// import { Redirect } from 'react-router-dom';
import uuid from 'uuid-v4'

import { Form } from 'gunner-react/web';
import Organization from 'react-shared/api/Organization';
import ContactGroup from 'react-shared/api/ContactGroup';
import { withoutKeys } from 'gunner-react';
import { withoutBlanks } from 'gunner-react';
import writeFragment from 'Util/writeFragment';


const fields = {
  name: {
    label: "Name",
    regex: /\w+/,
    required: true,
    type: 'text',
    errorMessage: "You are required enter a name"
  },
  thankYouMessage: {
    label: "Thank You Message",
    regex: /\w+/,
    type: 'text',
    helperText: "You may optionally include a message that will be shown to survey takers once they have completed a survey.",
    rows: 4
  },
  logoUrl: {
    label: "Logo",
    regex: /\w+/,
    type: 'media',
    helperText: "You may optionally select a logo to appear to survey takers once they have completed a survey. Make sure you crop your logo and upload it prior to submitting the form."
  },
}

const OrganizationFormContainer = ({organization, onSuccess = () => null, onSubmitting = () => null, onError = () => null}) => {
  const [formData, setFormData] = useState(organization || {});
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const organizationId = uuid();
  const client = useApolloClient()

  console.log("OR", organization)

  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: field === 'isExpired' ? !!value : value
    })

  const [_createOrganization] = useMutation(Organization.mutations.create, {
    variables: {input: { 
      ...withoutBlanks(formData),
      id: organizationId,
      adminGroup: `${organizationId}-Admins`,
      memberGroup: `${organizationId}-Members`,
      planType: "BASIC"
    }},
    optimisticResponse: {
      __typename: "Mutation",
      createOrganization: { 
        ...formData,
        __typename: "Organization",
        id: organizationId,
        adminGroup: `${organizationId}-Admins`,
        memberGroup: `${organizationId}-Members`,
        planType: "BASIC"
      }
    }
  });

  

  const [_updateOrganization] = useMutation(Organization.mutations.update, {
    variables: {input: {
      ...withoutBlanks(withoutKeys(formData, ['__typename'])),
    }},
    optimisticResponse: {
      __typename: "Mutation",
      updateOrganization: { 
        ...formData,
        __typename: "Organization"
      }
    },
    update: (proxy, {data: {updateOrganization}}) => writeFragment({client: proxy, data: updateOrganization, model: 'Organization'})
  });

  useEffect(() => {
    !!doSubmit && onSubmitting()

    !!doSubmit &&
    (!!formData.id ? _updateOrganization : _createOrganization)() //if this is an existing organization, update it, otherwise, create it
      .then(({data: {[(!!formData.id ? "updateOrganization" : "createOrganization")]: organization}}) => 
        !formData.id ? ( //if this is a new organization, create a contact group for it, too
          Promise.all([
            client.mutate({
              mutation: ContactGroup.mutations.create,
              variables: { input: {
                name: "Default",
                contactGroupOrganizationId: organizationId,
                adminGroup: `${organizationId}-Admins`,
                memberGroup: `${organizationId}-Members`
              }}
            }),
            client.mutate({
              mutation: ContactGroup.mutations.create,
              variables: { input: {
                name: "Hidden",
                contactGroupOrganizationId: organizationId,
                adminGroup: `${organizationId}-Admins`,
                memberGroup: `${organizationId}-Members`
              }}
            }),
          ])
        ) : (
          Promise.resolve(null)
        )
      )
      .then(() => setSubmitSuccess(true))
      .catch(e => [
        console.log(e),
        setDoSubmit(false),
        setSubmitSuccess(false),
        window.alert(e.message),
        onError(e)
      ])
  }, [doSubmit]);

  useEffect(() => {
    !!submitSuccess &&
    setTimeout(() => window.location.replace('/welcome'), 3000);
    // setTimeout(() => window.location.reload(), 2000)
  }, [submitSuccess])

  return (
    <Form 
      primaryButtonProps={{color: "primary", fullWidth: true}}
      submitting={false}
      fields={fields}
      onChange={handleChange}
      data={formData}
      onSubmit={() => setDoSubmit(true)}
      buttonLabel={"Submit"}
    />
  )
}

export default OrganizationFormContainer;