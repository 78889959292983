
import React from 'react'
import { Button, MenuItem } from '@material-ui/core'


export default ({onClick, render, buttonProps = {}}) => 
  render === 'button' ? (
    <Button
      onClick={onClick}
      variant="contained"
      color="secondary"
      size="small"
      {...buttonProps}
    >Delete</Button>
  ) : (
    <MenuItem
      onClick={onClick}
    >Delete</MenuItem>
  )
  