import React from 'react'
import Option from 'react-shared/api/Option'
import { DataTable } from 'gunner-react/web'

const fields = 
  {
    "Name": {
      value: item => <img style={{maxWidth: "100%", height: "25px"}} src={item.iconUrl ?? require(`../../../assets/images/survey/${item.position}.png`)} />,
      sort: null,
      hideLabel: true,
    },
    "Value": {
      value: item => item.value,
      sort: order =>  (a, b) => a.value > b.value ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Position": {
      value: item => item.position,
      sort: order =>  (a, b) => a.position > b.position ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    // "Created": {
    //   value: item => moment(item.createdAt).format('MM/DD/YYYY H:mm'),
    //   sort: order =>  (a, b) => a.createdAt > b.createdAt ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    // },
    // "View": {
    //   hideLabel: true,
    //   sort: null,
    //   value: item => <Button size="small" color="secondary" component={Link} to={`/contact-groups/${item.id}`}>View</Button>
    // },
    // "Edit": {
    //   hideLabel: true,
    //   sort: null,
    //   value: item => <OptionEditButton id={item.id} />
    // },
  }

export default ({promptId}) => {
  return (
    <>
      <DataTable
        fields={fields}
        search={null}
        dataListParams={{
          useButton: true,
          query: Option.queries.listByPromptId,
          variables: {
            optionPromptId: promptId,
            sortDirection: "DESC"
          },
          dataKey: "listOptionsByPromptId",
        }}
      />
    </>
  )
}