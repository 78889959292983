import React, { useState } from 'react'
import Campaign from 'react-shared/api/Campaign'
import { Typography, Box } from '@material-ui/core'
import { Redirect, Link } from 'react-router-dom'
import { DataTable } from 'gunner-react/web'

import CampaignEditButton from '../Edit/Button/CampaignEditButtonContainer'
import CampaignTemplateName from '../../CampaignTemplate/Name';
import CampaignDownloadButton from '../DownloadButton';
import MoreButton from "../../MoreButton"
import { MenuItem } from '@material-ui/core';

const LinkMenuItem = ({to, text}) => {
  const [redirectTo, setRedirectTo] = useState(null);

  return !!redirectTo ? <Redirect to={to} /> : (
    <MenuItem onClick={() => setRedirectTo(to)}>{text}</MenuItem>
  )
}


const fields = 
  {
    "Campaign Name": {
      value: item => <Link to={`/campaigns/${item.id}/surveys`}>{item.name}</Link>,
      sort: order =>  (a, b) => a.name > b.name ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Template Used": {
      value: item => <Link to={`/campaign-templates/${item.campaignCampaignTemplateId}/survey-templates`}><CampaignTemplateName id={item.campaignCampaignTemplateId} /></Link>,
      sort: null
    },
    "Active?": {
      value: item => item.inactive ? "No" : "Yes" ,
      sort: order =>  (a, b) => a.inactive > b.inactive ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Actions": {
      hideLabel: true,
      sort: null,
      value: item => 
        <MoreButton>
          <CampaignDownloadButton render="menu" id={item.id} />
          <CampaignEditButton render="menu" id={item.id} />
          <LinkMenuItem text="Send Survey" to={`/surveys/send?campaignId=${item.id}`} />
        </MoreButton>
    },
  }

export default ({organizationId}) => {
  const [items, setItems] = useState(null);

  return (
    <>
      {
        !!items && 
        !items.length &&
        <Box p={2}>
          <Typography paragraph>You have not started a campaign yet. To do so, <Link to="/campaign-templates">choose one you'd like to start</Link>.</Typography>
        </Box>
      }
      <DataTable
        fields={fields}
        search={null}
        dataListParams={{
          onItemsChange: items => setItems(items),
          useButton: true,
          query: Campaign.queries.listByOrganizationId,
          dataKey: "listCampaignsByOrganizationId",
          variables: {
            campaignOrganizationId: organizationId,
          }
        }}
      />
    </>
  )
}