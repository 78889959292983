import React from 'react';
import { useQuery } from '@apollo/client';
import CampaignTemplate from 'react-shared/api/CampaignTemplate';

const CampaignTemplateNameContainer = ({id}) => {

  const {error, data: {getCampaignTemplate = {}} = {}} = useQuery(CampaignTemplate.queries.get, {variables: {id}});
  const entry = useQuery(CampaignTemplate.queries.get, {variables: {id}});

  !!error && console.log(error);

  return (
    <>
      {getCampaignTemplate.name}
    </>
  )
}

export default CampaignTemplateNameContainer