import React from 'react'
import { 
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@material-ui/core'

export default ({
  tags, 
  textFieldProps = {}, 
  selectedTagIds, 
  handleChange, 
  helperText="Tags make organizing surveys to find and analyze",
  label="(Optional) Select Tags"
}) =>
  <FormControl component="fieldset" fullWidth>
    <FormLabel component="legend">{label}</FormLabel>
    <FormGroup>
      {
        tags.map(tag => 
          <FormControlLabel
            key={tag.id}
            control={<Checkbox checked={selectedTagIds.includes(tag.id)} onChange={handleChange} value={tag.id} />}
            label={tag.name}
          />
        )
      }
    </FormGroup>
    <FormHelperText>{helperText}</FormHelperText>
  </FormControl>
