import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { Form } from 'gunner-react/web';
import CampaignTemplate from 'react-shared/api/CampaignTemplate';
import { withoutKeys } from 'gunner-react';
import { withoutBlanks } from 'gunner-react';
import writeFragment from 'Util/writeFragment';
import uuid from 'uuid-v4'
import { useContext } from 'react';
import { CurrentUserContext } from "gunner-react"
import SurveyTemplate from 'react-shared/api/SurveyTemplate';
import SurveyTemplateFormContainer from '../../SurveyTemplate/Form/SurveyTemplateFormContainer';
import { Typography, Box, Button } from '@material-ui/core';
import Campaign from 'react-shared/api/Campaign';


const CampaignTemplateFormContainer = ({organizationId, campaignTemplate, onSuccess = () => null, onSubmitting = () => null, onError = () => null}) => {
  const [newId] = useState(uuid());
  const [formData, setFormData] = useState(campaignTemplate || {});
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const currentUser = useContext(CurrentUserContext);
  const [validations, setValidations] = useState({});
  const [newSurveyCount, setNewSurveyCount] = useState(!!formData.id ? 0 : 1);

  const {data: {listSurveyTemplatesByCampaignTemplateId: {items: surveyTemplates = []} = {}} = {}} = useQuery(SurveyTemplate.queries.listByCampaignTemplateId, {
    pollInterval: 3000,
    skip: !campaignTemplate?.id,
    variables: {
      surveyTemplateCampaignTemplateId: campaignTemplate?.id,
      limit: 100,
    }
  })
  
  const fields = {
    name: {
      label: "Template Name",
      regex: /\w+/,
      required: true,
      type: 'text',
      errorMessage: "You are required enter a name",
      helperText: `Enter a unique name that will identify this template. i.e. Customer Satisfaction Improvement`,
      placeholder: "Customer Satisfaction Improvement"
    },
    description: {
      label: "Description",
      regex: /\w+/,
      required: false,
      type: 'text',
      errorMessage: "You are required enter a name",
      helperText: "(Optional) Describe what this template is used for. i.e. This template should be used quarterly to measure various levels of satisfaction from your customers",
      placeholder: "This template should be used quarterly to measure various levels of satisfaction from your customers",
      rows: 4
    },
    ...(
      currentUser.groups.includes("Admins") ? ({
        public: {
          label: "Make this template public?",
          regex: /\w+/,
          required: false,
          type: 'checkbox',
          errorMessage: "You are required enter a name",
          helperText: "Check this box if you want this template to be shared with other organizations.",
        }
      }) : ({})
    )
  }

  const handleValidChange = key => isValid =>
    setValidations({
      ...validations,
      [key]: isValid
    })

  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: field === 'public' ? !!value : value
    })

  const [_createCampaignTemplate] = useMutation(CampaignTemplate.mutations.create, {
    variables: {input: { 
      ...withoutBlanks(formData),
      id: newId,
      adminGroup: `${organizationId}-Admins`,
      memberGroup: `${organizationId}-Members`
    }},
    optimisticResponse: {
      __typename: "Mutation",
      createCampaignTemplate: { 
        ...formData,
        id: newId,
        __typename: "CampaignTemplate",
        adminGroup: `${organizationId}-Admins`,
        memberGroup: `${organizationId}-Members`
      }
    },
    refetchQueries: [
      {
        query: Campaign.queries.listByOrganizationId,
        variables: {
          campaignOrganizationId: organizationId,
        }
      },
      {
        query: CampaignTemplate.queries.listByOrganizationId,
        variables: {
          campaignTemplateOrganizationId: organizationId,
        }
      }
    ]
  });

  

  const [_updateCampaignTemplate] = useMutation(CampaignTemplate.mutations.update, {
    variables: {input: {
      ...withoutBlanks(withoutKeys(formData, ['__typename'])),
    }},
    optimisticResponse: {
      __typename: "Mutation",
      updateCampaignTemplate: { 
        ...formData,
        __typename: "CampaignTemplate"
      }
    },
    update: (proxy, {data: {updateCampaignTemplate}}) => writeFragment({client: proxy, data: updateCampaignTemplate, model: 'CampaignTemplate'})
  });

  useEffect(() => {
    !!doSubmit && onSubmitting()

    !!doSubmit &&
    setTimeout(() => 
      (!!formData.id ? _updateCampaignTemplate : _createCampaignTemplate)() //if this is an existing campaignTemplate, update it, otherwise, create it
      .then(({data: {[(!!formData.id ? "updateCampaignTemplate" : "createCampaignTemplate")]: campaignTemplate}}) => [
        setDoSubmit(false),
        setSubmitSuccess(campaignTemplate)
      ])
      .catch(e => [
        console.log(e),
        setDoSubmit(false),
        setSubmitSuccess(false),
        window.alert(e.message),
        onError(e)
      ])
    , 1000)
  }, [doSubmit]);

  useEffect(() => {
    !!submitSuccess &&
    onSuccess(submitSuccess)
  }, [submitSuccess])

  return (
    <Form
      extraIsValid={!Object.values(validations).some(value => !value)}
      // excludeButton={!campaignTemplate?.id}
      primaryButtonProps={{color: "primary", fullWidth: true}}
      submitting={!!doSubmit}
      fields={fields}
      onChange={handleChange}
      data={formData}
      onSubmit={() => setDoSubmit(true)}
      buttonLabel={"Submit"}
      NestedForm={
        <>
          {
            [...surveyTemplates, ...[...Array(newSurveyCount).keys()]].map((st, i) => 
              <Box mt={2} mb={2} key={i}>
                <Typography variant="h5">Survey #{i+1}</Typography>
                <SurveyTemplateFormContainer 
                  isNested={true} 
                  surveyTemplate={{...st, surveyTemplateCampaignTemplateId: formData.id ?? newId}}
                  onValidChange={handleValidChange(i)}
                  forceSubmit={!!doSubmit}
                />
                {
                  !st?.id &&
                  newSurveyCount > 1 &&
                  (i+1) === surveyTemplates.length + newSurveyCount &&
                  <Button onClick={() => [setNewSurveyCount(newSurveyCount => newSurveyCount - 1), setValidations({...validations, [Object.keys(validations).length-1]: true})]} color="primary" variant="outlined" fullWidth>Remove Survey</Button>
                }
              </Box>
            )
          }
          <Button onClick={() => setNewSurveyCount(newSurveyCount => newSurveyCount + 1)} color="primary" variant="outlined" fullWidth>Add Another Survey</Button>
        </>
      }
    />
  )
}

export default CampaignTemplateFormContainer;