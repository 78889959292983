import React, { useState, useEffect } from 'react'
import { Button, MenuItem } from '@material-ui/core';
import useCampaignCsv from 'Hooks/useCampaignCsv';
import {downloadCsv} from 'gunner-react';

const Download = ({id}) => {
  const csvData = useCampaignCsv({campaignId: id});

  useEffect(() => {
    !!csvData &&
    downloadCsv(csvData)
  }, [!!csvData])

  return (
    null
  )
}

export default ({id, render = "button"}) => {
  const [doDownload, setDoDownload] = useState(false);

  useEffect(() => {
    !!doDownload && setTimeout(() => setDoDownload(false), 10000)
  }, [doDownload])

  return (
    <>
      {!!doDownload && <Download id={id} />}
      {
        render === 'menu' ? (
          <MenuItem disabled={!!doDownload} onClick={() => setDoDownload(true)}>Download Campaign Data</MenuItem>
        ) : (
          <Button disabled={!!doDownload} variant="contained" size="small" color="secondary" onClick={() => setDoDownload(true)}>Download</Button>
        )
      }
    </>
  )
}
