
import React from 'react'
import SurveyDeleteButtonView from './SurveyDeleteButtonView'
import { useMutation, useQuery } from '@apollo/client'
import Survey from 'react-shared/api/Survey'
import moment from 'moment';


export default ({id, render = "button"}) => {
  const { data: {getSurvey} = {}} = useQuery(Survey.queries.get, {variables: {id}})
  const [deleteSurvey] = useMutation(Survey.mutations.delete, {
    skip: !getSurvey,
    variables: {
      input: {
        id: getSurvey?.id,
      }
    },
    refetchQueries: [
      {
        query: Survey.queries.listByCampaignId,
        variables: {
          limit: 100,
          surveyCampaignId: getSurvey?.surveyCampaignId
        }
      }
    ]
  });



  return !getSurvey?.sendAt || moment(getSurvey?.sendAt).toDate().toISOString() <= moment().toDate().toISOString() ? (
    null
  ) : (
    <SurveyDeleteButtonView onClick={() => window.confirm("Are you sure you want to delete this scheduled survey from being sent?") && deleteSurvey()} render={render} />
  )
}