import gql from 'graphql-tag'
import Fragments from './Fragments'

const Response = {
  mutations: {
    update: gql`
      mutation UpdateResponse($input: UpdateResponseInput!) {
        updateResponse(input: $input) {
          ...ResponsePublic
        }
      }
      ${Fragments.Response.public}
    `,
    create: gql`
      mutation CreateResponse($input: CreateResponseInput!) {
        createResponse(input: $input) {
          ...ResponsePublic
        }
      }
      ${Fragments.Response.public}
    `
  },
  subscriptions: {
    onUpdate: gql`
      subscription OnUpdateResponse($responseUserId: String) {
        onUpdateResponse(responseUserId: $responseUserId) {
          ...ResponsePublic
        }
      }
      ${Fragments.Response.public}
    `
  },
  queries: {
    listByAdminGroupAndReasonSeenAt: gql`
      query ListResponsesByAdminGroupAndReasonSeenAt(
        $adminGroup: String
        $reasonSeenAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelResponseFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listResponsesByAdminGroupAndReasonSeenAt(
          adminGroup: $adminGroup
          reasonSeenAt: $reasonSeenAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...ResponsePublic
          }
          nextToken
        }
      }
      ${Fragments.Response.public}
    `,
    listByAdminGroup: gql`
      query ListResponsesByAdminGroup(
        $adminGroup: String
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelResponseFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listResponsesByAdminGroup(
          adminGroup: $adminGroup
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...ResponsePublic
          }
          nextToken
        }
      }
      ${Fragments.Response.public}
    `,
    listByAdminGroupAndPromptIdCreatedAt: gql`
      query ListResponsesByAdminGroupAndPromptIdCreatedAt(
        $adminGroup: String
        $responsePromptIdCreatedAt: ModelResponseByAdminGroupAndPromptIdCreatedAtCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelResponseFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listResponsesByAdminGroupAndPromptIdCreatedAt(
          adminGroup: $adminGroup
          responsePromptIdCreatedAt: $responsePromptIdCreatedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...ResponsePublic
          }
          nextToken
        }
      }
      ${Fragments.Response.public}
    `,
    listByAdminGroupAndSurveyTemplateIdCreatedAt: gql`
      query ListResponsesByAdminGroupAndSurveyTemplateIdCreatedAt(
        $adminGroup: String
        $responseSurveyTemplateIdCreatedAt: ModelResponseByAdminGroupAndSurveyTemplateIdCreatedAtCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelResponseFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listResponsesByAdminGroupAndSurveyTemplateIdCreatedAt(
          adminGroup: $adminGroup
          responseSurveyTemplateIdCreatedAt: $responseSurveyTemplateIdCreatedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...ResponsePublic
          }
          nextToken
        }
      }
      ${Fragments.Response.public}
    `,
    listBySurveyId: gql`
      query ListResponsesBySurveyId(
        $responseSurveyId: ID
        $updatedAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelResponseFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listResponsesBySurveyId(
          responseSurveyId: $responseSurveyId
          updatedAt: $updatedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...ResponsePublic
          }
          nextToken
        }
      }
      ${Fragments.Response.public}
    `,
    get: gql`
      query GetResponse($id: ID!) {
        getResponse(id: $id) {
          ...ResponsePublic
        }
      }
      ${Fragments.Response.public}
    `,
    list: gql`
      query ListResponses(
        $filter: ModelResponseFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            ...ResponsePublic
          }
          nextToken
        }
      }
      ${Fragments.Response.public}
    `
  }
}

export default Response