import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useApolloClient } from '@apollo/client';

import { Form } from 'gunner-react/web';
import Contact from 'react-shared/api/Contact';
import { withoutKeys } from 'gunner-react';
import { withoutBlanks } from 'gunner-react';
import writeFragment from 'Util/writeFragment';
import useContact from 'Hooks/useContact';
import ContactContactGroup from 'react-shared/api/ContactContactGroup';

const fields = {
  name: {
    label: "Name",
    regex: /\w+/,
    required: true,
    type: 'text',
    errorMessage: "You are required enter a name",
    helperText: `A name or other identifying information for the contact.`
  },
  email: {
    label: "Email",
    regex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    required: false,
    type: 'text',
    errorMessage: "Must be a valid email address",
    helperText: `You must enter an email or phone number for this contact.`
  },
  phone: {
    label: "Phone",
    // regex: /^\+\d[\d ]?[\d ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
    regex: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
    required: false,
    type: 'phone',
    errorMessage: "Must be a valid phone number",
    helperText: `You must enter an email or phone number for this contact.`
  },
}

const ContactFormContainer = ({organizationId, contact, onSuccess = () => null, onSubmitting = () => null, onError = () => null}) => {
  const [formData, setFormData] = useState(contact || {});
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const client = useApolloClient()
  const existingContact = useContact({email: formData.email, phone: formData.phone, organizationId: organizationId});

  const {data: {listContactContactGroupsByContactGroupIdAndContactId: { items: existingContactContactGroups = []} = {}} = {}} = useQuery(ContactContactGroup.queries.listByContactGroupIdAndContactId, {
    skip: !existingContact?.id || !formData.contactContactGroupId,
    variables: {
      contactId: {
        eq: existingContact?.id,
      },
      contactGroupId: formData.contactContactGroupId
    }
  })

  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: field === 'email' ? (value||"").toLowerCase() : value
    })

  const [_createContact] = useMutation(Contact.mutations.create, {
    variables: {input: { 
      ...withoutBlanks(withoutKeys(formData, ['contactContactGroupId', '__typename', 'tagContacts', 'contactContactGroups'])),
      adminGroup: `${organizationId}-Admins`,
      memberGroup: `${organizationId}-Members`
    }},
    optimisticResponse: {
      __typename: "Mutation",
      createContact: { 
        ...formData,
        __typename: "Contact",
        adminGroup: `${organizationId}-Admins`,
        memberGroup: `${organizationId}-Members`
      }
    },
    refetchQueries: [
      {
        query: ContactContactGroup.queries.listByContactGroupId,
        variables: {
          contactGroupId: formData.contactContactGroupId,
        }
      }
    ]
  });

  

  const [_updateContact] = useMutation(Contact.mutations.update, {
    variables: {input: {
      ...withoutBlanks(withoutKeys(formData, ['contactContactGroupId', '__typename', 'tagContacts', 'contactContactGroups'])),
    }},
    optimisticResponse: {
      __typename: "Mutation",
      updateContact: { 
        ...formData,
        __typename: "Contact"
      }
    },
    update: (proxy, {data: {updateContact}}) => writeFragment({client: proxy, data: updateContact, model: 'Contact'})
  });

  useEffect(() => {
    !!existingContact && 
    (console.log(existingContact) || setFormData({...existingContact, ...formData}))
  }, [existingContact?.id])

  // console.log("FORM DATA", formData)
  // console.log("existingContactContactGroup", existingContactContactGroups)
  // console.log("existingContact", existingContact)

  useEffect(() => {
    !!doSubmit && onSubmitting()

    !!doSubmit &&
    (!!formData.id ? _updateContact : _createContact)() //if this is an existing contact, update it, otherwise, create it
      .then(({data: {[(!!formData.id ? "updateContact" : "createContact")]: contact}}) => 
        !!existingContactContactGroups.length || !formData.contactContactGroupId ? (
          Promise.resolve(contact)
        ) : (
          client.mutate({
            mutation: ContactContactGroup.mutations.create,
            refetchQueries: [
              {
                query: ContactContactGroup.queries.listByContactGroupId,
                variables: {
                  contactGroupId: formData.contactContactGroupId,
                  limit: 100
                }
              }
            ],
            variables: {
              input: {
                contactId: contact.id,
                contactGroupId: formData.contactContactGroupId,
                createdAt: contact.createdAt,
                updatedAt: contact.updatedAt,
                adminGroup: contact.adminGroup,
                memberGroup: contact.memberGroup
              }
            }
          })
          .then(({data: {createContact}}) => contact)
        )
      )
      .then(contact => setSubmitSuccess(contact))
      .catch(e => [
        console.log(e),
        setDoSubmit(false),
        setSubmitSuccess(false),
        window.alert(e.message),
        onError(e)
      ])
  }, [doSubmit, !!existingContactContactGroups.length]);

  useEffect(() => {
    !!submitSuccess &&
    onSuccess(submitSuccess)
  }, [submitSuccess])

  return (
    <Form 
      primaryButtonProps={{color: "primary", fullWidth: true}}
      submitting={false}
      fields={fields}
      onChange={handleChange}
      data={formData}
      errorMessage={!!existingContactContactGroups.length ? "That contact has already been added to this group" : null}
      onSubmit={() => setDoSubmit(true)}
      buttonLabel={"Submit"}
    />
  )
}

export default ContactFormContainer;