import gql from 'graphql-tag'
import Fragments from './Fragments'

const Campaign = {
  mutations: {
    update: gql`
      mutation UpdateCampaign($input: UpdateCampaignInput!) {
        updateCampaign(input: $input) {
          ...CampaignPublic
        }
      }
      ${Fragments.Campaign.public}
    `,
    create: gql`
      mutation CreateCampaign($input: CreateCampaignInput!) {
        createCampaign(input: $input) {
          ...CampaignPublic
        }
      }
      ${Fragments.Campaign.public}
    `
  },
  subscriptions: {
    onUpdate: gql`
      subscription OnUpdateCampaign($id: String) {
        onUpdateCampaign(id: $id) {
          ...CampaignPublic
        }
      }
      ${Fragments.Campaign.public}
    `
  },
  queries: {
    listByOrganizationId: gql`
      query ListCampaignsByOrganizationId(
        $campaignOrganizationId: ID
        $updatedAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelCampaignFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listCampaignsByOrganizationId(
          campaignOrganizationId: $campaignOrganizationId
          updatedAt: $updatedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...CampaignPublic
          }
          nextToken
        }
      }
      ${Fragments.Campaign.public}
    `,
    get: gql`
      query GetCampaign($id: ID!) {
        getCampaign(id: $id) {
          ...CampaignPublic
        }
      }
      ${Fragments.Campaign.public}
    `,
    list: gql`
      query ListCampaigns(
        $filter: ModelCampaignFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            ...CampaignPublic
          }
          nextToken
        }
      }
      ${Fragments.Campaign.public}
    `
  }
}

export default Campaign