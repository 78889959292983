import React from 'react';

import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';

import Form from "../Form";

import ContactGroup from 'react-shared/api/ContactGroup';



const ContactGroupEditContainer = ({id, onSuccess = () => null, onSubmitting = () => null, onError = () => null}) => {

  const { error, loading, data: {getContactGroup: contactGroup} = {} } = useQuery(ContactGroup.queries.get, {variables: {id}});

  !!error && console.log(error)


  return (
    !!loading ? (
      <CircularProgress color="secondary" />
    ) : (
      <Form onSubmitting={onSubmitting} onSuccess={onSuccess} onError={onError} contactGroup={contactGroup} />
    )
  )
}

export default ContactGroupEditContainer;