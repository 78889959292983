import React, { useState, useContext, useEffect } from 'react';

// import OrganziationEdit from 'Components/Organization/Edit';
import { CurrentUserContext } from 'gunner-react'
import SurveyTemplateList from 'Components/SurveyTemplate/List';
import { Paper, Button, Typography, Box } from '@material-ui/core';
import Modal from 'Components/Modal';
import SurveyTemplateNew from 'Components/SurveyTemplate/New';
import NavigationBreadcrumbsContainer from 'Components/Navigation/Breadcrumbs';
import { Redirect } from 'react-router-dom'
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HelpIcon from '@material-ui/icons/Help';
import { AppBarContext } from 'gunner-react/web';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { IconButton } from '@material-ui/core';
import { useHasVisitedScreen } from 'gunner-react/web';

const AdminSurveyTemplateListRoute = ({match: {params}}) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const currentUser = useContext(CurrentUserContext);
  const [showModal, setShowModal] = useState(false);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(!useHasVisitedScreen());

  const appBar = useContext(AppBarContext);

  useEffect(() => {
    appBar.setTitle("Surveys")

    appBar.setOnClickHelp(() => () => setShowHelpModal(true))
    appBar.setRightButtons([
      <IconButton
        aria-label="add"
        aria-haspopup="true"
        onClick={() => setShowModal(true)}
        color="inherit"
      >
        <AddCircleOutlineIcon />
      </IconButton>
    ])

    return appBar.reset;
  }, [])

  return !!redirectTo ? <Redirect push to={redirectTo} /> : (
    <>
      <NavigationBreadcrumbsContainer />
      <Modal
        title="Create Survey Template"
        body={
          <SurveyTemplateNew campaignTemplateId={params.campaignTemplateId} onSuccess={surveyTemplate => [setShowModal(false), setRedirectTo(`/survey-templates/${surveyTemplate.id}/prompts`)]} />
        }
        onClose={() => setShowModal(false)}
        submitting={false}
        opened={!!showModal}
        saveButton={false}
      />
      {/* <Button variant="contained" color="secondary" onClick={() => setShowModal(true)}>Add Template</Button> */}
      <Paper square>
        <SurveyTemplateList campaignTemplateId={params.campaignTemplateId} doRefetch={!showModal} />
      </Paper>

      <Modal
        title="Help"
        body={
          <>
            <Typography paragraph>Add one or more Surveys to the Campaign Template. This will allow for a grouping of like-minded surveys.</Typography>
            <Typography paragraph>For example, if you have a "2020 Customer Satisfaction" campaign, that campaign may have a survey for each month of the year, i.e. "January Customer Satisfaction Survey", "February Customer Satisfaction Survey".</Typography>
          </>
        }
        onClose={() => setShowHelpModal(false)}
        submitting={false}
        opened={!!showHelpModal}
        saveButton={false}
      />

      {/* <SpeedDial
        ariaLabel="SpeedDial"
        icon={<SpeedDialIcon />}
        onClose={() => setSpeedDialOpen(false)}
        onOpen={() => setSpeedDialOpen(true)}
        open={speedDialOpen}
        direction={'up'}
      >
        <SpeedDialAction
          icon={<HelpIcon />}
          tooltipTitle={"Help"}
          onClick={() => setShowHelpModal(true)}
          tooltipOpen
        />
        <SpeedDialAction
          icon={<AddCircleIcon />}
          tooltipTitle={"Add Template"}
          onClick={() => setShowModal(true)}
          tooltipOpen
        />
      </SpeedDial> */}
    </>
  )
}


export default AdminSurveyTemplateListRoute; 
