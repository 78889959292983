import { useState } from 'react'
import Contact from "react-shared/api/Contact"
import { useQuery } from '@apollo/client';
import { normalizePhoneNumber } from "gunner-react"

console.log("AGAIN", normalizePhoneNumber)

const validEmail = email =>
  /\S+@\S+\.\S+/.test(email ?? "")

const validPhoneNumber = phoneNumber =>
  !!normalizePhoneNumber(phoneNumber)

export default ({contactInfo, organizationId}) => {

  const validEmails = contactInfo?.split?.(",")?.map?.(line => line.trim()).filter(line => validEmail(line)) ?? [];
  const validPhoneNumbers = contactInfo?.split?.(",")?.map?.(line => line.trim()).filter(line => validPhoneNumber(line)).map(line => normalizePhoneNumber(line)) ?? [];


  // const [contact, setContact] = useState(null);
  const [paginationToken, setPaginationToken] = useState(null);
  const filter = {
    and: [
      {
        adminGroup: {
          eq: `${organizationId}-Admins`
        }
      },
      {
        or: [
          ...validEmails.map(email => ({email: { eq: email }})),
          ...validPhoneNumbers.map(phone => ({phone: { eq: phone }})),
        ]
      }
    ]
  };

  const {error, data: {listContacts: {nextToken, items} = {}} = {}} = useQuery(Contact.queries.list, {
    skip: !validEmails.length && !validPhoneNumbers.length,
    variables: {
      limit: 1000,
      filter,
      nextToken: paginationToken
    }
  });

  // useEffect(() => {
  //   !!items &&
  //   Promise.all(validAll.filter(contactInfo => !items.find(item => item.phone === contactInfo || item.email === contactInfo)).map(contactInfo =>
  //     console.log("contactInfo", contactInfo) ||
  //     client.mutate({
  //       mutation: Contact.mutations.create,
  //       variables: {
  //         input: {
  //           name: "Anonymous",
  //           email: validEmail(contactInfo) ? contactInfo : undefined,
  //           phone: validPhoneNumber(contactInfo) ? normalizePhoneNumber(contactInfo) : undefined,
  //           adminGroup: `${organizationId}-Admins`,
  //           memberGroup: `${organizationId}-Members`
  //         }
  //       }
  //     })
  // }, [!!items])

  // useEffect(() => { 
  //   setContact(null)
  // }, [email, phone, organizationId]);

  // useEffect(() => {
  //   !contact && 
  //   !!contacts[0] &&
  //   setContact(contacts[0])

  //   !contact && 
  //   !contacts[0] &&
  //   nextToken &&
  //   setPaginationToken(nextToken)
  // }, [JSON.stringify(contacts), !contact, nextToken]);

  return (items ?? []).filter((item, index, self) => self.findIndex(selfItem => selfItem.phone === item.phone || selfItem.email === item.email) === index)
}