import React, { useEffect } from 'react'
import { Button, MenuItem } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/client';
import Invitation from 'react-shared/api/Invitation';
import { withoutKeys } from 'gunner-react';

const InvitationResendButtonContainer = ({render = "button", invitationId}) => {
  const {data: {getInvitation = {}} = {}} = useQuery(Invitation.queries.get, {variables: {id: invitationId}});
  
  const [createInvitation, {error: createError}] = useMutation(Invitation.mutations.create, {variables: {
    input: withoutKeys(getInvitation, ['__typename', 'id', 'createdAt', 'updatedAt', 'acceptor', 'user', 'invitationAcceptorId'])
  }});

  const [deleteInvitation, {error: deleteError, called: deleteCalled}] = useMutation(Invitation.mutations.delete, {variables: {
    input: {
      id: getInvitation?.id || "<>"
    }
  }})

  useEffect(() => {
    !!deleteCalled &&
    createInvitation()
  }, [deleteCalled])

  !!deleteError && console.log(deleteError)
  !!createError && console.log(createError)
  

  return deleteCalled ? null : (
    render === 'button' ? (
      <Button variant="contained" onClick={deleteInvitation} size="small" color="secondary">Resend</Button>
    ) : (
      <MenuItem onClick={deleteInvitation}>Resend</MenuItem>
    )
  )
}

export default InvitationResendButtonContainer;