import React, { useState, useContext, useEffect } from 'react';

// import OrganziationEdit from 'Components/Organization/Edit';
import { CurrentUserContext } from 'gunner-react'
import OptionList from 'Components/Option/List';
import { Paper, Button, Typography, Box } from '@material-ui/core';
import Modal from 'Components/Modal';
import OptionNew from 'Components/Option/New';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Prompt from 'react-shared/api/Prompt';
import NavigationBreadcrumbsContainer from 'Components/Navigation/Breadcrumbs';
import { AppBarContext } from 'gunner-react/web';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { IconButton } from '@material-ui/core';

const AdminOptionListRoute = ({match: {params}}) => {
  const currentUser = useContext(CurrentUserContext);
  const [showModal, setShowModal] = useState(false);
  const {data: {getPrompt = {}} = {}} = useQuery(Prompt.queries.get, {variables: {id: params.promptId}});

  const appBar = useContext(AppBarContext);

  useEffect(() => {
    appBar.setTitle("Options")

    return appBar.reset;
  }, [])

  return (
    <>
      <NavigationBreadcrumbsContainer />
      <Modal
        title="Create Option"
        body={
          <OptionNew promptId={params.promptId} onSuccess={() => setShowModal(false)} />
        }
        onClose={() => setShowModal(false)}
        submitting={false}
        opened={!!showModal}
        saveButton={false}
      />
      
      <Paper square>
        <Box m={2}>
          <Typography paragraph></Typography>
        </Box>
        <OptionList promptId={params.promptId} doRefetch={!showModal} />
      </Paper>
    </>
  )
}


export default AdminOptionListRoute; 
