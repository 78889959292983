import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { uniqBy } from 'ramda'
import ContactContactGroup from "react-shared/api/ContactContactGroup"

const ContactCountContainer = ({contactGroupId}) => {
  const [count, setCount] = useState(0);
  const [token, setToken] = useState(null);

  const {data: {listContactContactGroupsByContactGroupId: {nextToken, items = []} = {}} = {}} = useQuery(ContactContactGroup.queries.listByContactGroupId, {
    variables: {
      contactGroupId,
      nextToken: token,
      limit: 100
    }
  });

  useEffect(() => {
    !!nextToken && setToken(nextToken)
  }, [nextToken]);

  useEffect(() => {
    setCount(count + uniqBy(item => item.id, items).length)
  }, [JSON.stringify(items.map(item => item.id))])

  return (
    count
  )
}

export default ContactCountContainer;