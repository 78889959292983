import React from 'react'
import Response from 'react-shared/api/Response'
import { DataTable } from 'gunner-react/web'

import { Link } from 'react-router-dom'
import moment from 'moment';
import ReviewButton from '../ReviewButton'
import SeenAtField from '../SeenAtField';
import MoreButtonContainer from '../../MoreButton';

const fields = 
  {
    "Survey": {
      value: item => <Link to={`/surveys/${item.survey.id}/responses`}>{item.survey.surveyTemplate.name}</Link>,
      sort: order =>  (a, b) => a.survey.surveyTemplate.name > b.survey.surveyTemplate.name ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Contact": {
      value: item => <Link to={`/contacts/${item.survey.contact.id}/surveys`}>{item.survey.contact.name}</Link>,
      sort: order =>  (a, b) => a.survey.contact.name > b.survey.contact.name ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Prompt": {
      value: item => item.option.prompt.body,
      sort: order =>  (a, b) => a.option.prompt.body > b.option.prompt.body ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Option": {
      value: item => <img style={{maxWidth: "100%", height: "25px"}} src={item.option.iconUrl ?? require(`../../../assets/images/survey/${item.option.position}.png`)} />,
      sort: order =>  (a, b) => a.option.position > b.option.position ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Comment": {
      value: item => item.reason ?? "",
      sort: order =>  (a, b) => (a.reason || "") > (b.reason || "") ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Created At": {
      value: item => moment(item.createdAt).format('MM/DD/YYYY H:mm'),
      sort: order =>  (a, b) => a.createdAt > b.createdAt ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Seen At": {
      value: item => <SeenAtField id={item.id} />,
      sort: order =>  (a, b) => a.reasonSeenAt > b.reasonSeenAt ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Reviewed At": {
      value: item => !item.reason ? "--" : !!item.reviewedAt ? moment(item.reviewedAt).format('MM/DD/YYYY H:mm') : "",
      sort: order =>  (a, b) => a.reviewedAt > b.reviewedAt ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Review Comment": {
      hideLabel: true,
      sort: null,
      value: item => item.reviewComment ?? ""
    },
    "Review": {
      hideLabel: true,
      sort: null,
      value: item => !item.reason ? null : 
        <MoreButtonContainer>
          <ReviewButton render="menu" id={item.id} />
        </MoreButtonContainer>
    },
  }

export default ({surveyId}) => {
  return (
    <>
      <DataTable
        fields={fields}
        search={null}
        dataListParams={{
          useButton: true,
          query: Response.queries.listBySurveyId,
          variables: {
            responseSurveyId: surveyId
          },
          dataKey: "listResponsesBySurveyId",
        }}
      />
    </>
  )
}