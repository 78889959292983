import React, { useState, useEffect, useContext } from 'react'
import { Button, MenuItem } from '@material-ui/core';
import CampaignNew from '..';
import Modal from '../../../Modal';
import { CurrentUserContext } from 'gunner-react';
import { useQuery, useApolloClient } from '@apollo/client';
import SurveyTemplate from 'react-shared/api/SurveyTemplate';
import Prompt from 'react-shared/api/Prompt';

const useCanStartCampaign = id => {
  const [canStartCampaign, setCanStartCampaign] = useState(false);
  const client = useApolloClient();
  const {data: {listSurveyTemplatesByCampaignTemplateId: { items: surveyTemplates = []} = {}} = {}} = useQuery(SurveyTemplate.queries.listByCampaignTemplateId, {
    pollInterval: 2000,
    variables: {
      limit: 100,
      surveyTemplateCampaignTemplateId: id
    }
  });

  useEffect(() => {
    Promise.all(surveyTemplates.map(st =>
      client.query({
        fetchPolicy: "network-only",
        query: Prompt.queries.listBySurveyTemplateId,
        variables: {
          promptSurveyTemplateId: st.id
        }
      })
        .then(({data: {listPromptsBySurveyTemplateId: { items = []} = {}} = {}}) => items)
    ))
      .then(arrOfArrays => setCanStartCampaign(!!arrOfArrays.flat(1).length))
  }, [surveyTemplates.length])

  return canStartCampaign
}



const CampaignNewButtonContainer = ({render = "button", label = "Start Campaign",  campaignTemplateId, onChange, buttonProps={size: "small", color: "secondary", variant: "contained"}}) => {
  const [showModal, setShowModal] = useState(false);
  const currentUser = useContext(CurrentUserContext);
  // const canStartCampaign = useCanStartCampaign(campaignTemplateId)
  const canStartCampaign = true;

  return !canStartCampaign ? null : (
    <>
      <Modal
        title="Start Campaign"
        body={
          <CampaignNew 
            campaignTemplateId={campaignTemplateId} 
            organizationId={currentUser.userOrganizationId} 
            onSuccess={() => [setShowModal(false), onChange()]}
          />
        }
        onClose={() => setShowModal(false)}
        submitting={false}
        opened={!!showModal}
        saveButton={false}
      />
      {
        render === 'button' ? (
          <Button {...buttonProps} onClick={() => setShowModal(true)}>{label}</Button>
        ) : (
          <MenuItem onClick={() => setShowModal(true)}>{label}</MenuItem>
        )
      }
    </>
  )
}

export default CampaignNewButtonContainer