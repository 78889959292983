import React, { useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import Response from 'react-shared/api/Response'
import moment from 'moment';

export default ({id}) => {
  const {data: {getResponse} = {}} = useQuery(Response.queries.get, {variables: {id}});
  const [updateResponse] = useMutation(Response.mutations.update, {
    variables: {
      input: {
        id,
        reasonSeenAt: (new Date()).toISOString(),
        createdAt: getResponse?.createdAt,
        responseSurveyTemplateId: getResponse?.responseSurveyTemplateId,
        responsePromptId: getResponse?.responsePromptId
      }
    },
    refetchQueries: [
      {
        query: Response.queries.get,
        variables: { id }
      },
      {
        query: Response.queries.listByAdminGroupAndReasonSeenAt,
        variables: {
          limit: 100,
          nextToken: null,
          adminGroup: getResponse?.adminGroup,
          reasonSeenAt: {
            lt: "2000-01-26T22:39:21.602Z"
          }
        }
      }
    ]
  })

  useEffect(() => {
    !!getResponse?.reason && 
    !!getResponse?.reasonSeenAt &&
    getResponse?.reasonSeenAt <= "1976-01-26T22:39:21.602Z" &&
    updateResponse()
  }, [getResponse?.reason, getResponse?.reasonSeenAt])

  return (
    !getResponse?.reason ? "--" : !!getResponse?.reasonSeenAt ? moment(getResponse?.reasonSeenAt).format('MM/DD/YYYY H:mm') : ""
  )
}