import React, { useState, useEffect } from 'react'
import ContactSelectView from './ContactSelectView'
import { useQuery } from '@apollo/client'
import Contact from 'react-shared/api/Contact'
import ContactContactGroup from 'react-shared/api/ContactContactGroup'

export default ({reset, contactGroupId, onChange, onAddContactClick, addedContactId}) => {
  const [selectedContactIds, setSelectedContactIds] = useState([]);

  // const {data: {listContactsByContactGroupId: {items = []} = {}} = {}} = useQuery(Contact.queries.listByContactGroupId, {
  //   variables: {
  //     contactContactGroupId: contactGroupId,
  //     limit: 100
  //   }
  // });

  const { error, loading, refetch, data: {listContactContactGroupsByContactGroupId: {items: contactContactGroups = []} = {}} = {}} = useQuery(ContactContactGroup.queries.listByContactGroupId, {
    variables: {
      contactGroupId,
      limit: 100
    }
  });

  const items = contactContactGroups.map(contactContactGroup => contactContactGroup.contact)

  const handleChange = ({target: {value, checked}}) =>
    !!checked ? (
      setSelectedContactIds(
        value === 'all' ? (
          items.map(item => item.id)
        ) : (
          [
            ...[value],
            ...selectedContactIds
          ]
        )
      )
    ) : (
      setSelectedContactIds(
        value === 'all' ? (
          []
        ) : (
          selectedContactIds.filter(id => id !== value)
        )
      )
    )
    

  useEffect(() => {
    !!addedContactId &&
    !selectedContactIds.find(id => id !== addedContactId) &&
    setSelectedContactIds([...selectedContactIds, ...[addedContactId]])
  }, [addedContactId])

  useEffect(() => {
    onChange(selectedContactIds)
  }, [selectedContactIds])

  useEffect(() => {
    !!reset &&
    setSelectedContactIds([])

    !!reset &&
    onChange([])
  }, [reset])


  return (
    <ContactSelectView 
      contacts={items} 
      selectedContactIds={selectedContactIds} 
      handleChange={handleChange}
      onAddContactClick={onAddContactClick}
    />
  )
}