import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import { Form } from 'gunner-react/web';
import User from 'react-shared/api/User';
import { withoutKeys } from 'gunner-react';
import { withoutBlanks } from 'gunner-react';



const fields = {
  name: {
    label: "Name",
    regex: /\w+/,
    required: true,
    type: 'text',
    errorMessage: "You are required enter a name"
  },
  title: {
    label: "Title",
    regex: /\w+/,
    required: false,
    type: 'text'
  },
  email: {
    label: "Email",
    regex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    required: true,
    type: 'text',
    errorMessage: "Must be a valid email address"
  },
  phone: {
    label: "Phone",
    regex: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
    required: false,
    type: 'phone',
    errorMessage: "Must be a valid phone number"
  },
  password: {
    label: "Password",
    regex: /\w/,
    required: true,
    type: 'password',
    errorMessage: "Must be between 8 and 25 characters and contain at least one lowercase letter, capital letter, digit and special character.",
    helperText: "Must be between 8 and 25 characters and contain at least one lowercase letter, capital letter, digit and special character."
  },
  admin: {
    label: "Admin?",
    regex: /\w+/,
    required: false,
    type: 'checkbox',
  },
}

const UserFormContainer = ({user, onSuccess = () => null, onSubmitting = () => null, onError = () => null}) => {
  const [formData, setFormData] = useState(user || {});
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  
  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: (
        field === 'email' ? (
          (value||"").toLowerCase()
         ) : field === 'admin' ? (
          !!value ? true : false
         ) : (
          value
         ) 
      )
    })

  const [_createUser] = useMutation(User.mutations.createForOrganization, {
    variables: {input: { 
      ...withoutBlanks(withoutKeys(formData, ['__typename'])),
      phone: !!formData.phone ? formData.phone.replace(/\W/g, "") : undefined
    }},
    optimisticResponse: {
      __typename: "Mutation",
      createOrganizationUser: { 
        ...formData,
        phone: !!formData.phone ? formData.phone.replace(/\W/g, "") : undefined,
        __typename: "User",
      }
    },
    refetchQueries: [
      {
        query: User.queries.listByOrganizationId,
        variables: {
          userOrganizationId: formData?.userOrganizationId,
          filter: {
            inactive: {
              eq: true
            }
          }
        },
      },
      {
        query: User.queries.listByOrganizationId,
        variables: {
          userOrganizationId: formData?.userOrganizationId,
          filter: {
            inactive: {
              ne: true
            }
          }
        },
      }
    ]
  });


  useEffect(() => {
    !!doSubmit && onSubmitting()
    
    !!doSubmit &&
    _createUser()
      .then(({data: {createOrganizationUser}}) => 
        setSubmitSuccess(createOrganizationUser)
      )
      .catch(e => [
        console.log(e),
        setDoSubmit(false),
        setSubmitSuccess(false),
        window.alert(e.message),
        onError(e)
      ])
  }, [doSubmit]);

  useEffect(() => {
    !!submitSuccess &&
    onSuccess(submitSuccess)
  }, [submitSuccess])

  return (
    <Form 
      primaryButtonProps={{color: "primary", fullWidth: true}}
      submitting={false}
      fields={fields}
      onChange={handleChange}
      data={formData}
      onSubmit={() => setDoSubmit(true)}
      buttonLabel={"Submit"}
    />
  )
}

export default UserFormContainer;