import React, { useState } from 'react'
import { Button, MenuItem } from '@material-ui/core';
import ContactTags from '../';
import Modal from '../../../Modal';

export default ({id, contactGroupId, tagId, render = "menu"}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Modal
        title={`Edit Tags`}
        body={
          <ContactTags contactGroupId={contactGroupId} tagId={tagId} id={id} onSuccess={() => setShowModal(false)} />
        }
        onClose={() => setShowModal(false)}
        submitting={false}
        opened={!!showModal}
        saveButton={false}
      />
      {
        render === 'button' ? (
          <Button variant="contained" size="small" color="secondary" onClick={() => setShowModal(true)}>Tags</Button>
        ) : (
          <MenuItem variant="contained" size="small" color="secondary" onClick={() => setShowModal(true)}>Tags</MenuItem>
        )
      }
    </>
  )
}