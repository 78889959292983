import gql from 'graphql-tag'
import Fragments from './Fragments'

const TagSurvey = {
  mutations: {
    update: gql`
      mutation UpdateTagSurvey($input: UpdateTagSurveyInput!) {
        updateTagSurvey(input: $input) {
          ...TagSurveyPublic
        }
      }
      ${Fragments.TagSurvey.public}
    `,
    create: gql`
      mutation CreateTagSurvey($input: CreateTagSurveyInput!) {
        createTagSurvey(input: $input) {
          ...TagSurveyPublic
        }
      }
      ${Fragments.TagSurvey.public}
    `,
    delete: gql`
      mutation DeleteTagSurvey($input: DeleteTagSurveyInput!) {
        deleteTagSurvey(input: $input) {
          ...TagSurveyPublic
        }
      }
      ${Fragments.TagSurvey.public}
    `
  },
  subscriptions: {
    onUpdate: gql`
      subscription OnUpdateTagSurvey($id: String) {
        onUpdateTagSurvey(id: $id) {
          ...TagSurveyPublic
        }
      }
      ${Fragments.TagSurvey.public}
    `
  },
  queries: {
    listBySurveyId: gql`
      query ListTagSurveysBySurveyId(
        $surveyId: ID
        $tagId: ModelIDKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelTagSurveyFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listTagSurveysBySurveyId(
          surveyId: $surveyId
          tagId: $tagId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...TagSurveyPublic
          }
          nextToken
        }
      }
      ${Fragments.TagSurvey.public}
    `,
    listByTagId: gql`
      query ListTagSurveysByTagId(
        $tagId: ID
        $surveyId: ModelIDKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelTagSurveyFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listTagSurveysByTagId(
          tagId: $tagId
          surveyId: $surveyId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...TagSurveyPublic
          }
          nextToken
        }
      }
      ${Fragments.TagSurvey.public}
    `,
    get: gql`
      query GetTagSurvey($id: ID!) {
        getTagSurvey(id: $id) {
          ...TagSurveyPublic
        }
      }
      ${Fragments.TagSurvey.public}
    `,
    list: gql`
      query ListTagSurveys(
        $filter: ModelTagSurveyFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listTagSurveys(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            ...TagSurveyPublic
          }
          nextToken
        }
      }
      ${Fragments.TagSurvey.public}
    `
  }
}

export default TagSurvey