import React, { useState } from 'react'
import { Button } from '@material-ui/core';
import Modal from '../../Modal';
import ResponseEdit from '../Edit';

export default ({id}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Modal
        title="Leave a Comment"
        body={
          <ResponseEdit id={id} onSuccess={() => setShowModal(false)} data={{reasonSeenAt: "1976-01-26T22:39:21.602Z"}} />
        }
        onClose={() => setShowModal(false)}
        submitting={false}
        opened={!!showModal}
        saveButton={false}
      />
      <Button fullWidth variant="contained" size="small" color="primary" onClick={() => setShowModal(true)}>Leave a Comment</Button>
    </>
  )
}