import React from 'react'
import User from 'react-shared/api/User'

import moment from 'moment';

import UserPasswordResetButton from '../PasswordResetButton';
import UserStatusSwitch from '../StatusSwitch';
import UserAdminSwitch from '../AdminSwitch';
import MoreButtonContainer from '../../MoreButton';

import { DataTable } from 'gunner-react/web'

const fields = 
  {
    "Name": {
      value: item => item.name || "",
      sort: order =>  (a, b) => a.name || "" > b.name || "" ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Title": {
      value: item => item.title || "",
      sort: order =>  (a, b) => a.title || "" > b.title || "" ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Email": {
      value: item => item.email,
      sort: order =>  (a, b) => a.email > b.email ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Phone": {
      value: item => item.phone,
      sort: order =>  (a, b) => a.phone > b.phone ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Joined": {
      value: item => moment(item.createdAt).format('MM/DD/YYYY H:mm'),
      sort: order =>  (a, b) => a.createdAt > b.createdAt ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Status": {
      value: item => <UserStatusSwitch user={item} id={item.id} />,
      sort: order =>  (a, b) => a.inactive > b.inactive ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Admin": {
      value: item => <UserAdminSwitch user={item} id={item.id} />,
      sort: null
    },
    "Password": {
      hideLabel: true,
      value: item => <MoreButtonContainer><UserPasswordResetButton label="Change Password" render="menu" id={item.id} /></MoreButtonContainer>,
      sort: null
    },
  }



export default ({filter, organizationId}) => {
  return (
    <>
      <DataTable
        fields={fields}
        search={null}
        dataListParams={{
          useButton: true,
          query: User.queries.listByOrganizationId,
          variables: {
            userOrganizationId: organizationId,
            filter
          },
          dataKey: "listUsersByOrganizationId",
        }}
      />
    </>
  )
}

// const UserListContainer = ({filter, organizationId, doRefetch}) => {
//   const [token, setToken] = useState(null);
//   const { refetch, loading, error, data: {listUsersByOrganizationId: {nextToken, items: users = []} = {}} = {}} = useQuery(User.queries.listByOrganizationId, {
//     variables: {
//       limit: 1000,
//       userOrganizationId: organizationId,
//       nextToken: token,
//       filter
//     }
//   });


//   useEffect(() => {
//     !!doRefetch && refetch()
//   }, [doRefetch])

//   !!error && console.log(error)

//   return (
//     <>
//       <UserListView users={users} />
//       {
//         loading ? (
//           <CircularProgress color="secondary" />
//         ) : !!nextToken ? (
//           <Button variant="contained" onClick={() => setToken(nextToken)} fullWidth>Load More</Button>
//         ) : (
//           null
//         )
//       }
//     </>
//   )
// }

// export default UserListContainer;