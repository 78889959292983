import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { Form } from 'gunner-react/web';
import Campaign from 'react-shared/api/Campaign';
import { withoutKeys } from 'gunner-react';
import { withoutBlanks } from 'gunner-react';
import writeFragment from 'Util/writeFragment';
import CampaignTemplate from 'react-shared/api/CampaignTemplate';

const CampaignFormContainer = ({organizationId, campaign, onSuccess = () => null, onSubmitting = () => null, onError = () => null}) => {
  const [formData, setFormData] = useState(campaign || {});
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const fields = {
    name: {
      label: "Name",
      regex: /\w+/,
      required: true,
      type: 'text',
      errorMessage: "You are required enter a name",
      helperText: !!formData?.id ? undefined : `You may use the name of the template or give this campaign a custom name. i.e. "Quarterly Customer Satisfaction" could be renamed "Q1 2020 Customer Satisfaction"`,
    },
    inactive: {
      label: "Deactivate this campaign?",
      regex: /\w+/,
      required: false,
      type: !!formData.id ? 'checkbox' : 'hidden',
      errorMessage: "You are required enter a name",
      helperText: "Check this box if you want to prevent surveys in this campaign from being sent.",
    },
  }

  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: field === 'inactive' ? !!value : value
    })

  const {data: {getCampaignTemplate} = {}} = useQuery(CampaignTemplate.queries.get, {variables: {id: campaign.campaignCampaignTemplateId}})

  const [_createCampaign] = useMutation(Campaign.mutations.create, {
    variables: {input: { 
      ...withoutBlanks(formData),
      adminGroup: `${organizationId}-Admins`,
      memberGroup: `${organizationId}-Members`
    }},
    optimisticResponse: {
      __typename: "Mutation",
      createCampaign: { 
        ...formData,
        __typename: "Campaign",
        adminGroup: `${organizationId}-Admins`,
        memberGroup: `${organizationId}-Members`
      }
    },
    refetchQueries: [
      {
        query: Campaign.queries.listByOrganizationId,
        variables: {
          campaignOrganizationId: organizationId
        }
      }      
    ]
  });

  

  const [_updateCampaign] = useMutation(Campaign.mutations.update, {
    variables: {input: {
      ...withoutBlanks(withoutKeys(formData, ['__typename', 'campaignTemplate'])),
    }},
    optimisticResponse: {
      __typename: "Mutation",
      updateCampaign: { 
        ...formData,
        __typename: "Campaign"
      }
    },
    update: (proxy, {data: {updateCampaign}}) => writeFragment({client: proxy, data: updateCampaign, model: 'Campaign'})
  });

  useEffect(() => {
    !!getCampaignTemplate?.name &&
    !formData?.name &&
    handleChange('name', getCampaignTemplate?.name)
  }, [getCampaignTemplate?.name, formData?.name])

  useEffect(() => {
    !!doSubmit && onSubmitting()

    !!doSubmit &&
    (!!formData.id ? _updateCampaign : _createCampaign)() //if this is an existing campaign, update it, otherwise, create it
      .then(({data: {[(!!formData.id ? "updateCampaign" : "createCampaign")]: campaign}}) => 
        setSubmitSuccess(true)
      )
      .catch(e => [
        console.log(e),
        setDoSubmit(false),
        setSubmitSuccess(false),
        window.alert(e.message),
        onError(e)
      ])
  }, [doSubmit]);

  useEffect(() => {
    !!submitSuccess &&
    onSuccess()
  }, [submitSuccess])

  return (
    <Form 
      primaryButtonProps={{color: "primary", fullWidth: true}}
      submitting={false}
      fields={fields}
      onChange={handleChange}
      data={formData}
      onSubmit={() => setDoSubmit(true)}
      buttonLabel={"Submit"}
    />
  )
}

export default CampaignFormContainer;