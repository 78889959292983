import React, { useContext, useEffect } from 'react';

import { Paper } from '@material-ui/core';
import NavigationBreadcrumbsContainer from 'Components/Navigation/Breadcrumbs';
import TagContactList from 'Components/TagContact/List';
import { AppBarContext } from 'gunner-react/web';


export default ({match: {params}}) => {
  const appBar = useContext(AppBarContext);

  useEffect(() => {
    appBar.setTitle("Contacts")

    return appBar.reset
  }, [])

  return (
    <>
      <NavigationBreadcrumbsContainer />
      <Paper square>
        <TagContactList tagId={params.tagId} />
      </Paper>
    </>
  )
}
