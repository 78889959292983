import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


import { CurrentUserContext } from 'gunner-react'

import { Paper, Box, Grid, Button } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import CampaignTemplateList from 'Components/CampaignTemplate/List';
// import CampaignTemplateNewButton from 'Components/CampaignTemplate/New/Button';
// import CampaignList from 'Components/Campaign/List';
import Modal from 'Components/Modal';
import { AppBarContext } from 'gunner-react/web';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { IconButton } from '@material-ui/core';
import { useHasVisitedScreen } from 'gunner-react/web';
import CampaignTemplateNew from 'Components/CampaignTemplate/New';
import CampaignNewButton from 'Components/Campaign/New/Button';

const useQueryString = () => {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles(theme => ({
  // speedDial: {
  //   position: 'absolute',
  //   '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
  //     bottom: theme.spacing(8),
  //     right: theme.spacing(2),
  //   },
  // },
}));


export default () => {
  const classes = useStyles();
  const query = useQueryString();
  const [value, setValue] = useState(query.get("value") || "campaigns")
  const [redirectTo, setRedirectTo] = useState(null)
  const [doRefetch, setDoRefetch] = useState(false)
  const currentUser = useContext(CurrentUserContext);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [showCampaignModal, setShowCampaignModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(!useHasVisitedScreen());
  const [campaignTemplateId, setCampaignTemplateId] = useState(false);
  const appBar = useContext(AppBarContext);

  const handleChange = (evt, value) => [
    setValue(value),
    setDoRefetch(true),
    setTimeout(() => setDoRefetch(false), 1000),
    setRedirectTo("/campaigns")
  ]

  useEffect(() => {
    appBar.setTitle("Manage Campaigns")
    appBar.setOnClickHelp(() => () => setShowHelpModal(true))
    appBar.setRightButtons([
      <IconButton
        aria-label="add"
        aria-haspopup="true"
        onClick={() => setShowCampaignModal(true)}
        color="inherit"
      >
        <AddCircleOutlineIcon />
      </IconButton>
    ])

    return appBar.reset;
  }, [])
  

  return !!redirectTo ? <Redirect push to={redirectTo} /> : (
    <Paper square>
      <Modal
        title="Help"
        body={
          <>
            <Typography paragraph>Templates are used to start campaigns.</Typography>
            <Typography paragraph>Use this screen to find an existing campaign that suits your needs and click "Select Campaign".</Typography>
            <Typography paragraph>If none work for you, click "Add Template" to create a new template which can then be selected.</Typography>
          </>
        }
        onClose={() => setShowHelpModal(false)}
        submitting={false}
        opened={!!showHelpModal}
        saveButton={false}
      />
      <Modal
        title="Start Campaign Now?"
        body={
          <Box style={{'overflow': 'hidden'}}>
            <Typography paragraph>Would you like to use this template to start a campaign now?</Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <CampaignNewButton onChange={() => setRedirectTo("/campaigns")} label="Yes" campaignTemplateId={campaignTemplateId} buttonProps={{fullWidth: true, size: "large", color: "secondary", variant: "contained"}} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Button fullWidth onClick={() => setCampaignTemplateId(null)} size="large" variant="contained">No</Button>
              </Grid>
            </Grid>
          </Box>
        }
        onClose={() => setCampaignTemplateId(null)}
        submitting={false}
        opened={!!campaignTemplateId}
        saveButton={false}
      />
      <Modal
        title="New Campaign Template"
        body={
          <CampaignTemplateNew 
            organizationId={currentUser.userOrganizationId} 
            onSuccess={campaignTemplate => [setShowCampaignModal(false), setCampaignTemplateId(campaignTemplate.id)]}
          />
        }
        onClose={() => setShowCampaignModal(false)}
        submitting={false}
        opened={!!showCampaignModal}
        saveButton={false}
      />
      {/* <SpeedDial
        ariaLabel="SpeedDial"
        icon={<SpeedDialIcon />}
        onClose={() => setSpeedDialOpen(false)}
        onOpen={() => setSpeedDialOpen(true)}
        open={speedDialOpen}
        direction={'up'}
      >
        <SpeedDialAction
          icon={<HelpIcon />}
          tooltipTitle={"Help"}
          onClick={() => setShowHelpModal(true)}
          tooltipOpen
        />
        <SpeedDialAction
          icon={<AddCircleIcon />}
          tooltipTitle={"Add Template"}
          onClick={() => setShowCampaignModal(true)}
          tooltipOpen
        />
      </SpeedDial> */}
      {/* <CampaignTemplateNewButton hideButton showModal={showCampaignModal} onSuccess={campaignTemplate => setRedirectTo(`/campaign-templates/${campaignTemplate.id}/survey-templates`)} onChange={show => setDoRefetch(!show)} organizationId={currentUser.userOrganizationId} /> */}
      <CampaignTemplateList onNewCampaign={() => handleChange(null,'campaigns')} doRefetch={doRefetch} organizationId={currentUser.userOrganizationId} />
    </Paper>
  )
}