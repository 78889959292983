import gql from 'graphql-tag'
import Fragments from './Fragments'

const ContactGroup = {
  mutations: {
    update: gql`
      mutation UpdateContactGroup($input: UpdateContactGroupInput!) {
        updateContactGroup(input: $input) {
          ...ContactGroupPublic
        }
      }
      ${Fragments.ContactGroup.public}
    `,
    create: gql`
      mutation CreateContactGroup($input: CreateContactGroupInput!) {
        createContactGroup(input: $input) {
          ...ContactGroupPublic
        }
      }
      ${Fragments.ContactGroup.public}
    `
  },
  subscriptions: {
    onUpdate: gql`
      subscription OnUpdateContactGroup($id: String) {
        onUpdateContactGroup(id: $id) {
          ...ContactGroupPublic
        }
      }
      ${Fragments.ContactGroup.public}
    `
  },
  queries: {
    listByOrganizationId: gql`
      query ListContactGroupsByOrganizationId(
        $contactGroupOrganizationId: ID
        $updatedAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelContactGroupFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listContactGroupsByOrganizationId(
          contactGroupOrganizationId: $contactGroupOrganizationId
          updatedAt: $updatedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...ContactGroupPublic
          }
          nextToken
        }
      }
      ${Fragments.ContactGroup.public}
    `,
    get: gql`
      query GetContactGroup($id: ID!) {
        getContactGroup(id: $id) {
          ...ContactGroupPublic
        }
      }
      ${Fragments.ContactGroup.public}
    `,
    list: gql`
      query ListContactGroups(
        $filter: ModelContactGroupFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listContactGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            ...ContactGroupPublic
          }
          nextToken
        }
      }
      ${Fragments.ContactGroup.public}
    `
  }
}

export default ContactGroup