import React from 'react'
import { TextField, MenuItem } from '@material-ui/core'

export default ({contactGroups, textFieldProps = {}, selectedContactGroupId, handleChange}) =>
  <TextField
    label="Select Contact Group"
    fullWidth
    {...textFieldProps}
    select
    value={selectedContactGroupId || ""}
    onChange={handleChange}
  >
    {
      contactGroups.map(contactGroup => 
        <MenuItem key={contactGroup.id} value={contactGroup.id}>
          {contactGroup.name}
        </MenuItem>
      )
    }
  </TextField>