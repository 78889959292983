import React from 'react'
import { useQuery } from '@apollo/client'
import Prompt from 'react-shared/api/Prompt'
import SurveyTemplate from 'react-shared/api/SurveyTemplate'
import Campaign from 'react-shared/api/Campaign'
import { Link } from 'react-router-dom'
import PromptEditButton from '../Edit/Button/PromptEditButtonContainer';
import MoreButtonContainer from '../../MoreButton';
import { DataTable } from 'gunner-react/web'

export default ({surveyTemplateId}) => {
  const query = {query: Prompt.queries.listBySurveyTemplateId, variables: {promptSurveyTemplateId: surveyTemplateId, sortDirection: "ASC"}}
  const { data: {getSurveyTemplate} = {}} = useQuery(SurveyTemplate.queries.get, {variables: {id: surveyTemplateId}})
  const { data: {getCampaignTemplate} = {}} = useQuery(Campaign.queries.get, {variables: {id: getSurveyTemplate?.surveyTemplateCampaignTemplateId}, skip: !getSurveyTemplate?.surveyTemplateCampaignTemplateId})

  const fields = 
  {
    "Body": {
      value: item => <Link to={`/prompts/${item.id}/options`}>{item.body}</Link>,
      sort: order =>  (a, b) => a.body > b.body ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Position": {
      value: item => item.position,
      sort: order =>  (a, b) => a.position > b.position ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Edit": {
      hideLabel: true,
      sort: null,
      value: item => !!getCampaignTemplate?.public ? null :
        <MoreButtonContainer>
          <PromptEditButton render="menu" id={item.id} />
        </MoreButtonContainer>
    },
  }

  return (
    <>
      <DataTable
        fields={fields}
        search={null}
        dataListParams={{
          defaultSort: order => (a, b) => a.position < b.position ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1),
          useButton: true,
          query: query.query,
          variables: query.variables,
          dataKey: "listPromptsBySurveyTemplateId",
        }}
      />
    </>
  )
}