import React from 'react';

export default () => {
  return (
<div>
<h1>SimpliSurvey Tutorial</h1>

<video width="320" height="240" controls style={{width: '100%', height: 'auto'}} >
  <source src={require(`../../assets/videos/tutorial.mp4`)} type="video/mp4" />
</video>

<h2>Sign-up</h2>
      <ul>
         <li><span>Go to SimpliSurvey.com; click the &ldquo;Sign-in&rdquo; button; click &ldquo;Create account&rdquo;</span></li>
         <li><span>Enter your name, email address, password, and mobile phone number, including area code; click &ldquo;Create Account&rdquo;</span></li>
         <li><span>Your email address will receive a confirmation code; enter it in &ldquo;Confirmation Code&rdquo; and click &ldquo;Confirm&rdquo;</span></li>
      </ul>
      <p><span>Congratulations! You now have an active SimpliSurvey account.</span></p>
      <h2>Set-up</h2>
      <ul>
         <li><span>Click on &ldquo;Campaigns&rdquo; (you may review template surveys or create your own by clicking on the icon in the upper right hand corner</span></li>
         <li><span>By scrolling to the bottom, click &ldquo;Select&rdquo; and then &ldquo;Add&rdquo; to activate that campaign (you may activate one or more survey campaigns)</span></li>
         <li><span>Deactivate a survey campaign by clicking on the active campaign, scrolling to the bottom and using the &ldquo;Active? &ldquo;slide bar. You may activate, deactivate or reactivate survey campaigns at any time.</span></li>
         <li><span>When creating a campaign &ldquo;Create Template&rdquo;, type in a Campaign Name; click on &ldquo;+&rdquo; icon beside &ldquo;Surveys&rdquo;; type in survey name; click on &ldquo;+&rdquo; icon beside &ldquo;Prompts&rdquo;; type in your first survey question, (either click on &ldquo;+&rdquo; icon beside &ldquo;Prompts&rdquo; to add next question OR click &ldquo;SUBMIT&rdquo; to save your completed survey. For a video click here </span></li>
      </ul>
      <p><span><a href="https://www.google.com/url?q=https://www.screencast.com/t/qPJS8Qvdvv&amp;sa=D&amp;ust=1561944406419000">https://www.screencast.com/t/qPJS8Qvdvv</a></span></p>
      <ul>
         <li><span>If you save a created survey in error just &ldquo;Choose Template&rdquo;, click on that campaign and you will be presented with a &ldquo;delete&rdquo; button for any survey that has never been &ldquo;selected&rdquo;. </span></li>
         <li><span>Click on &ldquo;Settings&rdquo; to edit your Organization Name; click &ldquo;Save&rdquo;</span></li>
      </ul>
      <p><span>Users provide an option to invite other associates/team members to send out your surveys.</span></p>
      <ul>
         <li><span>Click on &ldquo;Users&rdquo;. By clicking the &ldquo;Add Member&rdquo; icon in the top right-hand corner of the screen, you may add as many users or Admin users as you desire. The &ldquo;Name&rdquo; and &ldquo;Email&rdquo; address are required fields when adding a user</span></li>
         <li><span>When adding a user, the &ldquo;Select&rdquo; field is where you determine the user&rsquo;s role as an Admin or Member (users can only send surveys; Admins can send surveys, add users, change survey questions, and see results)</span></li>
         <li><span>After selecting the new user status, click &ldquo;Send&rdquo;, and that individual will receive an email or text inviting them to download the APP and become a surveyor (Member or Admin) for your Survey Campaigns</span></li>
         <li><span>Invited Users will show up in black, along with the date they were invited</span></li>
         <li><span>To the right of their names, you can re-invite them or delete the invitation</span></li>
         <li><span>Users who accept and sign-up will show up in purple. Any active user can be deactivated with the slide bar to the right of their name</span></li>
      </ul>
      <p><span>Contacts provide an option to send surveys to multiple individuals at once.</span></p>
      <ul>
         <li><span>Click on &ldquo;Contacts&rdquo;; click on the &ldquo;Add Group&rdquo; icon in the top right-hand corner of the screen; name your Group; click &ldquo;Submit&rdquo;</span></li>
         <li><span>Click &ldquo;Add Contact&rdquo; (include Name, Phone, or Email of each person in your Contact list; click &ldquo;Submit&rdquo;</span></li>
      </ul>
      <p><span>NOTE: SimpliSurvey will default to sending out a text when both the phone number and email address is entered</span></p>
      <h2>Send Survey</h2>
      <p><span>There are three ways to survey:</span></p>
      <p><span>1) One survey sent at a time. You or an active user can click on &ldquo;Send Survey&rdquo;.</span></p>
      <p><span>Select your Campaign and Survey (or if you initially chose only one, that campaign/survey will default)</span></p>
      <p><span>Enter the mobile phone number or email address of the individual to be surveyed.</span></p>
      <p><span>You may use the &ldquo;Recipient Identifier&rdquo; to enter the person&rsquo;s name, ID, or other information</span></p>
      <p><span>Click the purple &ldquo;Send&rdquo; button</span></p>
      <p><span>2) Using Contact Groups sends surveys in batches. Select &ldquo;Send Survey&rdquo;; select your Campaign and Survey; select a &ldquo;Contact Group&rdquo;</span></p>
      <p><span>You may deactivate any Contact Group member by clicking on the checked box.</span></p>
      <p><span>Click on the purple &ldquo;Send&rdquo; button, and all activated Contact Group members will receive a survey</span></p>
      <p><span>3) Contact SimpliSurvey, and we can discuss automating surveys by building out an &ldquo;API&rdquo; to interface with your software</span></p>
      <h2>Dashboard</h2>
      <p><span>Survey responses are included in your Dashboard in real-time &ndash; simply click on &ldquo;Dashboard&rdquo;</span></p>
      <ul>
         <li><span>If more than one campaign was chosen at set-up, then select a Campaign from the top bar</span></li>
         <li><span>If more than one Survey was selected at set-up, select a Survey. Choose a time period to be viewed</span></li>
         <li><span>A list of active Uses will appear, the number of responded surveys per user, and the average survey question score for each user will appear</span></li>
      </ul>
      <p><span>NOTE: Using five survey emoticon choices, scores are weighted 0, 25, 50, 75, 100 per question</span></p>
      <ul>
         <li><span>Next you will find an emoticon graph with the total average score for your selected time period, along with a trend graph based upon your selected time interval. By clicking on the emoticon, you will see any survey comments. A &ldquo;follow-up box&rdquo; will display beside the comment</span></li>
         <li><span>By clicking the follow-up box, an ADMIN will be able to leave a date/time stamped response to the comment</span></li>
         <li><span>The follow-up box is for internal use &ndash; this response does NOT go back to the surveyed person</span></li>
      </ul>
      <p><span>All Dashboard information along with comments and follow-up details can be downloaded to a spreadsheet by clicking the &ldquo;Download&rdquo; icon in the top right-hand corner of the Dashboard screen and selecting &ldquo;CSV&rdquo;.</span></p>
</div>
);
}