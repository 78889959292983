import gql from 'graphql-tag'
import Fragments from './Fragments'

const ContactContactGroup = {
  mutations: {
    update: gql`
      mutation UpdateContactContactGroup($input: UpdateContactContactGroupInput!) {
        updateContactContactGroup(input: $input) {
          ...ContactContactGroupPublic
        }
      }
      ${Fragments.ContactContactGroup.public}
    `,
    create: gql`
      mutation CreateContactContactGroup($input: CreateContactContactGroupInput!) {
        createContactContactGroup(input: $input) {
          ...ContactContactGroupPublic
        }
      }
      ${Fragments.ContactContactGroup.public}
    `,
    delete: gql`
      mutation DeleteContactContactGroup($input: DeleteContactContactGroupInput!) {
        deleteContactContactGroup(input: $input) {
          ...ContactContactGroupPublic
        }
      }
      ${Fragments.ContactContactGroup.public}
    `
  },
  subscriptions: {
    onUpdate: gql`
      subscription OnUpdateContactContactGroup($id: String) {
        onUpdateContactContactGroup(id: $id) {
          ...ContactContactGroupPublic
        }
      }
      ${Fragments.ContactContactGroup.public}
    `
  },
  queries: {
    listByContactGroupId: gql`
      query ListContactContactGroupsByContactGroupId(
        $contactGroupId: ID
        $updatedAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelContactContactGroupFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listContactContactGroupsByContactGroupId(
          contactGroupId: $contactGroupId
          updatedAt: $updatedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...ContactContactGroupPublic
          }
          nextToken
        }
      }
      ${Fragments.ContactContactGroup.public}
    `,
    listByContactGroupIdAndContactId: gql`
      query ListContactContactGroupsByContactGroupIdAndContactId(
        $contactGroupId: ID
        $contactId: ModelIDKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelContactContactGroupFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listContactContactGroupsByContactGroupIdAndContactId(
          contactGroupId: $contactGroupId
          contactId: $contactId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...ContactContactGroupPublic
          }
          nextToken
        }
      }
      ${Fragments.ContactContactGroup.public}
    `,
    get: gql`
      query GetContactContactGroup($id: ID!) {
        getContactContactGroup(id: $id) {
          ...ContactContactGroupPublic
        }
      }
      ${Fragments.ContactContactGroup.public}
    `,
    list: gql`
      query ListContactContactGroups(
        $filter: ModelContactContactGroupFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listContactContactGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            ...ContactContactGroupPublic
          }
          nextToken
        }
      }
      ${Fragments.ContactContactGroup.public}
    `
  }
}

export default ContactContactGroup