import React from 'react'

import {FormGroup, FormControlLabel, Switch} from '@material-ui/core'
import { useQuery, useMutation } from '@apollo/client'
import User from 'react-shared/api/User'

export default ({id, user}) => {
  const {data: {getUser} = {}} = useQuery(User.queries.get, {variables: {id}});
  const [updateUser] = useMutation(User.mutations.update, {
    variables: {
      input: {
        id,
        inactive: !(getUser||user).inactive
      }
    },
    refetchQueries: [
      {
        query: User.queries.get,
        variables: {id}
      },
      {
        query: User.queries.listByOrganizationId,
        variables: {
          limit: 1000,
          userOrganizationId: (getUser||user).userOrganizationId,
          nextToken: null,
          filter: {
            inactive: {
              eq: true
            }
          }
        }
      },
      {
        query: User.queries.listByOrganizationId,
        variables: {
          limit: 1000,
          userOrganizationId: (getUser||user).userOrganizationId,
          nextToken: null,
          filter: {
            inactive: {
              ne: true
            }
          }
        }
      }
    ],
    optimisticResponse: {
      __typename: "Mutation",
      updateUser: {
        ...(user||getUser),
        inactive: !(getUser||user).inactive
      }
    }
  })

  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch size="small" checked={!(getUser||user).inactive} onChange={updateUser} />}
        label={(getUser||user).inactive ? "Inactive" : "Active"}
      />
    </FormGroup>
  )
}