import React, { useState, useContext, useEffect } from 'react';

import OrganziationEdit from 'Components/Organization/Edit';
import { CurrentUserContext } from 'gunner-react'
import { useHasVisitedScreen } from 'gunner-react/web';
import Modal from 'Components/Modal';
import { Typography } from '@material-ui/core';
import { AppBarContext } from 'gunner-react/web';

const AdminOrganziationEditRoute = ({match: {params}}) => {
  const currentUser = useContext(CurrentUserContext);
  const [showHelpModal, setShowHelpModal] = useState(!useHasVisitedScreen());

  const appBar = useContext(AppBarContext);

  useEffect(() => {
    appBar.setTitle("Settings")

    appBar.setOnClickHelp(() => () => setShowHelpModal(true))

    return appBar.reset;
  }, [])
  

  return (
    <>
      <OrganziationEdit id={currentUser.userOrganizationId}  />

      <Modal
        title="Help"
        body={
          <>
            <Typography paragraph>This screen allows you to edit information about your organization.</Typography>
            <Typography paragraph>If you include a "Thank You Message", it will be displayed to survey responders after they complete your surveys.</Typography>
            <Typography paragraph>Likewise, if you include a logo, it will be show to respondents after they complete a survey.</Typography>
            <Typography paragraph>If you include a logo, make sure to crop and upload it prior to submitting the form, or it will not be saved.</Typography>
          </>
        }
        onClose={() => setShowHelpModal(false)}
        submitting={false}
        opened={!!showHelpModal}
        saveButton={false}
      />
    </>
  )
}


export default AdminOrganziationEditRoute; 
