import React, { useEffect, useContext, useState } from 'react';
import CampaignSelect from 'Components/Campaign/Select';
import { CurrentUserContext } from 'gunner-react';
import { uniqBy } from 'ramda'

import uuid from 'uuid-v4'
import SurveyTemplateSelect from 'Components/SurveyTemplate/Select/SurveyTemplateSelectContainer';
import Campaign from 'react-shared/api/Campaign';
import { useQuery, useApolloClient } from '@apollo/client';
import { Button, Snackbar, Paper, Box, Typography, TextField, useMediaQuery } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { 
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@material-ui/core'
import ContactNew from 'Components/Contact/New';
import ContactGroupSelect from 'Components/ContactGroup/Select';
import ContactSelect from 'Components/Contact/Select';
import Modal from "Components/Modal"
import Survey from "react-shared/api/Survey"
import SurveyTemplate from 'react-shared/api/SurveyTemplate';
import TagSelect from 'Components/Tag/Select';
import TagSurvey from 'react-shared/api/TagSurvey';
import { DateTimePicker } from "@material-ui/pickers";
import moment from 'moment'
import { AppBarContext } from 'gunner-react/web';
import { useLocation } from 'react-router-dom';
import ContactGroup from 'react-shared/api/ContactGroup';
import useContact from 'Hooks/useContact';
import { normalizePhoneNumber } from 'gunner-react';
import Contact from 'react-shared/api/Contact';

const validEmail = email =>
  /\S+@\S+\.\S+/.test(email ?? "")

const validPhoneNumber = phoneNumber =>
  normalizePhoneNumber(phoneNumber) !== null


const useQueryParms = () =>
    new URLSearchParams(useLocation().search)

const MemberSurveySendRoute = () => {
  const matches = useMediaQuery(theme => theme.breakpoints.up('md'));
  const queryParams = useQueryParms();
  const currentUser = useContext(CurrentUserContext);
  const queryCampaignId = queryParams.get("campaignId");
  const [doSubmit, setDoSubmit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [checkedForMissingContacts, setCheckedForMissingContacts] = useState(false);
  const [submittingContact, setSubmittingContact] = useState(false);
  const [includeNotice, setIncludeNotice] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [campaignId, setCampaignId] = useState(null);
  const [surveyTemplateId, setSurveyTemplateId] = useState(queryParams.get("surveyTemplateId"));
  const [contactGroupId, setContactGroupId] = useState(null);
  const [contactIds, setContactIds] = useState([]);
  const now = moment().toDate().toISOString();
  const [selectedDates, setSelectedDates] = useState([now]);
  const [addedContactId, setAddedContactId] = useState(null);
  const [tagIds, setTagIds] = useState([]);
  const [contactInfo, setContactInfo] = useState("");
  const {data: {getCampaign} = {}} = useQuery(Campaign.queries.get, {skip: !campaignId, variables: {id: campaignId}});
  const {data: {getSurveyTemplate} = {}} = useQuery(SurveyTemplate.queries.get, {skip: !surveyTemplateId, variables: {id: surveyTemplateId}});
  const {error: errorContactGroups, loading: loadingContactGroups, data: {listContactGroupsByOrganizationId: {items: contactGroups = []} = {}} = {}} = useQuery(ContactGroup.queries.listByOrganizationId, {
    skip: !currentUser.userOrganizationId, 
    pollInterval: 5000,
    variables: {
      limit: 1000,
      contactGroupOrganizationId: currentUser.userOrganizationId,
      filter: {
        name: {
          eq: "Hidden"
        }
      }
    }
  });
  const client = useApolloClient();
  // const [contact, setContact] = useContact({organizationId: currentUser.userOrganizationId, phone: contactInfo, email: contactInfo});
  const contacts = useContact({organizationId: currentUser.userOrganizationId, contactInfo});
  const hiddenContactGroup = contactGroups[0];
  const contactInfoValid = contactInfo.split(",").map(line => line.trim()).every(line => validPhoneNumber(line) || validEmail(line))

  const addContact = contact => 
    setContactIds([
      ...[contact.id],
      ...contactIds
    ])

  const appBar = useContext(AppBarContext);

  useEffect(() => {
    !loadingContactGroups &&
    !hiddenContactGroup &&
    client.mutate({
      mutation: ContactGroup.mutations.create,
      refetchQueries: [
        {
          query: ContactGroup.queries.listByOrganizationId,
          variables: {
            limit: 1,
            contactGroupOrganizationId: currentUser.userOrganizationId,
            filter: {
              name: {
                eq: "Hidden"
              }
            }
          }
        }
      ],
      variables: { input: {
        name: "Hidden",
        contactGroupOrganizationId: currentUser.userOrganizationId,
        adminGroup: `${currentUser.userOrganizationId}-Admins`,
        memberGroup: `${currentUser.userOrganizationId}-Members`
      }}
    })
      .then(console.log)
      .catch(console.log)
  }, [!!currentUser, loadingContactGroups, !!hiddenContactGroup])

  useEffect(() => {
    !!queryCampaignId && setCampaignId(queryCampaignId)
  }, [queryCampaignId])

  useEffect(() => {
    appBar.setTitle("Send Surveys")

    return appBar.reset
  }, [])

  useEffect(() => {
    !!getSurveyTemplate &&
    setIncludeNotice(!!getSurveyTemplate.notice)
  }, [getSurveyTemplate?.id]);

  useEffect(() => {

    // !!doSubmit &&
    // !contactIds?.length &&
    // !!contacts?.length &&
    // setTimeout(() => setContactIds(contacts.map(contact => contact.id)), 1000)
    

    !!doSubmit &&
    !contactIds?.length &&
    !!contacts?.length &&
    setTimeout(() => setContactGroupId(hiddenContactGroup.id), 1000)
    

    !!doSubmit &&
    !contactIds?.length &&
    !!contactInfo &&
    !checkedForMissingContacts &&
    Promise.all(
      contactInfo
        .split(",")
        .map(line => line.trim())
        .filter(line => validPhoneNumber(line) || validEmail(line))
        .map(line => validPhoneNumber(line) ? normalizePhoneNumber(line) : line)
        .filter(contactInfo => !contacts.find(contact => contact.email === contactInfo || contact.phone === contactInfo))
        .map(contactInfo =>
          console.log("contactInfo", contactInfo) ||
          client.mutate({
            mutation: Contact.mutations.create,
            variables: {
              input: {
                name: "Anonymous",
                email: validEmail(contactInfo) ? contactInfo : undefined,
                phone: validPhoneNumber(contactInfo) ? normalizePhoneNumber(contactInfo) : undefined,
                adminGroup: `${currentUser.userOrganizationId}-Admins`,
                memberGroup: `${currentUser.userOrganizationId}-Members`
              }
            }
          })
          .then(({data: {createContact}}) => createContact)
          .catch(console.log)
    ))
    .then(createdContacts => 
      console.log("IDS", uniqBy(item => item.id, [...contacts, ...createdContacts]).map(item => item.id)) ||
      setContactIds(uniqBy(item => item.id, [...contacts, ...createdContacts]).map(item => item.id)) || [
        setTimeout(() =>
          setContactGroupId(contactGroupId ?? hiddenContactGroup.id)
        , 500),
        setTimeout(() => [
          setCheckedForMissingContacts(true),
        ], 1000)
      ]
    )
    
  }, [doSubmit, JSON.stringify(contacts), !!hiddenContactGroup, !!contactIds?.length, !!currentUser, contactInfo])

  useEffect(() => {
    !!doSubmit &&
    !!contactGroupId &&
    (!!checkedForMissingContacts || !!contactIds.length) &&
    Promise.all((selectedDates).map(date => console.log("selectedDates", selectedDates) ||
      Promise.all(contactIds.map(contactId => //Create a survey for each contact
        console.log("CONTACT", contactId) ||
        Promise.resolve(uuid())
          .then(id =>
            Promise.all([
              ...[client.mutate({
                mutation: Survey.mutations.create,
                variables: {
                  input: {
                    id,
                    includeNotice,
                    surveyCampaignId: campaignId,
                    surveySurveyTemplateId: surveyTemplateId,
                    surveyContactId: contactId,
                    surveyContactGroupId: contactGroupId,
                    adminGroup: `${currentUser.userOrganizationId}-Admins`,
                    memberGroup: `${currentUser.userOrganizationId}-Members`,
                    sendAt: date
                  }
                }
              }).catch(console.log)],
              ...tagIds.map(tagId => //Create a tag <=> survey mapping for each tag
                client.mutate({
                  mutation: TagSurvey.mutations.create,
                  variables: {
                    input: {
                      tagId,
                      surveyId: id,
                      adminGroup: `${currentUser.userOrganizationId}-Admins`,
                      memberGroup: `${currentUser.userOrganizationId}-Members`
                    }
                  }
                }),  
              )
            ])
          )
      ))
    ))
    .then(() => [
      setContactIds([]),
      setContactInfo(""),
      setSubmitSuccess(true),
      setDoSubmit(false),
      setCheckedForMissingContacts(false),
    ])
    .catch(e => [
      console.log(e),
      setDoSubmit(false),
      setSubmitSuccess(false),
      setCheckedForMissingContacts(false),
      window.alert(e.message),
    ])
  // }, [doSubmit, !!contactIds.length])
  }, [doSubmit, !!contactIds.length, !!tagIds.length, !!currentUser.userOrganizationId, surveyTemplateId, campaignId, checkedForMissingContacts])

  return (
    <Box m={-2}>
      <Paper square>
        <Box component="form" p={2}>
          <Modal
            title="Create Contact"
            body={
              <ContactNew 
                onSubmitting={() => setSubmittingContact(true)} 
                organizationId={currentUser.userOrganizationId} 
                contactGroupId={contactGroupId} 
                onSuccess={contact => [addContact(contact), setShowModal(false), setSubmittingContact(false), setAddedContactId(contact.id)]}
              />
            }
            onClose={() => setShowModal(false)}
            submitting={submittingContact}
            opened={!!showModal}
            saveButton={false}
          />
          
          <CampaignSelect selectedCampaignId={campaignId} organizationId={currentUser.userOrganizationId} onChange={campaignId => setCampaignId(campaignId)} />

          {
            !!getCampaign &&
            <SurveyTemplateSelect campaignTemplateId={getCampaign.campaignCampaignTemplateId} onChange={surveyTemplateId => setSurveyTemplateId(surveyTemplateId)} />
          }

          {
            !!getSurveyTemplate &&
            !!getSurveyTemplate.notice &&
            currentUser.organization.planType === "FULL" &&
            <FormControl component="fieldset">
              <FormLabel component="legend">Survey Notice</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={!!includeNotice} onChange={({target: {checked}}) => setIncludeNotice(checked)} value="all" />}
                  label="Include Notice?"
                />
              </FormGroup>
              <FormHelperText>{getSurveyTemplate.notice}</FormHelperText>
            </FormControl>
          }
          
          {
            !!surveyTemplateId &&
            !contactInfo &&
            currentUser.organization.planType === "FULL" &&
            <ContactGroupSelect 
              organizationId={currentUser.userOrganizationId} 
              onChange={contactGroupId => setContactGroupId(contactGroupId)} 
            />
          }

          {
            !!contactGroupId &&
            !contactInfo &&
            currentUser.organization.planType === "FULL" &&
            <ContactSelect 
              reset={!!submitSuccess}
              addedContactId={addedContactId} 
              onAddContactClick={() => setShowModal(true)} 
              contactGroupId={contactGroupId} 
              onChange={contactIds => setContactIds(contactIds || [])} 
            />
          }

          {
            !!surveyTemplateId &&
            <TextField
              label={currentUser.organization.planType === "FULL" ? "Or enter an email address or phone number where this survey should be sent" : "Enter one or more email addresses and/or phone numbers, separated by a comma, where this survey should be sent."} 
              style={{flex: 1}}
              fullWidth
              onChange={(({target: {value}}) => setContactInfo(value))}
              error={!contactInfoValid && !!contactInfo}
              helperText={!contactInfoValid && !!contactInfo && "Must be a valid phone number or email address"}
            />
          }


          {
            (!!contactIds.length || (!!contactInfoValid && !!contactInfo)) &&
            currentUser.organization.planType === "FULL" &&
            <TagSelect organizationId={currentUser.userOrganizationId} onChange={tagIds => setTagIds(tagIds || [])} />
          }

          {
            (!!contactIds.length || (!!contactInfoValid && !!contactInfo)) &&
            currentUser.organization.planType === "FULL" &&
            <>
              <DateTimePicker
                value={null}
                disablePast
                onChange={value => setSelectedDates([...selectedDates, value.utc().toDate().toISOString()])}
                label="Pick Days and Times to Send"
                showTodayButton
                fullWidth
                helperText="Choose one or more dates to send the survey. You must have at least one date selected. By default, the survey will be sent now."
              />
              <FormControl component="fieldset">
                <FormGroup>
                  {
                    selectedDates.map(date => 
                      <FormControlLabel
                        key={date}
                        disabled={selectedDates.length === 1}
                        control={
                          <Checkbox 
                            checked={selectedDates.includes(date)} 
                            onChange={({target: {checked, value}}) => !checked && setSelectedDates(selectedDates.filter(d => d !== value))} 
                            value={date} 
                          />
                        }
                        label={date <= now ? "Now" : moment(date).utcOffset(-1*(new Date().getTimezoneOffset())).format("dddd, MMMM Do YYYY, h:mm a")}
                      />
                    )
                  }
                </FormGroup>
              </FormControl>
            </>
          }

          {
            (!!contactIds.length || (!!contactInfoValid && !!contactInfo)) &&
            <Box>
              <Button fullWidth disabled={!!doSubmit} onClick={() => console.log("HI") || setDoSubmit(true)} variant="contained" color="secondary">
                {
                  !!doSubmit ? "Sending Surveys. Please Wait..." : "Send"
                }
              </Button>
            </Box>
          }

          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={!!submitSuccess}
            autoHideDuration={6000}
            onClose={() => setSubmitSuccess(null)}  
          >
            <Alert onClose={() => setSubmitSuccess(null)} elevation={6} variant="filled" severity="success">
              <AlertTitle>Surveys Sent!</AlertTitle>
              Feel free to use the form above to send more.
            </Alert>
          </Snackbar>
        </Box>
      </Paper>
    </Box>
  )
}

export default MemberSurveySendRoute;