import React, { useState, useContext, useEffect } from 'react'
import FileReaderInput from 'react-file-reader-input';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { uniqBy } from 'ramda'

import { normalizePhoneNumber } from "gunner-react"
import { Button, TableFooter } from '@material-ui/core';
import { CurrentUserContext } from 'gunner-react';
import { useQuery, useApolloClient } from '@apollo/client';
import ContactGroup from 'react-shared/api/ContactGroup';
import Contact from 'react-shared/api/Contact';
import ContactContactGroup from 'react-shared/api/ContactContactGroup';



export default ({contactGroupId, doSubmit, onSubmitSuccess, onError = err => err}) => {
  const currentUser = useContext(CurrentUserContext);

  const [contacts, setContacts] = useState([]);
  const [missingGroupNames, setMissingGroupNames] = useState([]);
  const [missingContacts, setMissingContacts] = useState([]);
  const client = useApolloClient()

  const contactGroupQuery = {
    query: ContactGroup.queries.listByOrganizationId,
    variables: {
      contactGroupOrganizationId: currentUser.userOrganizationId,
      limit: 200
    }
  }
  const contactQuery = {
    query: Contact.queries.listByAdminGroup,
    variables: {
      adminGroup: `${currentUser.userOrganizationId}-Admins`,
      limit: 500
    }
  }

  const {error, data: {getContactGroup} = {}} = useQuery(ContactGroup.queries.get, {
    skip: !contactGroupId,
    variables: {
      id: contactGroupId
    }
  })

  const {data: {listContactGroupsByOrganizationId: {items: groups = []} = {}} = {}} = useQuery(contactGroupQuery.query, {
    variables: contactGroupQuery.variables
  })

  const {data: {listContactsByAdminGroup: {items: savedContacts = []} = {}} = {}} = useQuery(contactQuery.query, {
    variables: contactQuery.variables
  })


  const handleFiles = (e, files) =>
    setContacts(
      files.map(file => 
        file[1].type.toLowerCase() === 'text/csv' ||
        file[1].type.toLowerCase() === 'text/plain' ||
        file[1].type.toLowerCase() === 'application/vnd.ms-excel'
        ? (
          file[0].target.result.replace( /[\n\r]/g, "^~^" ).split("^~^")
            .map(line => line.split(","))
            .map(([name, phone, email, group]) => ({name, phone: normalizePhoneNumber(phone), email: !!(email||"").match(/@/) ? email : null, group: (getContactGroup?.name ?? group)}))
            .filter(contact => !!contact.name && (!!contact.email || !!contact.phone) && contact.group)
          ) : (
            window.alert(`You can only upload csv files. You uploaded ${file[1].type}`) || []
          )
        )
        .flat(1)
    )

  useEffect(() => {
    setMissingGroupNames(
      uniqBy(name => name,
        contacts
          .map(contact => contact.group)
          .filter(groupName => !groups.find(group => group.name.toLowerCase() === groupName.toLowerCase()))
      )
    )
  }, [JSON.stringify(contacts), JSON.stringify(groups)])

  useEffect(() => {
    setMissingContacts(
      contacts
        .filter(contact => !savedContacts.find(savedContact => (!!contact.email && savedContact.email.toLowerCase() === contact.email.toLowerCase()) || (!!contact.phone && savedContact.phone === contact.phone) ))
      )
  }, [JSON.stringify(contacts), JSON.stringify(savedContacts)])

  useEffect(() => {
    !!doSubmit &&
    Promise.all([
      ...missingGroupNames.map(name =>
        client.mutate({
          mutation: ContactGroup.mutations.create,
          variables: {
            input: {
              name,
              contactGroupOrganizationId: currentUser.userOrganizationId,
              adminGroup: `${currentUser.userOrganizationId}-Admins`,
              memberGroup: `${currentUser.userOrganizationId}-Members`,
            }
          },
          refetchQueries: [contactGroupQuery],
          // awaitRefetchQueries: true
        })
          .catch(err => [onError(err), console.log(err)])
      ),
      ...missingContacts.map(contact =>
        client.mutate({
          mutation: Contact.mutations.create,
          variables: {
            input: {
              name: contact.name,
              email: !!contact.email ? contact.email.toLowerCase() : undefined,
              phone: !!contact.phone ? contact.phone : undefined,
              adminGroup: `${currentUser.userOrganizationId}-Admins`,
              memberGroup: `${currentUser.userOrganizationId}-Members`,
            }
          },
          refetchQueries: [contactQuery],
          // awaitRefetchQueries: true
        })
          .catch(err => [onError(err), console.log(err)])
      ),
    ])
      .then(() => 
        Promise.all([
          client.query({...contactQuery, fetchPolicy: "network-only"}).then(({data: {listContactsByAdminGroup: {items}}}) => items),
          client.query({...contactGroupQuery, fetchPolicy: "network-only"}).then(({data: {listContactGroupsByOrganizationId: {items}}}) => items)
        ])
          .then(([savedContacts, groups]) =>
            Promise.all(
              contacts
                .map(contact => ({
                  contact: savedContacts.find(savedContact => (!!contact.email && savedContact.email.toLowerCase() === contact.email.toLowerCase()) || (!!contact.phone && savedContact.phone === contact.phone) ),
                  group: groups.find(savedGroup => savedGroup.name.toLowerCase() === contact.group.toLowerCase())
                }))
                .filter(({contact, group}) => !!contact && !contact.contactContactGroups.items.find(ccg => ccg.contactGroupId === group.id)) // Don't add them to the group if they're already in tehre
                .map(({contact, group}) => 
                  client.mutate({
                    mutation: ContactContactGroup.mutations.create,
                    variables: {
                      input: {
                        contactId: contact.id,
                        contactGroupId: group.id,
                        adminGroup: `${currentUser.userOrganizationId}-Admins`,
                        memberGroup: `${currentUser.userOrganizationId}-Members`,
                      }
                    }
                  })
                    .catch(err => [onError(err), console.log(err)])
                )
            )   
          )
      )
        .then(onSubmitSuccess)
  }, [doSubmit])


  return (
    <>
      <FileReaderInput 
        as="binary" 
        onChange={handleFiles}
      >
        <Button variant="contained" color="primary">Select a File</Button>
      </FileReaderInput>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Email</TableCell>
            {
              !contactGroupId &&
              <TableCell>Group</TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts.map((contact, i) =>
            <TableRow key={i}>
              <TableCell  component="th" scope="row">
                {contact.name}
              </TableCell>
              <TableCell>{contact.phone}</TableCell>
              <TableCell>{contact.email}</TableCell>
              {
                !contactGroupId &&
               <TableCell>{contact.group}</TableCell>
              }
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={!contactGroupId ? 4 : 3}>Please use this exact format when uploading your CSV file.</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </>
  )
}