
import User from '../api/User'
import { useQuery } from '@apollo/client'

export default ({cognitoUser}) => {
  const groups = cognitoUser?.signInUserSession?.accessToken?.payload?.['cognito:groups'] ?? [];
  const isOrganizationMember = groups.find(group => /Members/.test(group));
  const isOrganizationAdmin = groups.find(group => /Admins/.test(group));

  const { loading, error, data: {listUsersByEmail: {items: users} = {}} = {}} = useQuery(User.queries.listByEmail, {
    skip: !cognitoUser?.attributes?.email,
    variables: {
      email: cognitoUser?.attributes?.email,
      limit: 1
    },
    pollInterval: 3000
  });

  const user = !!users && Array.isArray(users) ? users[0] : users;

  return { 
    loading, 
    error, 
    user: !user ? user : ({
      ...user,
      isOrganizationMember, 
      isOrganizationAdmin,
      groups 
    })
  }

}