import React from 'react'
import { Typography, Box, Button } from '@material-ui/core'
import ResponseCommentButton from '../../Response/CommentButton';

const Option = ({option, selected, onSelect}) =>
  <Box onClick={() => onSelect(option)} ml={1} p={selected ? 1 : 0} style={{backgroundColor: selected ? "purple" : "transparent", borderRadius: 16}} flex={1}>
    <img style={{maxWidth: "100%", height: "auto"}} src={option.iconUrl || require(`../../../assets/images/survey/${option.position}.png`)} />
  </Box>

export default ({survey, prompt, onSelect}) => {
  const selectedOptionId = prompt
    .options
    .items
    .map(option => option.id)
    .flat(1)
    .find(optionId => survey.responses.items.map(response => response.responseOptionId).includes(optionId));

  const selectedResponse = survey.responses.items.find(response => response.responseOptionId === selectedOptionId)

  return (
    <Box mb={2}>
      <Box component={Typography} mb={1} variant="h3">{prompt.body}</Box>
      <Box display="flex" ml={-1}>
        {
          prompt.options.items.slice().sort((a,b) => a.position < b.position ? -1 : 1).map(option => 
            <Option 
              onSelect={onSelect}
              key={option.id} 
              option={option} 
              prompt={prompt} 
              survey={survey} 
              selected={survey.responses.items.find(response => response.responseOptionId === option.id)}
            />
          )
        }
      </Box>
      {
        selectedOptionId &&
        <ResponseCommentButton id={selectedResponse?.id} />
      }
    </Box>   
  )
}