import gql from 'graphql-tag'
import Fragments from './Fragments'

const User = {
  mutations: {
    acceptInvitation: gql`
      mutation AcceptInvitation($input: AcceptInvitationInput!) {
        acceptInvitation(input: $input) {
          ...UserPublic
        }
      }
      ${Fragments.User.public}
    `,
    changePassword: gql`
      mutation ChangePassword($input: ChangePasswordInput!) {
        changePassword(input: $input) {
          ...UserPublic
        }
      }
      ${Fragments.User.public}
    `,
    update: gql`
      mutation UpdateUser($input: UpdateUserInput!) {
        updateUser(input: $input) {
          ...UserPublic
        }
      }
      ${Fragments.User.public}
    `,
    create: gql`
      mutation CreateUser($input: CreateUserInput!) {
        createUser(input: $input) {
          ...UserPublic
        }
      }
      ${Fragments.User.public}
    `,
    createForOrganization: gql`
      mutation CreateOrganizationUser($input: CreateOrganizationUserInput!) {
        createOrganizationUser(input: $input) {
          ...UserPublic
        }
      }
      ${Fragments.User.public}
    `,
    updateAdminStatus: gql`
      mutation UpdateUserAdminStatus($input: UpdateUserAdminStatusInput!) {
        updateUserAdminStatus(input: $input) {
          ...UserPublic
        }
      }
      ${Fragments.User.public}
    `
  },
  subscriptions: {
    onUpdate: gql`
      subscription OnUpdateUser($id: String) {
        onUpdateUser(id: $id) {
          ...UserPublic
        }
      }
      ${Fragments.User.public}
    `
  },
  queries: {
    getWithCognitoData: gql`
      query GetUserWithCognitoData(
        $id: ID!
      ) {
        getUserWithCognitoData(
          id: $id
        ) {
          ...UserPublic
        }
      }
      ${Fragments.User.public}
    `,
    listByOrganizationId: gql`
      query ListUsersByOrganizationId(
        $userOrganizationId: ID
        $updatedAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelUserFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listUsersByOrganizationId(
          userOrganizationId: $userOrganizationId
          updatedAt: $updatedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...UserPublic
          }
          nextToken
        }
      }
      ${Fragments.User.public}
    `,
    get: gql`
      query GetUser($id: ID!) {
        getUser(id: $id) {
          ...UserPublic
        }
      }
      ${Fragments.User.public}
    `,
    listByEmail: gql`
      query ListUsersByEmail(
        $email: AWSEmail
        $updatedAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelUserFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listUsersByEmail(
          email: $email
          updatedAt: $updatedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...UserPublic
          }
          nextToken
        }
      }
      ${Fragments.User.public}
    `,
    list: gql`
      query ListUsers(
        $filter: ModelUserFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            ...UserPublic
          }
          nextToken
        }
      }
      ${Fragments.User.public}
    `
  }
}

export default User