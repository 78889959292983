import React, {useState, useEffect} from 'react'
import { useParams, Link, useLocation } from 'react-router-dom'
import { Breadcrumbs, Typography } from '@material-ui/core'
import { useQuery } from '@apollo/client';
import SurveyTemplate from 'react-shared/api/SurveyTemplate';
import CampaignTemplate from 'react-shared/api/CampaignTemplate';
import Campaign from 'react-shared/api/Campaign';
import Survey from 'react-shared/api/Survey';
import Prompt from 'react-shared/api/Prompt';
import ContactGroup from 'react-shared/api/ContactGroup';
import Contact from 'react-shared/api/Contact';
import Tag from 'react-shared/api/Tag';

const useQueryParms = () =>
  new URLSearchParams(useLocation().search)

const NavigationBreadcrumbsContainer = () => {
  const params = useParams();
  const queryParams = useQueryParms();
  const {pathname} = useLocation();

  const {data: {getPrompt} = {}} = useQuery(Prompt.queries.get, {skip: !params.promptId, variables: {id: params.promptId}})

  const tagId = params.tagId || queryParams.get("tagId");
  const {data: {getTag} = {}} = useQuery(Tag.queries.get, {skip: !tagId, variables: {id: tagId }})

  const surveyTemplateId = params.surveyTemplateId || queryParams.get("surveyTemplateId") || getPrompt?.promptSurveyTemplateId;
  const {data: {getSurveyTemplate} = {}} = useQuery(SurveyTemplate.queries.get, {skip: !surveyTemplateId, variables: {id: surveyTemplateId }})
  const {data: {getCampaignTemplate} = {}} = useQuery(CampaignTemplate.queries.get, {skip: !params.campaignTemplateId && !getSurveyTemplate?.surveyTemplateCampaignTemplateId, variables: {id: params.campaignTemplateId || getSurveyTemplate?.surveyTemplateCampaignTemplateId}})
  
  const {data: {getSurvey} = {}} = useQuery(Survey.queries.get, {skip: !params.surveyId, variables: {id: params.surveyId}})
  const campaignId = params.campaignId ?? queryParams.get("campaignId") ?? getSurvey?.surveyCampaignId;
  const {data: {getCampaign} = {}} = useQuery(Campaign.queries.get, {skip: !campaignId, variables: {id: campaignId}})
  const {data: {getContact} = {}} = useQuery(Contact.queries.get, {skip: !params.contactId, variables: {id: params.contactId}})
  const {data: {getContactGroup} = {}} = useQuery(ContactGroup.queries.get, {skip: !params.contactGroupId && !queryParams.get("contactGroupId"), variables: {id: params.contactGroupId || queryParams.get("contactGroupId")}})
  
  
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {
        !!getTag &&
        <Link color="inherit" to="/tags">
          Tags
        </Link>
      }

      {
        !!getTag &&
        !!getContact &&
        <Link color="inherit" to={`/tags/${getTag.id}/contacts`}>
          {getTag.name}
        </Link>
      }

      {
        !!getTag &&
        !!getSurvey &&
        <Link color="inherit" to={`/tags/${getTag.id}/surveys`}>
          {getSurvey.surveyTemplate.name}
        </Link>
      }

      {
        !!getTag &&
        !getContact &&
        !getSurvey &&
        <Typography color="textPrimary">{getTag.name}</Typography>
      }

      {
        !!getContactGroup &&
        <Link color="inherit" to="/contact-groups">
          Contact Groups
        </Link>
      }

      {
        !getContactGroup &&
        !!getContact &&
        !getTag &&
        <Link color="inherit" to="/contact-groups">
          Contact Groups
        </Link>
      }

      {
        !getContactGroup &&
        !!getContact &&
        <Typography color="textPrimary">{getContact.name} - Surveys</Typography>
      }

      {
        !!getContactGroup &&
        !getContact &&
        !/surveys/.test(pathname) &&
        <Typography color="textPrimary">{getContactGroup.name}</Typography>
      }

      {
        !!getContactGroup &&
        !getContact &&
        !!/surveys/.test(pathname) &&
        <Link color="inherit" to={`/contact-groups/${getContactGroup.id}/contacts`}>
          {getContactGroup.name}
        </Link>
      }

      {
        !!getContactGroup &&
        !getContact &&
        !!/surveys/.test(pathname) &&
        <Typography color="textPrimary">Surveys</Typography>
      }

      {
        !!getContactGroup &&
        !!getContact &&
        <Link color="inherit" to={`/contact-groups/${getContactGroup.id}/contacts`}>
          {getContactGroup.name}
        </Link>
      }

      {
        !!getContactGroup &&
        !!getContact &&
        <Typography color="textPrimary">
          {getContact.name}
        </Typography>
      }

      {
        !!getCampaign &&
        <Link color="inherit" to="/campaigns">
          Campaigns
        </Link>
      }
      {
        !!getCampaignTemplate &&
        <Link color="inherit" to="/campaign-templates">
          Manage Campaigns
        </Link>
      }
      {
        !!getCampaign &&
        !getSurvey &&
        <Typography color="textPrimary">{getCampaign.name}</Typography>
      }

      {
        !!getCampaign &&
        !!getSurvey &&
        <Link color="inherit" to={`/campaigns/${getCampaign.id}/surveys`}>
          {getCampaign.name}
        </Link>
      }

      {
        !!getCampaign &&
        !!getSurvey &&
        <Typography color="textPrimary">{getSurvey.surveyTemplate.name} - {getSurvey.contact.name}</Typography>
      }

      {
        !!getCampaignTemplate &&
        !getSurveyTemplate &&
        <Typography color="textPrimary">{getCampaignTemplate.name}</Typography>
      }
      {
        !!getCampaignTemplate &&
        !!getSurveyTemplate &&
        <Link color="inherit" to={`/campaign-templates/${getCampaignTemplate.id}/survey-templates`}>
          {getCampaignTemplate.name}
        </Link>
      }
      {
        !!getSurveyTemplate &&
        !getPrompt &&
        <Typography color="textPrimary">{getSurveyTemplate.name}</Typography>
      }
      {
        !!getSurveyTemplate &&
        !!getPrompt &&
        <Link color="inherit" to={`/survey-templates/${getSurveyTemplate.id}/prompts`}>
          {getSurveyTemplate.name}
        </Link>
      }
      {
        !!getSurveyTemplate &&
        !!getPrompt &&
        <Typography color="textPrimary">{getPrompt.body}</Typography>
      }
    </Breadcrumbs>
  )
}

export default NavigationBreadcrumbsContainer