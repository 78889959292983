/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "3a1a569253fc4b10a4467039e7114c49",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "3a1a569253fc4b10a4467039e7114c49",
            "region": "us-east-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://o4hjnuqctj.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://y2lfueahezbezoweebexadt4ze.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:b3e55464-e4d3-4a5d-b0e1-3fcd291d28c2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_T0SVSIvDw",
    "aws_user_pools_web_client_id": "9sfu99kn93lh62js878u2celt",
    "oauth": {
        "domain": "simplisurveyv22dbfa65d-2dbfa65d-production.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/oauth/,https://qualis.com/oauth/,https://qualisweb.com/oauth/",
        "redirectSignOut": "http://localhost:3000/oauth/signout/,https://qualis.com/oauth/signout/,https://qualisweb.com/oauth/signout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "simplisurveyv2ed40adbe65374b6196afd96b6909e0b2130207-production",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
