import React, { useState, useEffect, useContext } from 'react';
import { Button, Paper, IconButton } from '@material-ui/core'

import InvitationList from 'Components/Invitation/List';
import InvitationNew from 'Components/Invitation/New';
import Modal from 'Components/Modal';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SendIcon from '@material-ui/icons/Send';
import HelpIcon from '@material-ui/icons/Help';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Typography } from '@material-ui/core';
import { AppBarContext } from 'gunner-react/web';
import { useHasVisitedScreen } from 'gunner-react/web';



const AdminInvitationListRoute = () => {
  const [showModal, setShowModal] = useState(false);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(!useHasVisitedScreen());

  const appBar = useContext(AppBarContext);

  useEffect(() => {
    appBar.setTitle("Invitations");
    appBar.setOnClickHelp(() => () => setShowHelpModal(true))
    appBar.setRightButtons([
      <IconButton
        aria-label="add"
        aria-haspopup="true"
        onClick={() => setShowModal(true)}
        color="inherit"
      >
        <AddCircleOutlineIcon />
      </IconButton>
    ])

    return appBar.reset
  }, [])

  return (
    <>
      {/* <Button color="secondary" variant="contained" onClick={() => setShowModal(true)}>Send Invite</Button> */}
      <Paper square>
        <InvitationList doRefetch={!showModal} />
      </Paper>

      <Modal
        title="Send Invitation"
        body={
          <InvitationNew onSuccess={() => setShowModal(false)} />
        }
        onClose={() => setShowModal(false)}
        submitting={false}
        opened={!!showModal}
        saveButton={false}
      />


      <Modal
        title="Help"
        body={
          <>
            <Typography paragraph>This screen will let you send a new invitation using a valid mobile number or email address.</Typography>
            <Typography paragraph>The recipient will get an invitation to join your organization.</Typography>
            <Typography paragraph>You will be able to see the status of that invite and resend it if necessary.</Typography>
          </>
        }
        onClose={() => setShowHelpModal(false)}
        submitting={false}
        opened={!!showHelpModal}
        saveButton={false}
      />

      {/* <SpeedDial
        ariaLabel="SpeedDial"
        icon={<SpeedDialIcon />}
        onClose={() => setSpeedDialOpen(false)}
        onOpen={() => setSpeedDialOpen(true)}
        open={speedDialOpen}
        direction={'up'}
      >
        <SpeedDialAction
          icon={<HelpIcon />}
          tooltipTitle={"Help"}
          onClick={() => setShowHelpModal(true)}
          tooltipOpen
        />
        <SpeedDialAction
          icon={<SendIcon />}
          tooltipTitle={"Send Invite"}
          onClick={() => setShowModal(true)}
          tooltipOpen
        />
      </SpeedDial> */}
    </>
  )
}


export default AdminInvitationListRoute; 
