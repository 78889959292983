import React, { useState, useEffect, useContext } from 'react';
import { useMutation, useApolloClient, useQuery } from '@apollo/client';


import { Form } from 'gunner-react/web';
import Prompt from 'react-shared/api/Prompt';
import Option from 'react-shared/api/Option';
import { withoutKeys } from 'gunner-react';
import { withoutBlanks } from 'gunner-react';
import { CurrentUserContext } from 'gunner-react';
import writeFragment from 'Util/writeFragment';
import uuid from 'uuid-v4';

const PromptFormContainer = ({
  prompt, 
  onSuccess = () => null, 
  onSubmitting = () => null, 
  onError = () => null,
  isNested = false, 
  onValidChange = () => null,
  forceSubmit = false
}) => {
  const [formData, setFormData] = useState(prompt || {});
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const currentUser = useContext(CurrentUserContext);
  const apolloClient = useApolloClient();
  const {loading, data: {listPromptsBySurveyTemplateId: {items: prompts = []} = {}} = {}} = useQuery(Prompt.queries.listBySurveyTemplateId, {
    variables: {
      promptSurveyTemplateId: prompt.promptSurveyTemplateId,
      limit: 100
    }
  })

  const fields = {
    body: {
      label: "Text",
      regex: /\w+/,
      required: true,
      type: 'text',
      errorMessage: "You are required enter text",
      helperText: `The text the survey taker will see to select a response for. i.e. "Rate your satisfaction with the meeting" or "How likely are you to return?"`
    },
    position: {
      label: "Position",
      regex: /\d+/,
      required: true,
      type: isNested ? 'hidden' : 'number',
      helperText: "When a respondent is taking this survey, what position should they see this prompt in?",
      options: [...Array(prompts.length+1).keys()].map(i => ({label: i+1, value: i+1})),
      value: value => formData.position ?? prompts.length+1
    },
  }

  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: field === 'isExpired' ? !!value : value
    })

  const newPromptId = uuid()
  const [_createPrompt] = useMutation(Prompt.mutations.create, {
    variables: {input: { 
      id: newPromptId,
      ...withoutBlanks(formData),
      adminGroup: `${currentUser.userOrganizationId}-Admins`,
      memberGroup: `${currentUser.userOrganizationId}-Members`
    }},
    optimisticResponse: {
      __typename: "Mutation",
      createPrompt: { 
        id: newPromptId,
        ...formData,
        __typename: "Prompt",
        adminGroup: `${currentUser.userOrganizationId}-Admins`,
        memberGroup: `${currentUser.userOrganizationId}-Members`
      }
    },
    refetchQueries: [
      {
        query: Prompt.queries.listBySurveyTemplateId, 
        variables: {
          promptSurveyTemplateId: prompt.promptSurveyTemplateId, 
          sortDirection: "ASC"
        }
      }
    ]
  });

  

  const [_updatePrompt] = useMutation(Prompt.mutations.update, {
    variables: {input: {
      ...withoutBlanks(withoutKeys(formData, ['__typename', 'options'])),
    }},
    optimisticResponse: {
      __typename: "Mutation",
      updatePrompt: { 
        ...formData,
        __typename: "Prompt"
      }
    },
    update: (proxy, {data: {updatePrompt}}) => writeFragment({client: proxy, data: updatePrompt, model: 'Prompt'})
  });


  useEffect(() => {
    !formData.position &&
    !loading &&
    setFormData({
      ...formData,
      position: prompts.length + 1
    })
  }, [loading, prompts.length, JSON.stringify(formData)])

  useEffect(() => {
    !!doSubmit && onSubmitting()

    !!doSubmit &&
    (!!formData.id ? _updatePrompt : _createPrompt)() //if this is an existing prompt, update it, otherwise, create it
      .then(({data: {[(!!formData.id ? "updatePrompt" : "createPrompt")]: prompt}}) => 
        (!formData.id ? ( ///IF THIS IS NEW, CREATE THE OPTIOSN FOR IT, TOO
          Promise.all([
            {name: "Extremely Satisfied", value: 100, position: 1},
            {name: "Satisfied", value: 75, position: 2},
            {name: "Neutral", value: 50, position: 3},
            {name: "Unsatisfied", value: 25, position: 4},
            {name: "Extremely Unsatisfied", value: 0, position: 5}
          ].map(({name, data, value, position}) => 
            apolloClient.mutate({
              mutation: Option.mutations.create,
              variables: {input: {
                value,
                name,
                position,
                optionPromptId: prompt.id,
                adminGroup: `${currentUser.userOrganizationId}-Admins`,
                memberGroup: `${currentUser.userOrganizationId}-Members`
              }}
            })
          ))   
        ) : (
          Promise.resolve(null)
        ))
        .then(() => [
          setSubmitSuccess(prompt),
          setDoSubmit(false)
        ])
      )
      .catch(e => [
        console.log(withoutBlanks(withoutKeys(formData, ['__typename', 'options']))),
        console.log(currentUser),
        console.log(e),
        setDoSubmit(false),
        setSubmitSuccess(false),
        window.alert(e.message),
        onError(e)
      ])
  }, [doSubmit]);

  useEffect(() => {
    !!submitSuccess &&
    onSuccess(submitSuccess)
  }, [submitSuccess])

  useEffect(() => {
    !!forceSubmit &&
    setDoSubmit(true)
  }, [forceSubmit])

  return (
    <Form
      isNested={!!isNested} 
      excludeButton={!!isNested}
      onValidChange={onValidChange}
      primaryButtonProps={{color: "primary", fullWidth: true}}
      submitting={!!doSubmit}
      fields={fields}
      onChange={handleChange}
      data={formData}
      onSubmit={() => setDoSubmit(true)}
      buttonLabel={"Submit"}
    />
  )
}

export default PromptFormContainer;