import React, { useContext, useEffect, useState } from 'react'
import SurveyDetail from 'Components/Survey/Detail'
import { LayoutContext } from 'gunner-react/web'
import { useMutation } from '@apollo/client';
import Survey from 'react-shared/api/Survey';
import Modal from "Components/Modal"


export default ({match: {params}}) => {
  const layout = useContext(LayoutContext);
  const [notice, setNotice] = useState(null);

  const [updateSurvey] = useMutation(Survey.mutations.update, {
    variables: {
      input: {
        id: params.id,
        lastSeenAt: (new Date()).toISOString()
      }
    }
  })

  useEffect(() => {
    layout.hideNav();
    updateSurvey()
      .then(({data: {updateSurvey}}) => 
        setNotice(!!updateSurvey?.includeNotice ? updateSurvey.surveyTemplate.notice : null)
      )
      .catch(console.log)
  }, [])

  return (
    <>
      <Modal
        title="Notice"
        body={notice}
        onClose={() => setNotice(null)}
        submitting={false}
        opened={!!notice}
        saveButton={false}
      />
      <SurveyDetail id={params.id} />
    </>
  )
}