import React from 'react';
import { useQuery } from '@apollo/client';
import Organziation from 'react-shared/api/Organization';
import { Typography, Box } from '@material-ui/core';

export default ({id}) => {

  const {error, data: {getOrganization = {}} = {}} = useQuery(Organziation.queries.get, {variables: {id}});
  const entry = useQuery(Organziation.queries.get, {variables: {id}});

  !!error && console.log(error);

  return (
    <>
      {
        !!getOrganization?.logoUrl &&
        <Box mb={2} display="flex" justifyContent="center">
          <img src={getOrganization?.logoUrl} style={{maxWidth: "100%", height: "auto"}} />
        </Box>  
      }
      <Typography variant={"h6"} align="center">
        {getOrganization.thankYouMessage || "Thank you for completing this survey!"}
      </Typography>
    </>
  )
}