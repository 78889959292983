import React, { useContext } from 'react'
import Tag from 'react-shared/api/Tag'
import { CurrentUserContext } from 'gunner-react'

import { Link } from 'react-router-dom'
import { DataTable } from 'gunner-react/web'
import TagEditButton from '../Edit/Button/TagEditContainer';
import MoreButtonContainer from '../../MoreButton';

const fields = 
  {
    "Name": {
      value: item => item.name,
      sort: order =>  (a, b) => a.name > b.name ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Surveys": {
      value: item => <Link to={`/tags/${item.id}/surveys`}>Surveys</Link>,
      sort: null,
      hideLabel: true,
    },
    "Contacts": {
      value: item => <Link to={`/tags/${item.id}/contacts`}>Contacts</Link>,
      sort: null,
      hideLabel: true,
    },
    "Actions": {
      hideLabel: true,
      sort: null,
      value: item => 
        <MoreButtonContainer> 
          <TagEditButton render="menut" id={item.id} />
        </MoreButtonContainer> 
    },
  }

export default ({}) => {
  const currentUser = useContext(CurrentUserContext);

  return (
    <>
      <DataTable
        fields={fields}
        search={null}
        dataListParams={{
          useButton: true,
          query: Tag.queries.listByAdminGroup,
          variables: {
            adminGroup: `${currentUser.userOrganizationId}-Admins`,
          },
          dataKey: "listTagsByAdminGroup",
        }}
      />
    </>
  )
}