
import React, { useState, useEffect } from 'react'
import SurveyResentButtonView from './SurveyResentButtonView'
import { useMutation, useQuery } from '@apollo/client'
import Survey from 'react-shared/api/Survey'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';



export default ({id, render = "button"}) => {
  const [showToast, setShowToast] = useState(false);
  const { data: {getSurvey} = {}} = useQuery(Survey.queries.get, {variables: {id}})
  const [updateSurvey, {data: {updateSurvey: updatedSurvey} = {}}] = useMutation(Survey.mutations.update, {
    skip: !getSurvey,
    variables: {
      input: {
        id: getSurvey?.id,
        sent: false
      }
    },
    refetchQueries: [
      {
        query: Survey.queries.listByCampaignId,
        variables: {
          limit: 100,
          surveyCampaignId: getSurvey?.surveyCampaignId
        }
      }
    ]
  });

  useEffect(() => {
    !!updatedSurvey?.updatedAt &&
    setShowToast(true)
  }, [updatedSurvey?.updatedAt])


  return (
    <>
      <SurveyResentButtonView onClick={updateSurvey} render={render} />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={!!showToast}
        autoHideDuration={6000}
        onClose={() => setShowToast(false)}  
      >
        <Alert onClose={() => setShowToast(false)} elevation={6} variant="filled" severity="success">
          <AlertTitle>Survey Sent!</AlertTitle>
          Your survey has been resent
        </Alert>
      </Snackbar>
    </>
  )
}