
import User from '../api/User'
import { useMutation } from '@apollo/client'

export default ({cognitoUser}) =>
  useMutation(User.mutations.create, {
    variables: {input: {
      id: cognitoUser?.username || '<>',
      name: cognitoUser?.attributes?.name ?? undefined,
      email: cognitoUser?.attributes?.email ?? undefined,
      phone: cognitoUser?.attributes?.phone ?? undefined,
      inactive: false
    }}
  });