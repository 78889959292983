import React, { useState } from 'react'
import { Button, MenuItem } from '@material-ui/core';
import ContactGroupEdit from '../';
import Modal from '../../../Modal';

const ContactGroupEditButtonContainer = ({id, render = "button"}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Modal
        title="Edit Contact Group"
        body={
          <ContactGroupEdit id={id} onSuccess={() => setShowModal(false)} />
        }
        onClose={() => setShowModal(false)}
        submitting={false}
        opened={!!showModal}
        saveButton={false}
      />
      {
        render === 'button' ? (
          <Button variant="contained" size="small" color="secondary" onClick={() => setShowModal(true)}>Edit</Button>
        ) : (
          <MenuItem onClick={() => setShowModal(true)}>Edit</MenuItem>
        )
      }
    </>
  )
}

export default ContactGroupEditButtonContainer