import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


import { CurrentUserContext } from 'gunner-react'

import { Paper, Tabs, Tab, Box } from '@material-ui/core';
import { Redirect, Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import CampaignTemplateList from 'Components/CampaignTemplate/List';
import CampaignTemplateNewButton from 'Components/CampaignTemplate/New/Button';
import CampaignList from 'Components/Campaign/List';
import Modal from 'Components/Modal';
import { AppBarContext } from 'gunner-react/web';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { IconButton } from '@material-ui/core';
import { useHasVisitedScreen } from 'gunner-react/web';

const useQueryString = () => {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles(theme => ({
  // speedDial: {
  //   position: 'absolute',
  //   '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
  //     bottom: theme.spacing(8),
  //     right: theme.spacing(2),
  //   },
  // },
}));


const CampaignRoute = () => {
  const currentUser = useContext(CurrentUserContext);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(!useHasVisitedScreen());
  const appBar = useContext(AppBarContext);

  useEffect(() => {
    appBar.setTitle("Campaigns")

    appBar.setOnClickHelp(() => () => setShowHelpModal(true))

    return appBar.reset;
  }, [])

  return (
    <Paper square>
      <CampaignList organizationId={currentUser.userOrganizationId} />
      <Modal
        title="Help"
        body={
          <>
            <Typography paragraph>This screen shows a list of all the campaigns you created from your templates.</Typography>
            <Typography paragraph>To see information about a campaign, click the name.</Typography>
            <Typography paragraph>To start a campaign from a template, or create a new template, go to your <Link to="/campaign-templates">templates screen</Link>.</Typography>
          </>
        }
        onClose={() => setShowHelpModal(false)}
        submitting={false}
        opened={!!showHelpModal}
        saveButton={false}
      />
      {/* <SpeedDial
        ariaLabel="SpeedDial"
        icon={<SpeedDialIcon />}
        onClose={() => setSpeedDialOpen(false)}
        onOpen={() => setSpeedDialOpen(true)}
        open={speedDialOpen}
        direction={'up'}
      >
        <SpeedDialAction
          icon={<HelpIcon />}
          tooltipTitle={"Help"}
          onClick={() => setShowHelpModal(true)}
          tooltipOpen
        />
      </SpeedDial> */}
    </Paper>
  )
}


export default CampaignRoute; 
