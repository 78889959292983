import React from 'react'
import { 
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  FormHelperText,
} from '@material-ui/core'

export default ({contacts, textFieldProps = {}, selectedContactIds, handleChange, onAddContactClick}) =>
  <FormControl component="fieldset" fullWidth>
    <FormLabel component="legend">Select Contacts</FormLabel>
    <Box component={FormGroup} maxHeight={200} overflow="auto">
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={selectedContactIds.length === contacts.length} onChange={handleChange} value="all" />}
        label="All"
      />
      {
        contacts.map(contact => 
          <FormControlLabel
            key={contact.id}
            control={<Checkbox checked={selectedContactIds.includes(contact.id)} onChange={handleChange} value={contact.id} />}
            label={contact.name}
          />
        )
      }
      </FormGroup>
    </Box>
    <FormHelperText>
      <Box onClick={onAddContactClick} component="a" color="secondary.main" style={{cursor: "pointer"}}>Click here to add a new contact</Box>
    </FormHelperText>
  </FormControl>
