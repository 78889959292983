import React, { useContext, useEffect } from 'react';

// import OrganziationEdit from 'Components/Organization/Edit';
import { Paper, } from '@material-ui/core';
import NavigationBreadcrumbsContainer from 'Components/Navigation/Breadcrumbs';
import ResponseList from 'Components/Response/List';
import { AppBarContext } from 'gunner-react/web';


export default ({match: {params}}) => {
  const appBar = useContext(AppBarContext);

  useEffect(() => {
    appBar.setTitle("Responses")

    return appBar.reset;
  }, [])

  return (
    <>
      <NavigationBreadcrumbsContainer />
      <Paper square>
        <ResponseList surveyId={params.surveyId} />
      </Paper>
    </>
  )
}

