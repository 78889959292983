import React, { useState, useContext, useEffect } from 'react';

import PromptList from 'Components/Prompt/List';
import { Paper, Button, Typography, Box } from '@material-ui/core';
import Modal from 'Components/Modal';
import PromptNew from 'Components/Prompt/New';
import NavigationBreadcrumbsContainer from 'Components/Navigation/Breadcrumbs';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HelpIcon from '@material-ui/icons/Help';
import { AppBarContext } from 'gunner-react/web';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { IconButton } from '@material-ui/core';
import { useHasVisitedScreen } from 'gunner-react/web';


const AdminPromptListRoute = ({match: {params}}) => {
  const [showModal, setShowModal] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(!useHasVisitedScreen());

  const appBar = useContext(AppBarContext);

  useEffect(() => {
    appBar.setTitle("Prompts");
    appBar.setOnClickHelp(() => () => setShowHelpModal(true))
    appBar.setRightButtons([
      <IconButton
        aria-label="add"
        aria-haspopup="true"
        onClick={() => setShowModal(true)}
        color="inherit"
      >
        <AddCircleOutlineIcon />
      </IconButton>
    ])

    return appBar.reset;
  }, [])

  return (
    <>
      <NavigationBreadcrumbsContainer />
      
      <Paper square>
        <PromptList surveyTemplateId={params.surveyTemplateId} />
      </Paper>

      <Modal
        title="Create Prompt"
        body={
          <PromptNew surveyTemplateId={params.surveyTemplateId} onSuccess={() => setShowModal(false)} onSubmitting={() => setFormSubmitting(true)} />
        }
        onClose={() => setShowModal(false)}
        submitting={false}
        opened={!!showModal}
        saveButton={false}
      />

      <Modal
        title="Help"
        body={
          <>
            <Typography paragraph>Add one or more prompts to your survey template.</Typography>
            <Typography paragraph>A prompt is a statement or question which survey takers will respond to.</Typography>
            <Typography paragraph>For example, "How satisfied were you with the presentation?" or "Tell us how satisfied you were with the presentation".</Typography>
            <Typography paragraph>The fewer the prompts, the more likely the survey taker is to complete the survey, so only create prompts for the most important aspects. You can always send a follow up survey.</Typography>
          </>
        }
        onClose={() => setShowHelpModal(false)}
        submitting={false}
        opened={!!showHelpModal}
        saveButton={false}
      />

      {/* <SpeedDial
        ariaLabel="SpeedDial"
        icon={<SpeedDialIcon />}
        onClose={() => setSpeedDialOpen(false)}
        onOpen={() => setSpeedDialOpen(true)}
        open={speedDialOpen}
        direction={'up'}
      >
        <SpeedDialAction
          icon={<HelpIcon />}
          tooltipTitle={"Help"}
          onClick={() => setShowHelpModal(true)}
          tooltipOpen
        />
        <SpeedDialAction
          icon={<AddCircleIcon />}
          tooltipTitle={"Add Prompt"}
          onClick={() => setShowModal(true)}
          tooltipOpen
        />
      </SpeedDial> */}
    </>
  )
}


export default AdminPromptListRoute; 
