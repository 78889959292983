import React from 'react';
import { useQuery } from '@apollo/client';
import User from 'react-shared/api/User';

const UserNameContainer = ({id}) => {
  const {error, data: {getUser = {}} = {}} = useQuery(User.queries.get, {variables: {id}});

  !!error && console.log(error);

  return (
    <>
      {getUser.name}
    </>
  )
}

export default UserNameContainer