import React from 'react'
import { useQuery } from '@apollo/client'
import SurveyTemplate from 'react-shared/api/SurveyTemplate'
import CampaignTemplate from 'react-shared/api/CampaignTemplate'
import SurveyTemplateEditButton from '../Edit/Button/SurveyTemplateEditContainer';
import MoreButtonContainer from '../../MoreButton';
import { Link } from 'react-router-dom'
import { DataTable } from 'gunner-react/web'

export default ({campaignTemplateId}) => {
  const { data: {getCampaignTemplate: campaignTemplate} = {}} = useQuery(CampaignTemplate.queries.get, {variables: {id: campaignTemplateId}});
  const fields = 
  {
    "Name": {
      value: item => <Link to={`/survey-templates/${item.id}/prompts`}>{item.name}</Link>,
      sort: order =>  (a, b) => a.name > b.name ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Description": {
      value: item => item.description,
      sort: order =>  (a, b) => a.description > b.description ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Position": {
      value: item => item.position,
      sort: order =>  (a, b) => a.position > b.position ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Edit": {
      hideLabel: true,
      sort: null,
      value: item => !!campaignTemplate?.public ? null :
        <MoreButtonContainer>
          <SurveyTemplateEditButton render="menu" id={item.id} />
        </MoreButtonContainer>
    },
  }

  return (
    <>
      <DataTable
        fields={fields}
        search={null}
        dataListParams={{
          useButton: true,
          query: SurveyTemplate.queries.listByCampaignTemplateId,
          variables: {
            surveyTemplateCampaignTemplateId: campaignTemplateId
          },
          dataKey: 'listSurveyTemplatesByCampaignTemplateId',
        }}
      />
    </>
  )
}


// const SurveyTemplateListContainer = ({campaignTemplateId, doRefetch}) => {
//   const { error, loading, refetch, data: {listSurveyTemplatesByCampaignTemplateId: {items: surveyTemplates = []} = {}} = {}} = useQuery(SurveyTemplate.queries.listByCampaignTemplateId, {
//     variables: {
//       surveyTemplateCampaignTemplateId: campaignTemplateId,
//       limit: 100,
//       sortDirection: "DESC"
//     }
//   });

//   const { data: {getCampaignTemplate} = {}} = useQuery(CampaignTemplate.queries.get, {variables: {id: campaignTemplateId}})

//   useEffect(() => {
//     !!doRefetch && refetch()
//   }, [doRefetch])

//   !!error && console.log(error)

//   return (
//     loading ? (
//       <CircularProgress color="secondary" />
//     ) : (
//       <SurveyTemplateListView campaignTemplate={getCampaignTemplate} surveyTemplates={surveyTemplates} />
//     )
//   )
// }

// export default SurveyTemplateListContainer;