import React, { useState, useEffect } from 'react'
import TagSelectView from './TagSelectView'
import { useQuery } from '@apollo/client'
import Tag from 'react-shared/api/Tag'

export default ({organizationId, onChange}) => {
  const [selectedTagIds, setSelectedTagIds] = useState([]);

  const { error, loading, refetch, data: {listTagsByAdminGroup: {items: tags = []} = {}} = {}} = useQuery(Tag.queries.listByAdminGroup, {
    variables: {
      adminGroup: `${organizationId}-Admins`,
      limit: 200
    }
  });

  const handleChange = ({target: {value, checked}}) =>
    !!checked ? (
      setSelectedTagIds(
        value === 'all' ? (
          tags.map(item => item.id)
        ) : (
          [
            ...[value],
            ...selectedTagIds
          ]
        )
      )
    ) : (
      setSelectedTagIds(
        value === 'all' ? (
          []
        ) : (
          selectedTagIds.filter(id => id !== value)
        )
      )
    )
    

  useEffect(() => {
    onChange(selectedTagIds)
  }, [JSON.stringify(selectedTagIds)])


  return (
    <TagSelectView 
      tags={tags} 
      selectedTagIds={selectedTagIds} 
      handleChange={handleChange}
    />
  )
}