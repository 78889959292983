import gql from 'graphql-tag'
import Fragments from './Fragments'

const Prompt = {
  mutations: {
    update: gql`
      mutation UpdatePrompt($input: UpdatePromptInput!) {
        updatePrompt(input: $input) {
          ...PromptPublic
        }
      }
      ${Fragments.Prompt.public}
    `,
    create: gql`
      mutation CreatePrompt($input: CreatePromptInput!) {
        createPrompt(input: $input) {
          ...PromptPublic
        }
      }
      ${Fragments.Prompt.public}
    `
  },
  subscriptions: {
    onUpdate: gql`
      subscription OnUpdatePrompt($promptUserId: String) {
        onUpdatePrompt(promptUserId: $promptUserId) {
          ...PromptPublic
        }
      }
      ${Fragments.Prompt.public}
    `,
    onCreate: gql`
      subscription OnCreatePrompt($promptUserId: String) {
        onCreatePrompt(promptUserId: $promptUserId) {
          ...PromptPublic
        }
      }
      ${Fragments.Prompt.public}
    `
  },
  queries: {
    listBySurveyTemplateId: gql`
      query ListPromptsBySurveyTemplateId(
        $promptSurveyTemplateId: ID
        $position: ModelIntKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelPromptFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listPromptsBySurveyTemplateId(
          promptSurveyTemplateId: $promptSurveyTemplateId
          position: $position
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...PromptPublic
          }
          nextToken
        }
      }
      ${Fragments.Prompt.public}
    `,
    get: gql`
      query GetPrompt($id: ID!) {
        getPrompt(id: $id) {
          ...PromptPublic
        }
      }
      ${Fragments.Prompt.public}
    `,
    list: gql`
      query ListPrompts(
        $filter: ModelPromptFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listPrompts(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            ...PromptPublic
          }
          nextToken
        }
      }
      ${Fragments.Prompt.public}
    `
  }
}

export default Prompt