import React from 'react'
import { TextField, MenuItem } from '@material-ui/core'

export default ({campaigns, textFieldProps = {}, selectedCampaignId, handleChange}) =>
  <TextField
    label="Select a Campaign"
    fullWidth
    {...textFieldProps}
    select
    value={selectedCampaignId || ""}
    onChange={handleChange}
  >
    {
      campaigns.map(campaign => 
        <MenuItem key={campaign.id} value={campaign.id}>
          {campaign.name}
        </MenuItem>
      )
    }
  </TextField>