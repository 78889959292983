import React from 'react'
import ContactGroup from 'react-shared/api/ContactGroup'
import { DataTable } from 'gunner-react/web'

import { Link } from 'react-router-dom'
import ContactCount from '../../Contact/Count'
import ContactGroupEditButton from '../Edit/Button/ContactGroupEditContainer';
import MoreButtonContainer from '../../MoreButton';

const fields = 
  {
    "Name": {
      value: item => <Link to={`/contact-groups/${item.id}/contacts`}>{item.name}</Link>,
      sort: order =>  (a, b) => a.name > b.name ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Contacts": {
      value: item => <ContactCount contactGroupId={item.id} /> ,
      sort: order =>  (a, b) => a.name > b.name ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Surveys": {
      value: item => <Link to={`/contact-groups/${item.id}/surveys`}>Surveys</Link>,
      sort: null,
      hideLabel: true,
    },
    "Edit": {
      hideLabel: true,
      sort: null,
      value: item => 
        <MoreButtonContainer>
          <ContactGroupEditButton render="menu" id={item.id} />
        </MoreButtonContainer>
    },
  }

export default ({organizationId}) => {
  return (
    <>
      <DataTable
        fields={fields}
        search={null}
        dataListParams={{
          clientFilter: cg => cg.name !== 'Hidden',
          useButton: true,
          query: ContactGroup.queries.listByOrganizationId,
          variables: {
            contactGroupOrganizationId: organizationId,
          },
          dataKey: "listContactGroupsByOrganizationId",
        }}
      />
    </>
  )
}