import React, { useContext, useEffect } from 'react';
import { Typography, List, ListItem, ListItemIcon, ListItemText, Box } from '@material-ui/core';
import { AppBarContext } from 'gunner-react/web';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HelpIcon from '@material-ui/icons/Help';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import LinkIcon from '@material-ui/icons/Link';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Link } from 'react-router-dom';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import { CurrentUserContext } from 'gunner-react';

export default () => {
  const appBar = useContext(AppBarContext);
  const currentUser = useContext(CurrentUserContext);

  useEffect(() => {
    appBar.setTitle("Welcome!")

    return appBar.reset
  }, [])

  return (
    <>
      <Typography paragraph>Thanks for signing up with SimpliSurvey.</Typography>
      <Typography paragraph>We've made getting started as easy as possible, hence the name, and have placed help menus throughout the site to guide you.</Typography>
      <Typography paragraph>Here are some quick tips to help you on your way:</Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary="Help" secondary="Most screens will have a help icon in the top right of the screen. Click or tap that if you need help understanding what that screen is for or what to do on that screen" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <AddCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Screen Actions" secondary="Next to the help icon, you will often see other actions such as 'Add' or 'Upload' in the top right of the screen." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <MoreHorizIcon />
          </ListItemIcon>
          <ListItemText primary="Contextual Actions" secondary="Within some of the tables, you may see the preceding icon. Click or tap that icon to reveal actions you may take on that item." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <SortByAlphaIcon />
          </ListItemIcon>
          <ListItemText primary="Sorting Data" secondary="Most table headers are clickable, which will change how the data is sorted. Click again to change the direction of the sort." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>
          <ListItemText primary="Links" secondary={<>Anywhere you see text that is <Box component="span" color="primary.main">this color</Box>, you can click it to navigate.</>} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <InsertDriveFileIcon />
          </ListItemIcon>
          <ListItemText primary="Forms" secondary={"When filling out forms, pay attention to the text underneath the form fields as they will explain what the field is for and give an example."} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <ErrorOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Buttons" secondary={"If a button is grayed out and doesn't respond to a click or tap, that likely means the form you're working on is not valid. When the form is valid, the button will light up."} />
        </ListItem>
      </List>

      <Typography paragraph>If you still can't figure something out or want help guiding you through setup, please reach out to us at <a href="mailto:support@simplisurvey.com">support@simplisurvey.com</a>.</Typography>

      {
        !currentUser ? (
          null
        ) : /Admins/.test(currentUser.groups.join(",")) ? (
          <Typography paragraph>Now that you're all caught up, head to the <Link to="/campaign-templates">Template Screen</Link> to start your first campaign!</Typography>
        ) : (
          <Typography paragraph>Now that you're all caught up, head to the <Link to="/surveys/send">Send Template Screen</Link> to send your first survey!</Typography>
        )
      }
    </>
  );
}