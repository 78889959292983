import React, { useState } from 'react'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { IconButton, Menu } from '@material-ui/core';

export default ({children}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={() => setAnchorEl(null) }
        onClick={() => setAnchorEl(null) }
      >
        {children}
      </Menu>
    </>
  )
}