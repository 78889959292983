import React from 'react';

import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';

import Form from "../Form";

import Response from 'react-shared/api/Response';



const ResponseEditContainer = ({id, type, data = {}, onSuccess = () => null, onSubmitting = () => null, onError = () => null}) => {

  const { error, loading, data: {getResponse: response} = {} } = useQuery(Response.queries.get, {variables: {id}});

  !!error && console.log(error)


  return (
    !!loading ? (
      <CircularProgress color="secondary" />
    ) : (
      <Form 
        type={type}
        organizationId={(response?.adminGroup ?? "").replace("-Admins", "")} 
        onSubmitting={onSubmitting} 
        onSuccess={onSuccess} 
        onError={onError} 
        response={{...response, ...data}}
      />
    )
  )
}

export default ResponseEditContainer;