import React, { useState, useEffect, useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import uuid from 'uuid-v4'

import { Form } from 'gunner-react/web';
import SurveyTemplate from 'react-shared/api/SurveyTemplate';
import { withoutKeys } from 'gunner-react';
import { withoutBlanks } from 'gunner-react';
import { CurrentUserContext } from 'gunner-react';
import writeFragment from 'Util/writeFragment';
import Prompt from 'react-shared/api/Prompt';
import { Typography, Box, Button } from '@material-ui/core';
import PromptForm from '../../Prompt/Form';

const SurveyTemplateFormContainer = ({
  surveyTemplate, 
  onSuccess = () => null, 
  onSubmitting = () => null, 
  onError = () => null, 
  isNested = false, 
  onValidChange = () => null,
  forceSubmit = false
}) => {
  const [newId] = useState(uuid());
  const [formData, setFormData] = useState(surveyTemplate || {});
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [newPromptCount, setNewPromptCount] = useState(!!formData.id ? 0 : 1);
  const [validations, setValidations] = useState({});
  const currentUser = useContext(CurrentUserContext);
  const [isValid, setIsValid] = useState(false);

  const handleValidChange = key => isValid =>
    setValidations({
      ...validations,
      [key]: isValid
    })

  const {loading, data: {listSurveyTemplatesByCampaignTemplateId: {items: surveyTemplates = []} = {}} = {}} = useQuery(SurveyTemplate.queries.listByCampaignTemplateId, {
    pollInterval: 3000,
    variables: {
      surveyTemplateCampaignTemplateId: surveyTemplate.surveyTemplateCampaignTemplateId,
      limit: 100
    }
  })

  const {data: {listPromptsBySurveyTemplateId: {items: prompts = []} = {}} = {}} = useQuery(Prompt.queries.listBySurveyTemplateId, {
    skip: !surveyTemplate?.id,
    variables: {
      promptSurveyTemplateId: surveyTemplate?.id,
      limit: 100,
    }
  })

  const fields = {
    name: {
      label: "Survey Name",
      regex: /\w+/,
      required: true,
      type: 'text',
      errorMessage: "You are required enter a name",
      helperText: `A unique name which can identify this survey within the campaign. i.e. "Pre Training" or "Post Training"`
    },
    description: {
      label: "Description",
      regex: /\w+/,
      required: false,
      type: 'text',
      helperText: "(Optional) Describe what this survey template is used for",
      rows: 4,
    },
    notice: {
      label: "Notice",
      regex: /\w+/,
      required: false,
      type: 'text',
      helperText: "(Optional) A note or addendum survey takers will see before taking the survey. i.e. 'Please do not fill out this survey if you were not in attendace'.",
      rows: 4,
    },
    position: {
      label: "Position",
      regex: /\d+/,
      required: true,
      type: isNested ? 'hidden' : 'number',
      helperText: "When your organization's users are viewing this campaign, what position should this survey appear in?",
      options: [...Array(surveyTemplates.length+1).keys()].map(i => ({label: i+1, value: i+1})),
      value: value => value || surveyTemplates.length+1
    },
  }

  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: field === 'isExpired' ? !!value : value
    })

  const [_createSurveyTemplate] = useMutation(SurveyTemplate.mutations.create, {
    variables: {input: { 
      ...withoutBlanks(formData),
      id: newId,
      adminGroup: `${currentUser.userOrganizationId}-Admins`,
      memberGroup: `${currentUser.userOrganizationId}-Members`
    }},
    optimisticResponse: {
      __typename: "Mutation",
      createSurveyTemplate: { 
        ...formData,
        id: newId,
        __typename: "SurveyTemplate",
        adminGroup: `${currentUser.userOrganizationId}-Admins`,
        memberGroup: `${currentUser.userOrganizationId}-Members`
      }
    }
  });

  

  const [_updateSurveyTemplate] = useMutation(SurveyTemplate.mutations.update, {
    variables: {input: {
      ...withoutBlanks(withoutKeys(formData, ['__typename'])),
    }},
    optimisticResponse: {
      __typename: "Mutation",
      updateSurveyTemplate: { 
        ...formData,
        __typename: "SurveyTemplate"
      }
    },
    update: (proxy, {data: {updateSurveyTemplate}}) => writeFragment({client: proxy, data: updateSurveyTemplate, model: 'SurveyTemplate'})
  });

  useEffect(() => {
    !formData.position &&
    !loading &&
    setFormData({
      ...formData,
      position: surveyTemplates.length + 1
    })
  }, [loading, surveyTemplates.length, JSON.stringify(formData)])

  useEffect(() => {
    console.log("WE submitting?", !!doSubmit)
    !!doSubmit && onSubmitting()
    
    !!doSubmit &&
    (!!formData.id ? _updateSurveyTemplate : _createSurveyTemplate)() //if this is an existing surveyTemplate, update it, otherwise, create it
      .then(({data: {[(!!formData.id ? "updateSurveyTemplate" : "createSurveyTemplate")]: surveyTemplate}}) => [
        setSubmitSuccess(surveyTemplate),
        setDoSubmit(false)
      ])
      .catch(e => [
        console.log(e),
        setDoSubmit(false),
        setSubmitSuccess(false),
        window.alert(e.message),
        onError(e)
      ])
  }, [doSubmit]);

  useEffect(() => {
    !!submitSuccess &&
    onSuccess(submitSuccess)
  }, [submitSuccess])

  useEffect(() => {
    !!forceSubmit &&
    setDoSubmit(true)
  }, [forceSubmit]);

  useEffect(() => {
    onValidChange(isValid && !Object.values(validations).some(value => !value))
  }, [isValid, Object.values(validations).some(value => !value)])

  return (
    <Form 
      isNested={!!isNested}
      onValidChange={valid => setIsValid(valid)}
      excludeButton={!!isNested}
      primaryButtonProps={{color: "primary", fullWidth: true}}
      submitting={!!doSubmit}
      fields={fields}
      onChange={handleChange}
      data={formData}
      onSubmit={() => setDoSubmit(true)}
      buttonLabel={"Submit"}
      NestedForm={
        <>
          {
            [...prompts, ...[...Array(newPromptCount).keys()]].map((prompt, i) => 
              <Box mt={2} mb={2} key={i}>
                <Typography variant="h5">Prompt #{i+1}</Typography>
                <PromptForm 
                  isNested={true} 
                  prompt={{...prompt, promptSurveyTemplateId: formData.id ?? newId}}
                  onValidChange={handleValidChange(i)}
                  forceSubmit={!!doSubmit}
                />
                {
                  !prompt?.id &&
                  newPromptCount > 1 &&
                  (i+1) === prompts.length + newPromptCount &&
                  <Button onClick={() => [setNewPromptCount(newPromptCount => newPromptCount - 1), setValidations({...validations, [Object.keys(validations).length-1]: true})]} color="primary" variant="outlined" fullWidth>Remove Prompt</Button>
                }
              </Box>
            )
          }
          <Button onClick={() => setNewPromptCount(newPromptCount => newPromptCount + 1)} color="primary" variant="outlined" fullWidth>Add Another Prompt</Button>
        </>
      }
    />
  )
}

export default SurveyTemplateFormContainer;