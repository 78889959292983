import React, { useContext } from 'react'

import {FormGroup, FormControlLabel, Switch} from '@material-ui/core'
import { useQuery, useMutation } from '@apollo/client'
import User from 'react-shared/api/User'
import { CurrentUserContext } from 'gunner-react'

export default ({id, user}) => {
  const currentUser = useContext(CurrentUserContext);
  const {error, data: {getUserWithCognitoData: getUser} = {}} = useQuery(User.queries.getWithCognitoData, {variables: {id}});
  const [updateUser] = useMutation(User.mutations.updateAdminStatus, {
    variables: {
      input: {
        id,
        admin: !(getUser||user).organizationAdmin
      }
    },
    refetchQueries: [
      {
        query: User.queries.getWithCognitoData,
        variables: {id}
      },
    ],
    optimisticResponse: {
      __typename: "Mutation",
      updateUser: {
        ...(user||getUser),
        organizationAdmin: !(getUser||user).organizationAdmin
      }
    }
  })

  return (
    <FormGroup>
      <FormControlLabel
        disabled={currentUser.id === user.id}
        control={<Switch size="small" checked={!!(getUser||user).organizationAdmin} onChange={updateUser} />}
        label={"Admin"}
      />
    </FormGroup>
  )
}