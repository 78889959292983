import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import { Form } from 'gunner-react/web';
import ContactGroup from 'react-shared/api/ContactGroup';
import { withoutKeys } from 'gunner-react';
import { withoutBlanks } from 'gunner-react';
import writeFragment from 'Util/writeFragment';

const fields = {
  name: {
    label: "Name",
    regex: /\w+/,
    required: true,
    type: 'text',
    errorMessage: "You are required enter a name"
  },
}

const ContactGroupFormContainer = ({organizationId, contactGroup, onSuccess = () => null, onSubmitting = () => null, onError = () => null}) => {
  const [formData, setFormData] = useState(contactGroup || {});
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: field === 'isExpired' ? !!value : value
    })

  const [_createContactGroup] = useMutation(ContactGroup.mutations.create, {
    variables: {input: { 
      ...withoutBlanks(formData),
      adminGroup: `${organizationId}-Admins`,
      memberGroup: `${organizationId}-Members`
    }},
    optimisticResponse: {
      __typename: "Mutation",
      createContactGroup: { 
        ...formData,
        __typename: "ContactGroup",
        adminGroup: `${organizationId}-Admins`,
        memberGroup: `${organizationId}-Members`
      }
    },
    refetchQueries: [
      {
        query: ContactGroup.queries.listByOrganizationId,
        variables: {
          contactGroupOrganizationId: organizationId,
        },
      }
    ]
  });

  

  const [_updateContactGroup] = useMutation(ContactGroup.mutations.update, {
    variables: {input: {
      ...withoutBlanks(withoutKeys(formData, ['__typename'])),
    }},
    optimisticResponse: {
      __typename: "Mutation",
      updateContactGroup: { 
        ...formData,
        __typename: "ContactGroup"
      }
    },
    update: (proxy, {data: {updateContactGroup}}) => writeFragment({client: proxy, data: updateContactGroup, model: 'ContactGroup'})
  });

  useEffect(() => {
    !!doSubmit && onSubmitting()

    !!doSubmit &&
    (!!formData.id ? _updateContactGroup : _createContactGroup)() //if this is an existing contactGroup, update it, otherwise, create it
      .then(({data: {[(!!formData.id ? "updateContactGroup" : "createContactGroup")]: contactGroup}}) => 
        setSubmitSuccess(true)
      )
      .catch(e => [
        console.log(e),
        setDoSubmit(false),
        setSubmitSuccess(false),
        window.alert(e.message),
        onError(e)
      ])
  }, [doSubmit]);

  useEffect(() => {
    !!submitSuccess &&
    onSuccess()
  }, [submitSuccess])

  return (
    <Form 
      primaryButtonProps={{color: "primary", fullWidth: true}}
      submitting={false}
      fields={fields}
      onChange={handleChange}
      data={formData}
      onSubmit={() => setDoSubmit(true)}
      buttonLabel={"Submit"}
    />
  )
}

export default ContactGroupFormContainer;