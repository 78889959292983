import React from 'react'
import TagContact from 'react-shared/api/TagContact'
import { Link } from 'react-router-dom'
import { DataTable } from 'gunner-react/web'
import ContactEditButton from '../../Contact/Edit/Button';
import ContactContactGroupDeleteButton from '../../ContactContactGroup/Delete/Button';
import ContactTagsButton from '../../Contact/Tags/Button';
import MoreButtonContainer from '../../MoreButton';

export default ({tagId}) => {
  const contactGroupId = '';
  const fields = 
  {
    "Name": {
      value: item => <Link to={`/contacts/${item.id}/surveys?contactGroupId=${contactGroupId||""}&tagId=${tagId}`}>{item.name}</Link>,
      sort: order =>  (a, b) => a.name > b.name ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Tags": {
      value: item => <>
        {(item.tagContacts?.items||[]).map(tc => <React.Fragment key={tc.id}><Link  to={`/tags/${tc.tagId}/contacts`}>{tc.tag.name}</Link>&nbsp;&nbsp;</React.Fragment>) }
      </>,
      sort: null,
    },
    "Email": {
      value: item => item.email,
      sort: order =>  (a, b) => a.email > b.email ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Phone": {
      value: item => item.phone,
      sort: order =>  (a, b) => a.phone > b.phone ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Actions": {
      hideLabel: true,
      sort: null,
      value: item => 
        <MoreButtonContainer>
          <ContactEditButton render="menu" id={item.id} />
          <ContactTagsButton render="menu" contactGroupId={contactGroupId} tagId={tagId} id={item.id} />
          {!!contactGroupId && <ContactContactGroupDeleteButton render="menu" contactId={item.id} contactGroupId={contactGroupId} />}
        </MoreButtonContainer>
    },
  }

  return (
    <>
      <DataTable
        fields={fields}
        search={null}
        dataListParams={{
          transform: items => items.map(tc => tc.contact),
          useButton: true,
          query: TagContact.queries.listByTagId,
          dataKey: "listTagContactsByTagId",
          variables: {
            tagId,
          }
        }}
      />
    </>
  )
}