import gql from 'graphql-tag'
import Fragments from './Fragments'

const TagContact = {
  mutations: {
    update: gql`
      mutation UpdateTagContact($input: UpdateTagContactInput!) {
        updateTagContact(input: $input) {
          ...TagContactPublic
        }
      }
      ${Fragments.TagContact.public}
    `,
    create: gql`
      mutation CreateTagContact($input: CreateTagContactInput!) {
        createTagContact(input: $input) {
          ...TagContactPublic
        }
      }
      ${Fragments.TagContact.public}
    `,
    delete: gql`
      mutation DeleteTagContact($input: DeleteTagContactInput!) {
        deleteTagContact(input: $input) {
          ...TagContactPublic
        }
      }
      ${Fragments.TagContact.public}
    `
  },
  subscriptions: {
    onUpdate: gql`
      subscription OnUpdateTagContact($id: String) {
        onUpdateTagContact(id: $id) {
          ...TagContactPublic
        }
      }
      ${Fragments.TagContact.public}
    `
  },
  queries: {
    listByContactId: gql`
      query ListTagContactsByContactId(
        $contactId: ID
        $tagId: ModelIDKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelTagContactFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listTagContactsByContactId(
          contactId: $contactId
          tagId: $tagId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...TagContactPublic
          }
          nextToken
        }
      }
      ${Fragments.TagContact.public}
    `,
    listByTagId: gql`
      query ListTagContactsByTagId(
        $tagId: ID
        $contactId: ModelIDKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelTagContactFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listTagContactsByTagId(
          tagId: $tagId
          contactId: $contactId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...TagContactPublic
          }
          nextToken
        }
      }
      ${Fragments.TagContact.public}
    `,
    get: gql`
      query GetTagContact($id: ID!) {
        getTagContact(id: $id) {
          ...TagContactPublic
        }
      }
      ${Fragments.TagContact.public}
    `,
    list: gql`
      query ListTagContacts(
        $filter: ModelTagContactFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listTagContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            ...TagContactPublic
          }
          nextToken
        }
      }
      ${Fragments.TagContact.public}
    `
  }
}

export default TagContact