import React, { useContext, useState, useEffect } from 'react';

import { useQuery, useApolloClient } from '@apollo/client';


import ContactContactGroup from 'react-shared/api/ContactContactGroup';
import Tag from 'react-shared/api/Tag';
import TagContact from 'react-shared/api/TagContact';
import { CurrentUserContext } from 'gunner-react';
import TagSelectView from '../../Tag/Select/TagSelectView';



export default ({id, contactGroupId, tagId, onSuccess = () => null, onSubmitting = () => null, onError = () => null}) => {
  const currentUser = useContext(CurrentUserContext);
  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const query = {
    query: TagContact.queries.listByContactId,
    variables: {
      contactId: id
    }
  };

  const listQuery = !!tagId ? ({
    query: TagContact.queries.listByTagId,
    variables: {
      tagId,
      limit: 500
    }
  }) : ({
    query: ContactContactGroup.queries.listByContactGroupId,
    variables: {
      contactGroupId,
      limit: 200
    }
  })

  const { data: {listTagContactsByContactId: {items: tagContacts = []} = {}} = {}} = useQuery(query.query, {variables: query.variables})
  const { data: {listTagsByAdminGroup: {items: tags = []} = {}} = {}} = useQuery(Tag.queries.listByAdminGroup, {
    variables: {
      adminGroup: `${currentUser.userOrganizationId}-Admins`,
      limit: 200
    }
  });
  const client = useApolloClient();

  const handleChange = ({target: {value, checked}}) =>
    !!checked ? (
      [
        client.mutate({
          refetchQueries: [query, listQuery, {
            query: TagContact.queries.listByTagId,
            variables: {
              tagId: value,
              limit: 500
            }
          }],
          mutation: TagContact.mutations.create,
          variables: {
            input: {
              tagId: value,
              contactId: id,
              adminGroup: `${currentUser.userOrganizationId}-Admins`,
              memberGroup: `${currentUser.userOrganizationId}-Members`
            }
          }
        })
        .catch(console.log)
        .then(console.log),
        setSelectedTagIds(
          value === 'all' ? (
            tags.map(item => item.id)
          ) : (
            [
              ...[value],
              ...selectedTagIds
            ]
          )
        )
      ]
      
    ) : (
      [
        setSelectedTagIds(
          value === 'all' ? (
            []
          ) : (
            selectedTagIds.filter(id => id !== value)
          )
        ),
        client.query({
          fetchPolicy: "network-only",
          query: TagContact.queries.listByContactId,
          variables: {
            contactId: id,
            tagId: {
              eq: value
            }
          }
        })
          .then(({data: {listTagContactsByContactId: {items = []} = {}} = {}}) => console.log("items", items) ||
            client.mutate({
              refetchQueries: [query, listQuery, {
                query: TagContact.queries.listByTagId,
                variables: {
                  tagId: value,
                  limit: 500
                }
              }],
              mutation: TagContact.mutations.delete,
              variables: {
                input: {
                  id: items[0]?.id || "<>"
                }
              }
            })
            .catch(console.log)
            .then(console.log)
          )
      ]
    )


  useEffect(() => {
    setSelectedTagIds(tagContacts.map(tc => tc.tagId))
  }, [JSON.stringify(tagContacts)])

  return (
    <TagSelectView 
      tags={tags} 
      selectedTagIds={selectedTagIds} 
      handleChange={handleChange}
      label="Select one or more tags"
      helperText="Tags make organizing contacts to find and analyze"
    />
  )
}