import gql from 'graphql-tag'
import Fragments from './Fragments'

const CampaignTemplate = {
  mutations: {
    update: gql`
      mutation UpdateCampaignTemplate($input: UpdateCampaignTemplateInput!) {
        updateCampaignTemplate(input: $input) {
          ...CampaignTemplatePublic
        }
      }
      ${Fragments.CampaignTemplate.public}
    `,
    create: gql`
      mutation CreateCampaignTemplate($input: CreateCampaignTemplateInput!) {
        createCampaignTemplate(input: $input) {
          ...CampaignTemplatePublic
        }
      }
      ${Fragments.CampaignTemplate.public}
    `
  },
  subscriptions: {
    onUpdate: gql`
      subscription OnUpdateCampaignTemplate($id: String) {
        onUpdateCampaignTemplate(id: $id) {
          ...CampaignTemplatePublic
        }
      }
      ${Fragments.CampaignTemplate.public}
    `
  },
  queries: {
    listByOrganizationId: gql`
      query ListCampaignTemplatesByOrganizationId(
        $campaignTemplateOrganizationId: ID
        $updatedAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelCampaignTemplateFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listCampaignTemplatesByOrganizationId(
          campaignTemplateOrganizationId: $campaignTemplateOrganizationId
          updatedAt: $updatedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...CampaignTemplatePublic
          }
          nextToken
        }
      }
      ${Fragments.CampaignTemplate.public}
    `,
    get: gql`
      query GetCampaignTemplate($id: ID!) {
        getCampaignTemplate(id: $id) {
          ...CampaignTemplatePublic
        }
      }
      ${Fragments.CampaignTemplate.public}
    `,
    list: gql`
      query ListCampaignTemplates(
        $filter: ModelCampaignTemplateFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listCampaignTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            ...CampaignTemplatePublic
          }
          nextToken
        }
      }
      ${Fragments.CampaignTemplate.public}
    `
  }
}

export default CampaignTemplate