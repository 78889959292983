import React, { useState, useEffect, useContext } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';


import { Form } from 'gunner-react/web';
import Option from 'react-shared/api/Option';
import { withoutKeys } from 'gunner-react';
import { withoutBlanks } from 'gunner-react';
import { CurrentUserContext } from 'gunner-react';
import writeFragment from 'Util/writeFragment';

const fields = {
  body: {
    label: "Text",
    regex: /\w+/,
    required: true,
    type: 'text',
    errorMessage: "You are required enter text",
    helperText: "The text the survey taker will see to enter a response for"
  },
  position: {
    label: "Position",
    regex: /\d+/,
    required: true,
    type: 'number',
    helperText: "What order in the campaign should this template appear in?",
  },
}

const OptionFormContainer = ({promptId, option, onSuccess = () => null, onSubmitting = () => null, onError = () => null}) => {
  const [formData, setFormData] = useState(Option || {});
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const currentUser = useContext(CurrentUserContext);
  const apolloClient = useApolloClient()

  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: field === 'isExpired' ? !!value : value
    })

  const [_createOption] = useMutation(Option.mutations.create, {
    variables: {input: { 
      ...withoutBlanks(formData),
      adminGroup: `${currentUser.userOrganizationId}-Admins`,
      memberGroup: `${currentUser.userOrganizationId}-Members`
    }},
    optimisticResponse: {
      __typename: "Mutation",
      createOption: { 
        ...formData,
        __typename: "Option",
        adminGroup: `${currentUser.userOrganizationId}-Admins`,
        memberGroup: `${currentUser.userOrganizationId}-Members`
      }
    },
    refetchQueries: [
      {
        query: Option.queries.listByPromptId,
        variables: {
          optionPromptId: promptId,
          sortDirection: "DESC"
        },
      }
    ]
  });

  

  const [_updateOption] = useMutation(Option.mutations.update, {
    variables: {input: {
      ...withoutBlanks(withoutKeys(formData, ['__typename'])),
    }},
    optimisticResponse: {
      __typename: "Mutation",
      updateOption: { 
        ...formData,
        __typename: "Option"
      }
    },
    update: (proxy, {data: {updateOption}}) => writeFragment({client: proxy, data: updateOption, model: 'Option'})
  });

  useEffect(() => {
    !!doSubmit && onSubmitting()

    !!doSubmit &&
    (!!formData.id ? _updateOption : _createOption)() //if this is an existing Option, update it, otherwise, create it
      .then(({data: {[(!!formData.id ? "updateOption" : "createOption")]: option}}) => 
        setSubmitSuccess(true)
      )
      .catch(e => [
        console.log(e),
        setDoSubmit(false),
        setSubmitSuccess(false),
        window.alert(e.message),
        onError(e)
      ])
  }, [doSubmit]);

  useEffect(() => {
    !!submitSuccess &&
    onSuccess()
  }, [submitSuccess])

  return (
    <Form 
      primaryButtonProps={{color: "primary", fullWidth: true}}
      submitting={false}
      fields={fields}
      onChange={handleChange}
      data={formData}
      onSubmit={() => setDoSubmit(true)}
      buttonLabel={"Submit"}
    />
  )
}

export default OptionFormContainer;