import React from 'react';
import { useQuery } from '@apollo/client';
import Organziation from 'react-shared/api/Organization';

const OrganziationNameContainer = ({id}) => {

  const {error, data: {getOrganization = {}} = {}} = useQuery(Organziation.queries.get, {variables: {id}});
  const entry = useQuery(Organziation.queries.get, {variables: {id}});

  !!error && console.log(error);

  return (
    <>
      {getOrganization.name}
    </>
  )
}

export default OrganziationNameContainer