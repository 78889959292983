import React from 'react';

import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';

import Form from "../Form";

import Tag from 'react-shared/api/Tag';



const TagEditContainer = ({id, onSuccess = () => null, onSubmitting = () => null, onError = () => null}) => {

  const { error, loading, data: {getTag: tag} = {} } = useQuery(Tag.queries.get, {variables: {id}});

  !!error && console.log(error)


  return (
    !!loading ? (
      <CircularProgress color="secondary" />
    ) : (
      <Form onSubmitting={onSubmitting} onSuccess={onSuccess} onError={onError} tag={tag} />
    )
  )
}

export default TagEditContainer;