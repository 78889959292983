import React, { useState, useEffect, useContext } from 'react';
import { useMutation } from '@apollo/client';
import shortid from 'shortid'

import { Form } from 'gunner-react/web';
import Invitation from 'react-shared/api/Invitation';
import { withoutKeys } from 'gunner-react';
import { withoutBlanks } from 'gunner-react';
import { CurrentUserContext } from 'gunner-react';
import writeFragment from 'Util/writeFragment';


const fields = {
  contactInfo: {
    label: "Contact Info",
    regex: /\w+/,
    required: true,
    type: 'text',
    helperText: "Enter an email address or mobile number to send the invitation to. If you enter a mobile number, it must be able to accept text messages."
  }
}

const formatContactInfo = contactInfo => {
  if(/@/.test(new RegExp(contactInfo))) {
    return contactInfo;
  } else {
    const phoneNumber = (contactInfo || "").replace(/\D/g, "");
    return phoneNumber.length === 10 ? (
      `+1${phoneNumber}`
    ) : phoneNumber.length === 11 ? (
      `+${phoneNumber}`
    ) : (
      `+${phoneNumber}`
    )
  }
}

const InvitationFormContainer = ({invitation, onSuccess = () => null, onSubmitting = () => null, onError = () => null}) => {
  const [formData, setFormData] = useState(invitation || {});
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const currentUser = useContext(CurrentUserContext);

  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: field === 'isExpired' ? !!value : value
    })

  const [_createInvitation] = useMutation(Invitation.mutations.create, {
    variables: {input: { 
      contactInfo: formatContactInfo(formData.contactInfo),
      code: shortid.generate(),
      adminGroup: currentUser.adminGroup,
      memberGroup: currentUser.memberGroup
    }},
    optimisticResponse: {
      __typename: "Mutation",
      createInvitation: { 
        contactInfo: formatContactInfo(formData.contactInfo),
        __typename: "Invitation",
        adminGroup: currentUser.adminGroup,
        memberGroup: currentUser.memberGroup
      }
    },
    refetchQueries: [
      {
        query: Invitation.queries.listByAdminGroup,
        variables: {
          adminGroup: `${currentUser.userOrganizationId}-Admins`,
        },
      }
    ]
  });

  

  const [_updateInvitation] = useMutation(Invitation.mutations.update, {
    variables: {input: {
      ...withoutBlanks(withoutKeys(formData, ['__typename'])),
    }},
    optimisticResponse: {
      __typename: "Mutation",
      updateInvitation: { 
        ...formData,
        __typename: "Invitation"
      }
    },
    update: (proxy, {data: {updateInvitation}}) => writeFragment({client: proxy, data: updateInvitation, model: 'Invitation'})
  });

  useEffect(() => {
    !!doSubmit && onSubmitting()

    !!doSubmit &&
    (!!formData.id ? _updateInvitation : _createInvitation)() //if this is an existing invitation, update it, otherwise, create it
      .then(({data: {[(!!formData.id ? "updateInvitation" : "createInvitation")]: invitation}}) => 
        setSubmitSuccess(true)
      )
      .catch(e => [
        console.log(e),
        setDoSubmit(false),
        setSubmitSuccess(false),
        window.alert(e.message),
        onError(e)
      ])
  }, [doSubmit]);

  useEffect(() => {
    !!submitSuccess &&
    onSuccess()
  }, [submitSuccess])

  return (
    <Form 
      primaryButtonProps={{color: "primary", fullWidth: true}}
      submitting={false}
      fields={fields}
      onChange={handleChange}
      data={formData}
      onSubmit={() => setDoSubmit(true)}
      buttonLabel={"Submit"}
    />
  )
}

export default InvitationFormContainer;