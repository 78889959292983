import { useState, useEffect, useContext } from 'react'
import { useQuery, useSubscription } from '@apollo/client'
import Response from 'react-shared/api/Response'
import { CurrentUserContext } from 'gunner-react';

export default ({organizationId, onlyUnseen}) => {
  const [token, setToken] = useState(undefined);
  const [responses, setResponses] = useState([]);
  const currentUser = useContext(CurrentUserContext);
  const dataKey = onlyUnseen ? 'listResponsesByAdminGroupAndReasonSeenAt' : 'listResponsesByAdminGroup';
  const query = onlyUnseen ? Response.queries.listByAdminGroupAndReasonSeenAt : Response.queries.listByAdminGroup;

  const {error, fetchMore, loading, data: {[dataKey]: {nextToken, items = []} = {}} = {}} = useQuery(query, {
    skip: !organizationId,
    pollInterval: 3000,
    variables: {
      filter: {
        reason: {
          attributeExists: true
        }
      },
      limit: 100,
      sortDirection: "DESC",
      nextToken: token,
      adminGroup: `${organizationId}-Admins`,
      ...(!!onlyUnseen ? ({
        reasonSeenAt: {
          lt: "2000-01-26T22:39:21.602Z"
        }
      }) : ({

      }))
    }
  });

  // const { data: { onUpdateResponse } = {} } = useSubscription(Response.subscriptions.onUpdate, { variables: { 
  //   responseUserId: currentUser?.id || "<>"
  // }});

  !!error && console.log(error);

  useEffect(() => {
    !!nextToken &&
    setToken(nextToken);
  }, [nextToken]);

  useEffect(() => {
    setResponses([
      ...responses,
      ...items
    ]);
  }, [JSON.stringify(items)]);

  // useEffect(() => {
  //   !!onUpdateResponse?.id &&
  //   !!onUpdateResponse?.reasonSeenAt > "2000-01-26T22:39:21.602Z" &&
  //   setResponses(responses.filter(response => response.id !== onUpdateResponse?.id))
  // }, [onUpdateResponse?.id])

  return responses;
}