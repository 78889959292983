import React from 'react'
import { Typography, Paper, Box, Button } from '@material-ui/core'
import UserName from "../../User/Name"
import OrganziationName from '../../Organization/Name'
import OrganizationThankYouMessage from '../../Organization/ThankYouMessage'
import SurveyPrompt from '../Prompt'

export default ({submitting, onSubmit, isValid, survey, showThankYouMessage, prompts, onOptionSelect}) =>
  <Box m={2}>
    <Paper square>
      <Box p={2}>
        {
          !!showThankYouMessage ? (
            <Box mt={3}>
              <OrganizationThankYouMessage id={survey.adminGroup.replace("-Admins", "")} />
            </Box>
          ) : (
            <>
              <Typography paragraph>
                <UserName id={survey.surveyUserId} /> sent you this survey.
              </Typography>
              <Typography variant={'h1'}>
                <OrganziationName id={survey.adminGroup.replace("-Admins", "")} />'s Survey
              </Typography>
              <Typography paragraph>
                Please respond to all prompts and questions by clicking your choice.
              </Typography>
              <Typography paragraph>
                Optionally, you may add a comment to each of your selections, however, this is not required.
              </Typography>
              <Typography paragraph>
                Once you have completely filled out the survey, click the "Submit" button.
              </Typography>
              {
                prompts.map(prompt => 
                  <SurveyPrompt onSelect={onOptionSelect} survey={survey} prompt={prompt} key={prompt.id} />
                )
              }
            </>
          )
        }
        {
          !!isValid &&
          !showThankYouMessage &&
          <Button disabled={!!submitting} variant="contained" color="secondary" fullWidth onClick={onSubmit}>{!!submitting ? "Submitting..." : "Submit"}</Button>
        }
      </Box>
    </Paper>
    Version {process.env.REACT_APP_VERSION}
  </Box>