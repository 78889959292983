import gql from 'graphql-tag'
import Fragments from './Fragments'

const Invitation = {
  mutations: {
    update: gql`
      mutation UpdateInvitation($input: UpdateInvitationInput!) {
        updateInvitation(input: $input) {
          ...InvitationPublic
        }
      }
      ${Fragments.Invitation.public}
    `
    ,
    create: gql`
      mutation CreateInvitation($input: CreateInvitationInput!) {
        createInvitation(input: $input) {
          ...InvitationPublic
        }
      }
      ${Fragments.Invitation.public}
    `
    ,
    delete: gql`
      mutation DeleteInvitation(
        $input: DeleteInvitationInput!
        $condition: ModelInvitationConditionInput
      ) {
        deleteInvitation(input: $input, condition: $condition) {
          ...InvitationPublic
        }
      }
      ${Fragments.Invitation.public}
    `
  },
  subscriptions: {
    onUpdate: gql`
      subscription OnUpdateInvitation($id: String) {
        onUpdateInvitation(id: $id) {
          ...InvitationPublic
        }
      }
      ${Fragments.Invitation.public}
    `
  },
  queries: {
    get: gql`
      query GetInvitation($id: ID!) {
        getInvitation(id: $id) {
          ...InvitationPublic
        }
      }
      ${Fragments.Invitation.public}
    `,
    listByUserId: gql`
      query ListInvitationsByUserId(
        $invitationUserId: ID
        $updatedAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelInvitationFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listInvitationsByUserId(
          invitationUserId: $invitationUserId
          updatedAt: $updatedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...InvitationPublic
          }
          nextToken
        }
      }
      ${Fragments.Invitation.public}
    `,
    listByCode: gql`
      query ListInvitationsByUserId(
        $code: String
        $updatedAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelInvitationFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listInvitationsByCode(
          code: $code
          updatedAt: $updatedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...InvitationPublic
          }
          nextToken
        }
      }
      ${Fragments.Invitation.public}
    `,
    listByAdminGroup: gql`
      query ListInvitationsByAdminGroup(
        $adminGroup: String
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelInvitationFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listInvitationsByAdminGroup(
          adminGroup: $adminGroup
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...InvitationPublic
          }
          nextToken
        }
      }
      ${Fragments.Invitation.public}
    `,
    list: gql`
      query ListInvitations(
        $filter: ModelInvitationFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listInvitations(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            ...InvitationPublic
          }
          nextToken
        }
      }
      ${Fragments.Invitation.public}
    `
  }
}

export default Invitation