import React from 'react'

import useStyles from "./VideoStyles";

const VideoView = ({url}) => {
  const classes = useStyles();
  return (
    <div className={classes.video}>
      <iframe width="560" height="349" src={url} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  )
}

export default VideoView