import gql from 'graphql-tag'
import Fragments from './Fragments'

const Organization = {
  mutations: {
    update: gql`
      mutation UpdateOrganization($input: UpdateOrganizationInput!) {
        updateOrganization(input: $input) {
          ...OrganizationPublic
        }
      }
      ${Fragments.Organization.public}
    `,
    create: gql`
      mutation CreateOrganization($input: CreateOrganizationInput!) {
        createOrganization(input: $input) {
          ...OrganizationPublic
        }
      }
      ${Fragments.Organization.public}
    `
  },
  subscriptions: {
    onUpdate: gql`
      subscription OnUpdateOrganization($id: String) {
        onUpdateOrganization(id: $id) {
          ...OrganizationPublic
        }
      }
      ${Fragments.Organization.public}
    `
  },
  queries: {
    get: gql`
      query GetOrganization($id: ID!) {
        getOrganization(id: $id) {
          ...OrganizationPublic
        }
      }
      ${Fragments.Organization.public}
    `,
    list: gql`
      query ListOrganizations(
        $filter: ModelOrganizationFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            ...OrganizationPublic
          }
          nextToken
        }
      }
      ${Fragments.Organization.public}
    `
  }
}

export default Organization