import React from 'react'
import Invitation from 'react-shared/api/Invitation'
import { useContext } from 'react'
import { CurrentUserContext } from 'gunner-react'
import { DataTable } from 'gunner-react/web'
import InvitationResendButton from '../ResendButton';
import MoreButtonContainer from '../../MoreButton';
import moment from 'moment';

const fields = 
  {
    "Name": {
      value: item => item.acceptor?.name || "",
      sort: order =>  (a, b) => a.acceptor?.name || "" > b.acceptor?.name || "" ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Contact Info": {
      value: item => item.contactInfo,
      sort: order =>  (a, b) => a.contactInfo > b.contactInfo ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Code": {
      value: item => item.code,
      sort: order =>  (a, b) => a.code > b.code ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Accepted?": {
      value: item => item.accepted ? "Yes" : "No",
      sort: order =>  (a, b) => a.accepted > b.accepted ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Sent": {
      value: item => moment(item.createdAt).format('MM/DD/YYYY H:mm'),
      sort: order =>  (a, b) => a.createdAt > b.createdAt ? (order === 'desc' ? -1 : 1) : (order === 'asc' ? -1 : 1)
    },
    "Resend": {
      hideLabel: true,
      sort: null,
      value: item => item.accepted ? null : 
        <MoreButtonContainer>
          <InvitationResendButton render="menu" invitationId={item.id} />
        </MoreButtonContainer>
    },
  }

export default ({}) => {
  const currentUser = useContext(CurrentUserContext);
  return (
    <>
      <DataTable
        fields={fields}
        search={null}
        dataListParams={{
          useButton: true,
          query: Invitation.queries.listByAdminGroup,
          variables: {
            adminGroup: `${currentUser.userOrganizationId}-Admins`,
          },
          dataKey: "listInvitationsByAdminGroup",
        }}
      />
    </>
  )
}